import React from "react";
// reactstrap components
import {
    Badge,
    Button,
    Card, CardBody,
    CardFooter,
    CardHeader,
    Container, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row, Spinner,
    Table
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, notification, Select} from "antd";
import "./GlobalesCss.css";
import {TiArrowUnsorted} from "react-icons/ti";
import {AiOutlineEye} from "react-icons/ai"
import {FiEdit2} from "react-icons/fi"
import Tooltip from "react-simple-tooltip"
import {RiDeleteBin2Line} from "react-icons/ri";
import {Link} from "react-router-dom";
import Col from "reactstrap/lib/Col";
import {convertDateTime, IsLoggedIn} from '../Helpers/index'
import ReactExport from "react-export-excel";
import {Authorized, FormatDate} from "../Helpers";




const { Option } = Select;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
function parseDMYhm(s) {
  var b = s.split(/\D/);
  return new Date(b[2], b[1]-1, b[2], b[3], b[4]);  
}
function sortTable(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("cordonnee-table");
  switching = true;
  //Set the sorting direction to ascending:
  dir = "asc";
  /*Make a loop that will continue until
  no switching has been done:*/
  while (switching) {
    //start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /*Loop through all table rows (except the
    first, which contains table headers):*/
    for (i = 1; i < (rows.length - 1); i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /*Get the two elements you want to compare,
      one from current row and one from the next:*/
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /*check if the two rows should switch place,
      based on the direction, asc or desc:*/
        if (n===3){
            x= x.innerHTML
            y = y.innerHTML
            if (dir == "asc") {
                if (x.toDate('dd/mm/yyyy hh:ii') > y.toDate('dd/mm/yyyy hh:ii')) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir == "desc") {
                if (x.toDate('dd/mm/yyyy hh:ii') < y.toDate('dd/mm/yyyy hh:ii')) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        else {
            if (dir === "asc") {
                if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir === "desc") {
                if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }

    }
    if (shouldSwitch) {
      /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      //Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /*If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again.*/
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}


class Admins_logs extends React.Component {
  constructor() {
    super()
    this.state = {
      joueurs:[],
        notificationModal: false,
        loading : true,
        idDelete:"",
        pseudo:[],
        logs:[]
    }
    this.onChange_Pseudo = this.onChange_Pseudo.bind(this)
  }

    openCustomNotification_Success = (msg) => {
        notification['success']({
            message: 'Succès',
            description:
            msg,
        });
    };
    openNotificationError = () => {
        notification['error']({
            message: 'Erreur',
            description:
                'Une erreur s\'est produite veuillez réessayer.',
        });
    };


    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

  componentDidMount() {
      if(IsLoggedIn()[0] && IsLoggedIn()[1].Role === 1){
          console.log("auth")
      }
      else
          window.location.replace("/");

      axios.get('api/admin-logs/all-pseudo')
        .then(res=>{
          console.log("ddddd  : ",res)
          this.setState({logs : res.data})
        })
        .catch(err =>{
            this.setState({
                loading : false
            });
          console.log(err.data)
        })

      axios.get('api/admin-logs/all')
          .then(res=>{
              console.log("Tous les pseudos: " , res.data);
              this.setState({
                  pseudo: res.data,
                  loading: false
              });
          })
          .catch(err =>{
              console.log(err.data)
              this.setState({
                  loading: false
              });
          })

  }

    onChange_Pseudo(value) {
        axios.get('api/admin-logs/all/'+value)
            .then(res=>{
                console.log("Tous les logs 11: " , res.data);
                this.setState({logs : res.data})
            })
            .catch(err =>{
                console.log(err.data)
            });
    }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };


  render() {
    return (
      <>
        <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("assets/img/brand/argon-react.png"),
              imgAlt: "..."
            }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
              {...this.props}
              brandText = {this.getBrandText(this.props.location.pathname)}
          />

        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h2 className="mb-0" style={{textAlign: "center"}}>Activités</h2>
                </CardHeader>
                  <Row>
                      <Col>
                      <Select
                          showSearch
                          style={{width: "30%", marginLeft:"1.5rem"}}
                          className="form-control-alternative admin-logs"
                          placeholder="sélectionner"
                          optionFilterProp="children"
                          onChange={this.onChange_Pseudo}
                          loading={this.state.loading}
                          filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                      >
                          {this.state.pseudo.map(r => (
                              <Option value={r.Pseudonyme}>{r.Pseudonyme}</Option>
                          )) }
                      </Select>
                      </Col>
                      <Col>
                          <ExcelFile element={
                              <Button className="export-btn btn-primary">
                                  Exporter</Button>
                          } filename={"Liste des activités"}>
                              {this.state.logs===[] ? <ExcelSheet data={this.state.logs} name="Activités">
                                  <ExcelColumn label="Activités" value="Aucune donnée"/>
                              </ExcelSheet>
                                  :
                                  <ExcelSheet data={this.state.logs} name="Activités">
                                      <ExcelColumn label="Pseudonyme" value="Pseudonyme"/>
                                      <ExcelColumn label="Page" value="Page"/>
                                      <ExcelColumn label="Action" value="Action"/>
                                      <ExcelColumn label="Date"
                                                   value={(col) => FormatDate(col.createdAt)}/>
                                  </ExcelSheet>
                              }
                          </ExcelFile>
                      </Col>
                  </Row>
                  {this.state.loading ?
                  <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                  </div> :
                <Table className="align-items-center table-flush" responsive id={"cordonnee-table"}>
                  <thead className="thead-light">
                    <tr>
                      <th className="th-sort" scope="col" onClick={() => sortTable(0)}>Pseudonyme <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(1)}>Page <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(2)}>Action <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(3)}>date <TiArrowUnsorted/></th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.logs.map((row) => (
                      <tr key={row.idLogin_admins}>
                          <td>{row.Pseudonyme}</td>
                          <td>{row.Page}</td>
                          <td>{row.Action}</td>
                          <td>{FormatDate(row.createdAt)}</td>
                      </tr>
                  ))}
                  </tbody>
                </Table>
                      }
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>


            <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admins_logs;
