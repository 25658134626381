import React from "react";
// reactstrap components
import {
  Alert,
  Badge,
  Button,
  Card, CardBody,
  CardFooter,
  CardHeader,
  Container,
  Row, Spinner,
  Table
} from "reactstrap";
// import Tabs,{Tab} from 'react-best-tabs';
import { Tabs } from 'antd';

import 'react-best-tabs/dist/index.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw,convertFromHTML,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './GlobalesCss.css'
import 'draft-js/dist/Draft.css';

// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, notification} from "antd";
import "./GlobalesCss.css";

import {Authorized, nextSaturday} from "../Helpers";


let deadline2 = nextSaturday();
deadline2.setHours(21,0,0,);
const { TabPane } = Tabs;


class Pages extends React.Component {
  constructor() {
    super()
    this.state = {
      joueurs:[],
      notif_visible : false,
      Modal_Detail: true,
      notificationModal: false,
      authPage:false,
      LectureMode : false,
      editorState_1  : EditorState.createEmpty(),
      editorState_2  : EditorState.createEmpty(),
      editorState_4  : EditorState.createEmpty(),
      editorState_5  : EditorState.createEmpty(),
      editorState_6  : EditorState.createEmpty(),
      editorState_12  : EditorState.createEmpty(),
      editorState_17 : EditorState.createEmpty(),
      editorState_18 : EditorState.createEmpty(),
      editorState_19 : EditorState.createEmpty(),
      editorState_20 : EditorState.createEmpty(),
      editorState_21 : EditorState.createEmpty(),
      editorState_22 : EditorState.createEmpty(),
      editorState_23 : EditorState.createEmpty(),
      editorState_24 : EditorState.createEmpty(),
      editorState_25 : EditorState.createEmpty(),
      editorState_26 : EditorState.createEmpty(),
      editorState_27 : EditorState.createEmpty(),
      editorState_28 : EditorState.createEmpty(),
      editorState_29 : EditorState.createEmpty(),
      editorState_30 : EditorState.createEmpty(),
      editorState_31 : EditorState.createEmpty(),
      editorState_32 : EditorState.createEmpty(),
      editorState_33 : EditorState.createEmpty(),
      editorState_35 : EditorState.createEmpty(),
      editorState_36 : EditorState.createEmpty(),
      editorState_37 : EditorState.createEmpty(),
      editorState_38 : EditorState.createEmpty(),
      editorState_39 : EditorState.createEmpty(),
      editorState_40 : EditorState.createEmpty(),
      editorState_41 : EditorState.createEmpty(),
      editorState_42 : EditorState.createEmpty(),
      editorState_43 : EditorState.createEmpty(),
      editorState_44 : EditorState.createEmpty(),
      editorState_45 : EditorState.createEmpty(),
      editorState_46 : EditorState.createEmpty(),
      editorState_47 : EditorState.createEmpty(),
      editorState_48 : EditorState.createEmpty(),
      editorState_49 : EditorState.createEmpty(),
      editorState_50 : EditorState.createEmpty(),
      editorState_51 : EditorState.createEmpty(),
      editorState_52 : EditorState.createEmpty(),
      editorState_53 : EditorState.createEmpty(),

    }
    this.onEditorStateChange_1 = this.onEditorStateChange_1.bind(this);
    this.onEditorStateChange_2 = this.onEditorStateChange_2.bind(this);
    this.onEditorStateChange_3 = this.onEditorStateChange_3.bind(this);
    this.onEditorStateChange_4 = this.onEditorStateChange_4.bind(this);
    this.onEditorStateChange_5 = this.onEditorStateChange_5.bind(this);
    this.onEditorStateChange_6 = this.onEditorStateChange_6.bind(this);
    this.onEditorStateChange_12 = this.onEditorStateChange_12.bind(this);
    this.onEditorStateChange_17 = this.onEditorStateChange_17.bind(this);
    this.onEditorStateChange_18 = this.onEditorStateChange_18.bind(this);
    this.onEditorStateChange_19 = this.onEditorStateChange_19.bind(this);
    this.onEditorStateChange_20 = this.onEditorStateChange_20.bind(this);
    this.onEditorStateChange_21 = this.onEditorStateChange_21.bind(this);
    this.onEditorStateChange_22 = this.onEditorStateChange_22.bind(this);
    this.onEditorStateChange_23 = this.onEditorStateChange_23.bind(this);
    this.onEditorStateChange_24 = this.onEditorStateChange_24.bind(this);
    this.onEditorStateChange_25 = this.onEditorStateChange_25.bind(this);
    this.onEditorStateChange_26 = this.onEditorStateChange_26.bind(this);
    this.onEditorStateChange_27 = this.onEditorStateChange_27.bind(this);
    this.onEditorStateChange_28 = this.onEditorStateChange_28.bind(this);
    this.onEditorStateChange_29 = this.onEditorStateChange_29.bind(this);
    this.onEditorStateChange_30 = this.onEditorStateChange_30.bind(this);
    this.onEditorStateChange_31 = this.onEditorStateChange_31.bind(this);
    this.onEditorStateChange_32 = this.onEditorStateChange_32.bind(this);
    this.onEditorStateChange_33 = this.onEditorStateChange_33.bind(this);
    this.onEditorStateChange_35 = this.onEditorStateChange_35.bind(this);
    this.onEditorStateChange_36 = this.onEditorStateChange_36.bind(this);
    this.onEditorStateChange_37 = this.onEditorStateChange_37.bind(this);
    this.onEditorStateChange_38 = this.onEditorStateChange_38.bind(this);
    this.onEditorStateChange_39 = this.onEditorStateChange_39.bind(this);
    this.onEditorStateChange_40 = this.onEditorStateChange_40.bind(this);
    this.onEditorStateChange_41 = this.onEditorStateChange_41.bind(this);
    this.onEditorStateChange_42 = this.onEditorStateChange_42.bind(this);
    this.onEditorStateChange_43 = this.onEditorStateChange_43.bind(this);
    this.onEditorStateChange_44 = this.onEditorStateChange_44.bind(this);
    this.onEditorStateChange_45 = this.onEditorStateChange_45.bind(this);
    this.onEditorStateChange_46 = this.onEditorStateChange_46.bind(this);
    this.onEditorStateChange_47 = this.onEditorStateChange_47.bind(this);
    this.onEditorStateChange_48 = this.onEditorStateChange_48.bind(this);
    this.onEditorStateChange_49 = this.onEditorStateChange_49.bind(this);
    this.onEditorStateChange_50 = this.onEditorStateChange_50.bind(this);
    this.onEditorStateChange_51 = this.onEditorStateChange_51.bind(this);
    this.onEditorStateChange_52 = this.onEditorStateChange_52.bind(this);
    this.onEditorStateChange_53 = this.onEditorStateChange_53.bind(this);
  }
  onEditorStateChange_1(editorState){
    this.setState({
      editorState_1 : editorState,
    });
  };
  onEditorStateChange_2(editorState){
    this.setState({
      editorState_2 : editorState,
    });
  };
  onEditorStateChange_3(editorState){
    this.setState({
      editorState_3 : editorState,
    });
  };
  onEditorStateChange_4(editorState){
    this.setState({
      editorState_4 : editorState,
    });
  };
  onEditorStateChange_5(editorState){
    this.setState({
      editorState_5 : editorState,
    });
  };

  onEditorStateChange_6(editorState){
    this.setState({
      editorState_6 : editorState,
    });
  }

  onEditorStateChange_12(editorState){
    this.setState({
      editorState_12 : editorState,
    });
  }

  onEditorStateChange_17(editorState){
    this.setState({
      editorState_17 : editorState,
    });
  }
  onEditorStateChange_18(editorState){
    this.setState({
      editorState_18 : editorState,
    });
  }
  onEditorStateChange_19(editorState){
    this.setState({
      editorState_19 : editorState,
    });
  }
  onEditorStateChange_20(editorState){
    this.setState({
      editorState_20 : editorState,
    });
  }
  onEditorStateChange_21(editorState){
    this.setState({
      editorState_21 : editorState,
    });
  }onEditorStateChange_22(editorState){
    this.setState({
      editorState_22 : editorState,
    });
  }onEditorStateChange_23(editorState){
    this.setState({
      editorState_23 : editorState,
    });
  }onEditorStateChange_24(editorState){
    this.setState({
      editorState_24 : editorState,
    });
  }onEditorStateChange_25(editorState){
    this.setState({
      editorState_25 : editorState,
    });
  }onEditorStateChange_26(editorState){
    this.setState({
      editorState_26 : editorState,
    });
  }onEditorStateChange_27(editorState){
    this.setState({
      editorState_27 : editorState,
    });
  }onEditorStateChange_28(editorState){
    this.setState({
      editorState_28 : editorState,
    });
  }onEditorStateChange_29(editorState){
    this.setState({
      editorState_29 : editorState,
    });
  }
  onEditorStateChange_30(editorState){
    this.setState({
      editorState_30 : editorState,
    });
  }
  onEditorStateChange_31(editorState){
    this.setState({
      editorState_31 : editorState,
    });
  }
  onEditorStateChange_32(editorState){
    this.setState({
      editorState_32 : editorState,
    });
  }

  onEditorStateChange_33(editorState){
    this.setState({
      editorState_33 : editorState,
    });
  }

  onEditorStateChange_35(editorState){
    this.setState({
      editorState_35 : editorState,
    });
  }

  onEditorStateChange_36(editorState){
    this.setState({
      editorState_36 : editorState,
    });
  }

  onEditorStateChange_37(editorState){
    this.setState({
      editorState_37 : editorState,
    });
  }

  onEditorStateChange_38(editorState){
    this.setState({
      editorState_38 : editorState,
    });
  }

  onEditorStateChange_39(editorState){
    this.setState({
      editorState_39 : editorState,
    });
  }

  onEditorStateChange_40(editorState){
    this.setState({
      editorState_40 : editorState,
    });
  }

  onEditorStateChange_41(editorState){
    this.setState({
      editorState_41 : editorState,
    });
  }

  onEditorStateChange_42(editorState){
    this.setState({
      editorState_42 : editorState,
    });
  }

  onEditorStateChange_43(editorState){
    this.setState({
      editorState_43 : editorState,
    });
  }

  onEditorStateChange_44(editorState){
    this.setState({
      editorState_44 : editorState,
    });
  }

  onEditorStateChange_45(editorState){
    this.setState({
      editorState_45 : editorState,
    });
  }

  onEditorStateChange_46(editorState){
    this.setState({
      editorState_46 : editorState,
    });
  }

  onEditorStateChange_47(editorState){
    this.setState({
      editorState_47 : editorState,
    });
  }

  onEditorStateChange_48(editorState){
    this.setState({
      editorState_48 : editorState,
    });
  }

  onEditorStateChange_49(editorState){
    this.setState({
      editorState_49 : editorState,
    });
  }

  onEditorStateChange_50(editorState){
    this.setState({
      editorState_50 : editorState,
    });
  }

  onEditorStateChange_51(editorState){
    this.setState({
      editorState_51 : editorState,
    });
  }

  onEditorStateChange_52(editorState){
    this.setState({
      editorState_52 : editorState,
    });
  }

  onEditorStateChange_53(editorState){
    this.setState({
      editorState_53 : editorState,
    });
  }

  openNotificationErrorProfile = (msg) => {
    notification['error']({
      message: 'Erreur',
      description:
      msg,
    });
  };

  toggle = () => this.setState({Modal_Detail : !this.state.Modal_Detail});
  componentDidMount() {
    Authorized("PA").then(r=>{
      if (r.data.Role === 2){ // if role is super user see if access to this page is giver
        if (r.data.Access){
          this.setState({authPage:true})
        }
        else{
          window.location.replace("/");
        }
      }else if(r.data.Role === 3) {
        this.setState({LectureMode : true, authPage:true})
      }else
        this.setState({authPage:true})
    }).catch(
        this.setState({authPage:true})
    )

    axios
        .get('api/pages')
        .then(res=>{
          // console.log("Tous les pages : " , res.data.Pages[0].contenu_page);
          let data = res.data.Pages
          data.map(p =>{
            // console.log(p.contenu_page)
            const blocksFromHTML = htmlToDraft(p.contenu_page);
            const temp = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );

            this.setState({
              ["editorState_"+p.id_page]: EditorState.createWithContent(temp),
            });
          })


        })
        .catch(err =>{
          console.log(err.data)
        })
    // this.initializeClock(deadline2);
  }


  openNotification = () => {
    notification['success']({
      message: 'Succès',
      description:
          'La page est mis à jour avec succès !',
    });
  };

  openNotificationError = () => {
    notification['error']({
      message: 'Erreur',
      description:
          'Une erreur s\'est produite veuillez réessayer.',
    });
  };

  onDismiss = () => this.setState({notif_visible : false})

  updatePage = async (id) =>{
    if(this.state.LectureMode){
      this.openNotificationErrorProfile("vous n'êtes pas autorisé à effectuer cette action")
    }else {
      await axios.post("api/pages", {
        contenu_page: draftToHtml(convertToRaw(this.state['editorState_' + id].getCurrentContent())),
        id_page: id,
      })
          .then((res) => {
            // console.log(res)
            this.openNotification()
          })
          .catch(() => {
            this.openNotificationError()
          })
    }
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };


  render() {

    return (
      <>
        {!this.state.authPage ?
            <div className="spinner-pages spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
            </div> :
            <>
              <Sidebar
                  {...this.props}
                  routes={routes}
                  logo={{
                    innerLink: "/admin/index",
                    imgSrc: require("assets/img/brand/argon-react.png"),
                    imgAlt: "..."
                  }}
              />
              <div className="main-content" ref="mainContent">
                <AdminNavbar
                    {...this.props}
                    brandText={this.getBrandText(this.props.location.pathname)}
                />

                <Header/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                  {/* Table */}
                  <Row>
                    <div className="col">
                      <Card className="shadow">

                        <CardHeader className="border-0">
                          <h2 className="mb-0" style={{textAlignLast: "center"}}>Pages</h2>

                        </CardHeader>
                        <CardBody>

                          <Tabs defaultActiveKey="1" className="mt-5">
                            {/* Nous connaitre */}
                            <TabPane tab="Nous connaître" className="mr-3" key="1">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_1}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_1}
                                />

                              </div>
                              <Button onClick={() => this.updatePage(1)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* Concept */}
                            <TabPane key={"2"} tab="Concept" className="mr-3">
                              <h3>Texte en haut : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_17}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_17}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(17)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>
                              <h3 className="mt-5 mb-3">Texte pour partie bleu (gauche): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_35}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_35}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(35)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Texte pour partie droite (1): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_36}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_36}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(36)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>


                              <h3 className="mt-5 mb-3">Texte pour partie droite (2): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_37}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_37}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(37)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Texte pour partie droite (3): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_38}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_38}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(38)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Texte pour partie droite (4): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_39}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_39}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(39)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Texte pour partie droite (5): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_40}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_40}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(40)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Texte pour partie droite (6): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_18}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_18}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(18)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Texte pour partie en dessous bleu : </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_41}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_41}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(41)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Liste pour partie en dessous bleu (1): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_42}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_42}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(42)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Liste pour partie en dessous bleu (2): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_43}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_43}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(43)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Liste pour partie en dessous bleu (3): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_44}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_44}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(44)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Liste pour partie en dessous bleu (4): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_45}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_45}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(45)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Liste pour partie en dessous bleu (5): </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_46}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_46}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(46)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">Texte en bas: </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_19}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_19}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(19)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* Nos avantages */}
                            <TabPane key={"3"} tab="Nos avantages" className="mr-3">
                              <h3>1 : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_20}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_20}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(20)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">2: </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_21}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_21}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(21)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">3 : </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_22}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_22}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(22)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">4 : </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_23}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_23}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(23)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3 className="mt-5 mb-3">5 : </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_24}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_24}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(24)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <hr/>
                              <h3 className="mt-5 mb-3">6 : </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_25}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_25}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(25)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <hr/>
                              <h3 className="mt-5 mb-3">7 : </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_26}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_26}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(26)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <hr/>
                              <h3 className="mt-5 mb-3">8 : </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_27}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_27}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(27)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <hr/>
                              <h3 className="mt-5 mb-3">9 : </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_28}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_28}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(28)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <hr/>
                              <h3 className="mt-5 mb-3">10 : </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_29}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_29}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(29)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <hr/>
                              <h3 className="mt-5 mb-3">11 : </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_30}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_30}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(30)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <hr/>
                              <h3 className="mt-5 mb-3">12 : </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_31}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_31}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(30)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* ************ Regles *****************/}
                            <TabPane tab="Règles" className="mr-3" key="4">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_2}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_2}
                                />

                              </div>
                              <Button onClick={() => this.updatePage(2)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* Déroulement du tirage */}
                            <TabPane key={"12"} tab="Déroulement du tirage" className="mr-3">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_12}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_12}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(12)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* ************ A propos *****************/}
                            <TabPane tab="À propos" className="mr-3" key="6">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_32}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_32}
                                />

                              </div>
                              <Button onClick={() => this.updatePage(32)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* ************ Securite *****************/}
                            <TabPane tab="Sécurité " className="mr-3" key="7">
                              <h3>Texte 1 : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_47}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_47}
                                />

                              </div>
                              <Button onClick={() => this.updatePage(47)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3>Avec le protocole HTTPS : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_48}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_48}
                                />

                              </div>
                              <Button onClick={() => this.updatePage(48)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3>Législation RGPD : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_49}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_49}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(49)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>
                              <h3>Sécurité paiement avec garantie 3D Secure et certifié PCI DSS : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_33}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_33}
                                />

                              </div>
                              <Button onClick={() => this.updatePage(33)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>
                            </TabPane>

                            {/* Licence de jeu */}
                            <TabPane tab="Licence de jeu" className="mr-3" key="8">
                              <h3>Texte 1 : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_5}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_5}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(5)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3>Mais pourquoi avoir choisi la Curacao e-Gaming Licensing Authority plutôt qu’une autre
                                ?</h3>
                              <h3>partie 1 : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_50}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_50}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(50)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3>partie 2 : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_51}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_51}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(51)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* Transparence et tarif */}
                            <TabPane tab="Transparence et tarif" className="mr-3" key="9">
                              <h3>Texte avant photo : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_6}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_6}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(6)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3>Texte aprés photo : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_52}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_52}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(52)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>


                          </Tabs>
                        </CardBody>

                      </Card>
                    </div>
                  </Row>
                </Container>

                <Container fluid>
                  <AdminFooter/>
                </Container>
              </div>
            </>
        }
      </>
    );
  }
}

export default Pages;
