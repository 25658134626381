import React from "react";
// reactstrap components
import {
  Alert,
  Badge,
  Button,
  Card, CardBody,
  CardFooter,
  CardHeader,
  Container,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row, Spinner,
  Table
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input} from "antd";
import "./GlobalesCss.css";
import {TiArrowUnsorted} from "react-icons/ti";
import { ModalManager} from 'react-dynamic-modal';
import {Modal} from "reactstrap"
import Infos_Modal from "../components/Joueurs_Detail_Modal";
import {Link} from "react-router-dom";
import {Authorized, convertDateTime, FormatDate, getTimeRemaining, getYear_Week, nextSaturday} from "../Helpers";


function Search_In_Table() {
  var input, filter, table, found, tr, td, i, j, txtValue;
  input = document.getElementById("input-cord-table");
  filter = input.value.toUpperCase();
  table = document.getElementById("cordonnee-table");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td");
    for (j = 0; j < td.length; j++) {
      if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
        found = true;
      }
    }
    if (found) {
      tr[i].style.display = "";
      found = false;
    } else {
      if (i>0) { //this skips the headers
        tr[i].style.display = "none";
      }
    }
  }

}

function sortTable(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("cordonnee-table");
  switching = true;
  //Set the sorting direction to ascending:
  dir = "asc";
  /*Make a loop that will continue until
  no switching has been done:*/
  while (switching) {
    //start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /*Loop through all table rows (except the
    first, which contains table headers):*/
    for (i = 1; i < (rows.length - 1); i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /*Get the two elements you want to compare,
      one from current row and one from the next:*/
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /*check if the two rows should switch place,
      based on the direction, asc or desc:*/
      if (n===3){
        x= convertDateTime(x.innerHTML)
        y = convertDateTime(y.innerHTML)
        if (dir == "asc") {
          if (x > y) {
            //if so, mark as a switch and break the loop:
            shouldSwitch= true;
            break;
          }
        } else if (dir == "desc") {
          if (x < y) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      else {
        if (dir == "asc") {
          if (Number(x.innerHTML) > Number(y.innerHTML)) {
            //if so, mark as a switch and break the loop:
            shouldSwitch= true;
            break;
          }
        } else if (dir == "desc") {
          if (Number(x.innerHTML) < Number(y.innerHTML)) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
    }
    if (shouldSwitch) {
      /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      //Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /*If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again.*/
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}


function DownloadPdfFile(FileName, byte, FileType) {
    let blob = new Blob([new Uint8Array(byte)], {type: FileType});
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = FileName;
    link.click();
}

let deadline2 = nextSaturday();
deadline2.setHours(21,0,0,);

let year_week = getYear_Week(new Date());
const NumeroTirage = year_week[0]+"-"+year_week[1];


class Do_Draw extends React.Component {
  constructor() {
    super()
    this.state = {
      Draws:[],
      errorMessage:"",
      WinnerNumbers : [],
      WinnerIds : [],
      successMessage:[],
      errorCode:"",
      notif_error_visible:false,
      notif_success_visible:false,
      Modal_Detail: true,
      notificationModal: false,
      daysSpan : "",
      hoursSpan : "",
      minutesSpan : "",
      secondsSpan : "", loading : false,
      authPage:false,
      LectureMode : false
    }

  }



  toggle = () => this.setState({Modal_Detail : !this.state.Modal_Detail});

  componentDidMount() {
    Authorized("LT").then(r=>{
      if (r.data.Role === 2){ // if role is super user see if access to this page is giver
        if (r.data.Access){
          this.setState({authPage:true})
        }
        else{
          window.location.replace("/");
        }
      }else if(r.data.Role === 3) {
        this.setState({LectureMode : true, authPage:true})
      }else
        this.setState({authPage:true})
    }).catch(
        this.setState({authPage:true})
    )

    axios.get('api/random-draw')
        .then(res=>{
          console.log("resultat : " , res.data.result);
          this.setState({
            Draws: Object.values(res.data.result)
          });
          /*console.log("Players : " + res.data.Players)
          this.setState({
            joueurs: Object.values(res.data.Players),
          });

          console.log("111111111111111111111111111", this.state.joueurs[0].pieceRecto);*/

        })
        .catch(err =>{
          console.log(err.data)
        })
    this.initializeClock(deadline2);
  }


  initializeClock(endtime) {
    // const daysSpan = clock.querySelector('.days');
    // const hoursSpan = clock.querySelector('.hours');
    // const minutesSpan = clock.querySelector('.minutes');
    // const secondsSpan = clock.querySelector('.seconds');

    let updateClock = () => {
      let t = getTimeRemaining(endtime);

      console.log("time" + t)
      this.setState({daysSpan : t.days});
      this.setState({hoursSpan : ('0' + t.hours).slice(-2)});
      this.setState({minutesSpan : ('0' + t.minutes).slice(-2)});
      this.setState({secondsSpan : ('0' + t.seconds).slice(-2)});



      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }

    //updateClock();
    const timeinterval = setInterval(updateClock, 1000);
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  onDismiss = (StateName) => this.setState({[StateName] : false})

  Do_draw(){
    this.setState({loading : true})
    axios.post('api/random-draw',{WeekID : NumeroTirage})
        .then(res=>{
          console.log("resultat : " , res.data);

          this.toggleModal("notificationModal")
          this.setState({notif_success_visible : true, WinnerNumbers : res.data.number,
            WinnerIds : res.data.id, loading : false})

        })
        .catch(err =>{
          console.log(err.data)
          this.toggleModal("notificationModal")
          this.setState({notif_error_visible : true, loading : false})
          this.setState({errorMessage : "Erreur: il n y a aucun participant à ce tirage au sort," +
                " ou vous devez charger vote compte Random.org"})
        })
  }

  render() {

    return (
      <>
        {!this.state.authPage ?
            <div className="spinner-pages spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
            </div> :
            <>
              <Sidebar
                  {...this.props}
                  routes={routes}

              />
              <div className="main-content" ref="mainContent">
                <AdminNavbar/>

                <Header/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                  {/* Table */}
                  <Row>
                    <div className="col">
                      <Card className="shadow">

                        <CardHeader className="border-0">
                          <h2 className="mb-0" style={{textAlignLast: "center"}}>Temps restants</h2>
                          <Row style={{justifyContent: "center", borderBottom: "1px solid #0000002e"}}>
                            <h3 style={{marginTop: "10px", marginRight: "20px"}}>
                              {this.state.daysSpan} Jours
                            </h3>
                            <h3 style={{marginTop: "10px", marginRight: "20px"}}>
                              {this.state.hoursSpan} Heures
                            </h3>
                            <h3 style={{marginTop: "10px", marginRight: "20px"}}>
                              {this.state.minutesSpan} Minutes
                            </h3>
                            <h3 style={{marginTop: "10px", marginRight: "20px"}}>
                              {this.state.secondsSpan} Secondes
                            </h3>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Alert color="danger" isOpen={this.state.notif_error_visible}
                                 toggle={() => this.toggleModal("notif_error_visible")}>
                            {this.state.errorMessage} <br/>
                          </Alert>

                          <Alert color="success" isOpen={this.state.notif_success_visible}
                                 toggle={() => this.toggleModal("notif_success_visible")}>
                            <h5>Numéros gagnants</h5>
                            {this.state.WinnerNumbers.map((v) => (
                                <p>{v}</p>
                            ))
                            }
                            <h5>Id joueurs gagnants</h5>
                            {this.state.WinnerIds.map((v) => (
                                <p>{v}</p>
                            ))
                            }
                          </Alert>
                          {!this.state.LectureMode &&
                          <Button block color="danger" size="lg" type="button"
                                  onClick={() => this.toggleModal("notificationModal")}>
                            Lancer le tirage
                          </Button>
                          }

                          <Modal
                              className="modal-dialog-centered modal-danger"
                              contentClassName="bg-gradient-danger"
                              isOpen={this.state.notificationModal}
                              toggle={() => this.toggleModal("notificationModal")}
                          >
                            <div className="modal-header">
                              <h6 className="modal-title" id="modal-title-notification">
                                Votre attention est requise
                              </h6>
                              <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => this.toggleModal("notificationModal")}
                              >
                                <span aria-hidden={true}>×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x"/>
                                <h4 className="heading mt-4">VOUS DEVRIEZ LIRE CECI!</h4>
                                <p>
                                  Vos êtes sur le point de lancer un tirage au sort, Êtes-vous certain de vouloir faire
                                  ça ?
                                </p>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <Button className="btn-white" color="default" type="button" disabled={this.state.loading}
                                      onClick={() => this.Do_draw()}>
                                {this.state.loading ? <div className="spinner-border" role="status">
                                </div> : <>Ok, compris</>}

                              </Button>
                              <Button
                                  className="text-white ml-auto"
                                  color="link"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => this.toggleModal("notificationModal")}
                              >
                                Fermer
                              </Button>
                            </div>
                          </Modal>

                          <Table className="align-items-center table-flush mt-5" responsive id={"cordonnee-table"}>
                            <thead className="thead-light">
                            <tr>
                              <th className="th-sort" scope="col" onClick={() => sortTable(0)}>
                                Id<TiArrowUnsorted/>
                              </th>
                              <th className="th-sort" scope="col" onClick={() => sortTable(1)}>
                                Nombre de participants<TiArrowUnsorted/></th>
                              <th className="th-sort" scope="col" onClick={() => sortTable(2)}>
                                Nombre de gagnants<TiArrowUnsorted/>
                              </th>
                              <th className="th-sort" scope="col" onClick={() => sortTable(3)}>
                                Date de tirage <TiArrowUnsorted/>
                              </th>
                              <th className="th-sort" scope="col">
                                Lien vers résultat <TiArrowUnsorted/>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.Draws.map((row) => (
                                <tr key={row.drawId}>
                                  <td>{row.drawId}</td>
                                  <td>{row.entryCount}</td>
                                  <td>{row.winnerCount}</td>
                                  <td>{ FormatDate(row.completionTime)}</td>
                                  <td><a href={row.recordUrl}>{row.recordUrl}</a></td>
                                </tr>
                            ))}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </div>
                  </Row>

                </Container>
                <Container fluid>
                  <AdminFooter/>
                </Container>
              </div>
            </>
        }
      </>
    );
  }
}

export default Do_Draw;
