import axios from 'axios'
import React from 'react'
import {IsLoggedIn} from '../Helpers/index'
import { Redirect, Route } from 'react-router-dom'


// export const register = (newUser) => {
//     return axios.post('api/users/register', {
//             first_name: newUser.first_name,
//             last_name: newUser.last_name,
//             email: newUser.email,
//             password: newUser.password,
//             imageFile: newUser.data
//         })
//         .then(response => {
//             console.log('Registered')
//         })
// }
//
//
export const login = user => {
    return axios
        .post('api/users/login', {
            email: user.email,
            password: user.password
        })
        .then(response => {
            localStorage.setItem('usertoken', response.data)
            return ["success" , response.data]
        })
        .catch(err => {
            console.log("auth Service" , err.response)
            return ["failed",err.response.data];
        })
}


// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.


export const PrivateRoute = ({ component: Component, path, ...rest}) => {

    // Add your own authentication on the below line.
    const isLoggedIn = IsLoggedIn()[0]

    console.log("is logged in :" + isLoggedIn)
    return (
        <Route

            path={path}
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/auth', state: { prevLocation: path } }} />
                )
            }
        />
    )
}

