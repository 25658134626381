import React from "react";
// reactstrap components
import {
    Badge,
    Button,
    Card, CardBody,
    CardFooter,
    CardHeader,
    Container, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row, Spinner,
    Table
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, notification} from "antd";
import "./GlobalesCss.css";
import {TiArrowUnsorted} from "react-icons/ti";
import {AiOutlineEye} from "react-icons/ai"
import {FiEdit2} from "react-icons/fi"
import Tooltip from "react-simple-tooltip"
import {RiDeleteBin2Line} from "react-icons/ri";
import {Link} from "react-router-dom";
import Col from "reactstrap/lib/Col";
import {convertDateTime, IsLoggedIn} from '../Helpers/index'
import ReactExport from "react-export-excel";
import {Authorized, FormatDate} from "../Helpers";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Search_In_Table() {
  var input, filter, table, found, tr, td, i, j, txtValue;
  input = document.getElementById("input-cord-table");
  filter = input.value.toUpperCase();
  table = document.getElementById("cordonnee-table");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td");
    for (j = 0; j < td.length; j++) {
      if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
        found = true;
      }
    }
    if (found) {
      tr[i].style.display = "";
      found = false;
    } else {
      if (i>0) { //this skips the headers
        tr[i].style.display = "none";
      }
    }
  }

}

function sortTable(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("cordonnee-table");
  switching = true;
  //Set the sorting direction to ascending:
  dir = "asc";
  /*Make a loop that will continue until
  no switching has been done:*/
  while (switching) {
    //start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /*Loop through all table rows (except the
    first, which contains table headers):*/
    for (i = 1; i < (rows.length - 1); i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /*Get the two elements you want to compare,
      one from current row and one from the next:*/
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /*check if the two rows should switch place,
      based on the direction, asc or desc:*/
        if (n===2){
            x= convertDateTime(x.innerHTML)
            y = convertDateTime(y.innerHTML)
            if (dir == "asc") {
                if (x > y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir == "desc") {
                if (x < y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        else {
            if (dir === "asc") {
                if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir === "desc") {
                if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
    }
    if (shouldSwitch) {
      /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      //Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /*If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again.*/
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}


function DownloadPdfFile(FileName, byte, FileType) {
    let blob = new Blob([new Uint8Array(byte)], {type: FileType});
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = FileName;
    link.click();
}

class VB_MC extends React.Component {
  constructor() {
    super()
    this.state = {
      joueurs:[],
        notificationModal: false,
        loading : true,
        idDelete:"",
        authPage:false,
        LectureMode : false

    }

  }

    openCustomNotification_Success = (msg) => {
        notification['success']({
            message: 'Succès',
            description:
            msg,
        });
    };
    openNotificationError = () => {
        notification['error']({
            message: 'Erreur',
            description:
                'Une erreur s\'est produite veuillez réessayer.',
        });
    };


    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

  componentDidMount() {
      Authorized("VB_MC").then(r=>{
          if (r.data.Role === 2){ // if role is super user see if access to this page is giver
              if (r.data.Access){
                  this.setState({authPage:true})
              }
              else{
                  window.location.replace("/");
              }
          }else if(r.data.Role === 3) {
              this.setState({LectureMode : true, authPage:true})
          }else
              this.setState({authPage:true})
      }).catch(
          this.setState({authPage:true})
      )
      console.log("vb")
      axios.get('/api/vb-mc')
        .then(res=>{
          if (res.data){
            this.setState({
              joueurs: Object.values(res.data),
                loading : false
            });
          }
        })
        .catch(err =>{
            this.setState({
                loading : false
            });
          console.log(err.data)
        })

  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

    async removeAdmin(id) {
        this.toggleModal("notificationModal")
        await axios.delete("api/vb-mc/"+id)
            .then((res) =>{
                // console.log(res.data)
                console.log('ok');
                let filteredArray = this.state.joueurs.filter(item => item.idPayment !== id)
                this.setState({joueurs: filteredArray});
                this.openCustomNotification_Success("Le virement a été supprimé avec succès")
            })
            .catch(err =>{
                console.log("erreur : " + err)
                this.openNotificationError()
            })

    }

  render() {
    return (
      <>
          {!this.state.authPage ?
              <div className="spinner-pages spinner-border text-primary " role="status">
                  <span className="sr-only">Loading...</span>
              </div> :
              <>
        <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("assets/img/brand/argon-react.png"),
              imgAlt: "..."
            }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
              {...this.props}
              brandText = {this.getBrandText(this.props.location.pathname)}
          />

        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h2 className="mb-0" style={{textAlign: "center"}}>Paiements</h2>
                </CardHeader>
                  <Row>
                      <Col md={2}>
                          <Input type={"text"} onKeyUp={Search_In_Table} placeholder={"Chercher"} id="input-cord-table"/>
                      </Col>
                      {!this.state.LectureMode &&
                          <>
                      <Col>
                          <Link to={'/add-VB-MC'}>
                              <button className="btn"><i className="fa fa-plus"/> Ajouter</button>
                          </Link>

                      </Col>

                      <Col>
                          <ExcelFile element={
                              <Button className="export-btn btn-primary">
                                  Exporter</Button>
                          } filename={"Paiements"}>
                              {this.state.joueurs===[] ? <ExcelSheet data={this.state.joueurs} name="Paiements">
                                  <ExcelColumn label="" value=""/>
                              </ExcelSheet>
                                  :
                                  <ExcelSheet data={this.state.joueurs} name="Paiements">
                                      <ExcelColumn label="Pseudonyme" value="Pseudonyme"/>
                                      <ExcelColumn label="Motif / Objet"
                                                   value={(row) => row.PaymentType ==="ABO-VB" ||
                                                   row.PaymentType ==="ABO-MC" ?row.token :
                                                       ""}/>
                                      <ExcelColumn label="Date"
                                                   value={(col) => FormatDate(col.PaymentDate)}/>
                                      <ExcelColumn label="Moyen de paiement"
                                                   value={(col) => col.PaymentType==="ABO-VB" ? "VB" :
                                                       (col.PaymentType==="ABO-MC" ? "MC" : "CB")
                                                   }/>
                                      <ExcelColumn label="Nombre de tirage" value="solde"/>
                                  </ExcelSheet>
                              }
                          </ExcelFile>
                      </Col>
                      </>
                          }
                  </Row>
                  {this.state.loading ?
                  <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                  </div> :
                <Table className="align-items-center table-flush" responsive id={"cordonnee-table"}>
                  <thead className="thead-light">
                    <tr>
                      <th className="th-sort" scope="col" onClick={() => sortTable(0)}>Pseudonyme <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(1)}>Objet / motif <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(2)}>Date<TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(3)}>Moyen de paiement
                          <TiArrowUnsorted/></th>
                        {!this.state.LectureMode &&
                        <th className="th-sort" scope="col">Action</th>
                        }
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.joueurs.map((row) => (
                      <tr key={row.id}>
                          <td>{row.Pseudonyme}</td>
                          <td>{row.PaymentType ==="ABO-VB" || row.PaymentType ==="ABO-MC" ?row.token :
                          ""}</td>
                          <td>{FormatDate(row.PaymentDate)}</td>
                          <td>
                              {row.PaymentType ==="ABO-VB" ? <label>VB</label> :
                                  (row.PaymentType ==="ABO-MC" ? <label>MC</label> : <label>CB</label>)
                              }
                          </td>
                          {!this.state.LectureMode &&
                          <td>{row.PaymentType ==="ABO-VB" || row.PaymentType ==="ABO-MC" ?
                              <Tooltip content={"Supprimer"} radius={7} padding={8}>
                                  <a onClick={() => {
                                      this.toggleModal("notificationModal")
                                      this.setState({idDelete: row.idPayment})
                                  }
                                  } style={{cursor: "pointer"}}>
                                      <RiDeleteBin2Line size="20px" style={{color: "red"}}/>
                                  </a>
                              </Tooltip>:
                              ""}

                          </td>
                          }
                      </tr>
                  ))}
                  </tbody>
                </Table>
                      }
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>

            <Modal
                className="modal-dialog-centered modal-danger"
                contentClassName="bg-gradient-danger"
                isOpen={this.state.notificationModal}
                toggle={() => this.toggleModal("notificationModal")}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        Votre attention est requise
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("notificationModal")}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        <i className="ni ni-bell-55 ni-3x" />
                        <h4 className="heading mt-4">VOUS DEVRIEZ LIRE CECI!</h4>
                        <p>
                            Vos êtes sur le point de supprimer ce virement, Êtes-vous certain de vouloir faire ça ?
                        </p>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button className="btn-white" color="default" type="button" disabled={this.state.loading}
                            onClick={() => this.removeAdmin(this.state.idDelete)}>
                        {this.state.loading ? <div className="spinner-border" role="status">
                        </div>: <>Ok, compris</>}

                    </Button>
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("notificationModal")}
                    >
                        Fermer
                    </Button>
                </div>
            </Modal>


            <Container fluid>
            <AdminFooter />
          </Container>
        </div>
              </>
              }
      </>
    );
  }
}

export default VB_MC;
