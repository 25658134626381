import React from "react";
import {Effect, Modal, ModalManager} from "react-dynamic-modal";
import Col from "reactstrap/es/Col";
import {FormatDate, getStatutColor, getStatutWord} from "../Helpers";
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
import {TiArrowUnsorted} from "react-icons/ti";
import axios from "axios";

function sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("cordonnee-table");
    switching = true;
    //Set the sorting direction to ascending:
    dir = "asc";
    /*Make a loop that will continue until
    no switching has been done:*/
    while (switching) {
        //start by saying: no switching is done:
        switching = false;
        rows = table.rows;
        /*Loop through all table rows (except the
        first, which contains table headers):*/
        for (i = 1; i < (rows.length - 1); i++) {
            //start by saying there should be no switching:
            shouldSwitch = false;
            /*Get the two elements you want to compare,
            one from current row and one from the next:*/
            x = rows[i].getElementsByTagName("TD")[n];
            y = rows[i + 1].getElementsByTagName("TD")[n];
            /*check if the two rows should switch place,
            based on the direction, asc or desc:*/
            if (dir == "asc") {
                if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir == "desc") {
                if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        if (shouldSwitch) {
            /*If a switch has been marked, make the switch
            and mark that a switch has been done:*/
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            //Each time a switch is done, increase this count by 1:
            switchcount ++;
        } else {
            /*If no switching has been done AND the direction is "asc",
            set the direction to "desc" and run the while loop again.*/
            if (switchcount == 0 && dir == "asc") {
                dir = "desc";
                switching = true;
            }
        }
    }
}

class parrainage_details extends React.Component{
    constructor() {
        super()
        this.state = {
            parrains:[],
            loading : true,
        }
    }

    componentDidMount() {
        axios.post('api/parrainages/parrains',{Code : this.props.Code})
            .then(res=>{
                console.log(res.data);
                this.setState({parrains : res.data,loading : false})
            })
            .catch(err =>{
                console.log(err.data)
                this.setState({loading : false})
            })
    }

    render(){
        const { onRequestClose } = this.props;
        return (
            <Modal
                onRequestClose={onRequestClose}
                effect={Effect.Fall} style={{content: { margin:'3% auto', padding: "2.5rem", height:"80%"}}}>
                <Row>
                    <Table className="align-items-center table-flush" responsive
                           id={"cordonnee-table"}>
                        <thead className="thead-light">
                        <tr>
                            <th className="th-sort" scope="col" onClick={() => sortTable(0)}>Identifiant
                                <TiArrowUnsorted/></th>
                            <th className="th-sort" scope="col"
                                onClick={() => sortTable(1)}>Pseudonyme<TiArrowUnsorted/></th>
                            <th className="th-sort" scope="col"
                                onClick={() => sortTable(2)}>Date d'inscription<TiArrowUnsorted/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.parrains.map((row) => (
                            <tr key={row.id}>

                                <td>{row.id}</td>
                                <td>{row.Pseudonyme}</td>
                                <td>{FormatDate(row.created)}</td>


                            </tr>
                        ))}
                        </tbody>

                    </Table>
                </Row>

                <Button color="secondary" type="button" onClick={ModalManager.close}
                        style={{float : "right", marginTop : "20px"}}>
                    Fermer
                </Button>

            </Modal>
        );
    }
}

export default parrainage_details;
