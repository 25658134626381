
var routes = [
  {
    label : "/",
    path: "/index",
    name: "Accueil",
    icon: "ni ni-tv-2 text-primary",
    // component: Index,
    //layout: "/admin"
  },
  {
    label : "A",
    path: "/admins",
    name: "Administrateurs",
    icon: "fa fa-user-lock",
    // component: Utilisateurs,
    //layout: "/admin"
  },
  {
    label : "AL",
    path: "/admin-logs",
    name: "Activités Admins",
    icon: "fa fa-history",
    // component: Utilisateurs,
    //layout: "/admin"
  },
  {
    label : "J",
    path: "/user",
    name: "Joueurs",
    icon: "ni ni-circle-08",
    // component: Utilisateurs,
    //layout: "/admin"
  },

  {
    label : "NL",
    path: "/newsletter",
    name: "Newsletter",
    icon: "fa fa-envelope",
    // component: Utilisateurs,
    //layout: "/admin"
  },
  {
    label : "JA",
    path: "/user-archived",
    name: "Joueurs Archivés",
    icon: "ni ni-archive-2",
    // component: Utilisateurs,
    //layout: "/admin"
  },
  {
    label : "PAR",
    path: "/parrainages",
    name: "Parrainages",
    icon: "ni ni-trophy",
    // component: Utilisateurs,
    //layout: "/admin"
  },
  {
    label : "V",
    path: "/votes",
    name: "Tickets et votes",
    icon: "fa fa-ticket-alt",
    // component: Utilisateurs,
    //layout: "/admin"
  },
  /*{
    label : "NUM",
      path: "/tirage-number",
      name: "Numéros tirages",
      icon: "ni ni-collection",
      // component: Utilisateurs,
      //layout: "/admin"
  },*/
  {
    label : "PC",
      path: "/vb-mc",
      name: "Paiements",
      icon: "ni ni-credit-card",
      // component: Utilisateurs,
      //layout: "/admin"
  },
  /*{
    label : "VB_MC",
      path: "/vb-mc",
      name: "Paiements par VBMC",
      icon: "fa fa-university",
      // component: Utilisateurs,
      //layout: "/admin"
  },*/
  {
    label : "C",
    path: "/credits",
      name: "Crédits",
      icon: "fa fa-star",
      // component: Utilisateurs,
      //layout: "/admin"
  },
  {
    label : "LT",
    path: "/do-draw",
    name: "Lancer le tirage",
    icon: "ni ni-watch-time text-info",
    //layout: "/admin"
  },
  {
    label : "G",
    path: "/winners",
    name: "Gagnants",
    icon: "fa fa-award",
    //layout: "/admin"
  },
  {
    label : "PA",
    path: "/pages",
    name: "Pages",
    icon: "ni ni-ungroup text-default",
    //layout: "/admin"
  },
  {
    label : "PA2",
    path: "/pages-2",
    name: "Pages (2)",
    icon: "ni ni-ungroup text-default",
    //layout: "/admin"
  },
  {
    label : "FAQ_CDJ",
    path: "/faq-conditions-jeu",
    name: "FAQ's > Conditions de jeu",
    icon: "ni ni-ungroup text-default",
    //layout: "/admin"
  },
  {
    label : "FAQ_J",
    path: "/faq-le-jeu",
    name: "FAQ's > Le jeu",
    icon: "ni ni-ungroup text-default",
    //layout: "/admin"
  },
  {
    label : "FAQ_S",
    path: "/faq-securite",
    name: "FAQ's > Sécurité",
    icon: "ni ni-ungroup text-default",
    //layout: "/admin"
  },

  {
    label : "FAQ_T",
    path: "/faq-le-tirage",
    name: "FAQ's > Le tirage",
    icon: "ni ni-ungroup text-default",
    //layout: "/admin"
  },

  {
    label : "FAQ_G",
    path: "/faq-le-gain",
    name: "FAQ's > Les gains",
    icon: "ni ni-ungroup text-default",
    //layout: "/admin"
  },

];
export default routes;
