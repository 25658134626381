import React from "react";
// reactstrap components
import {
  Button,
  Card, CardBody,
  CardFooter,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import 'react-best-tabs/dist/index.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw,convertFromHTML,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './GlobalesCss.css'
import 'draft-js/dist/Draft.css';

// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, notification} from "antd";
import "./GlobalesCss.css";

import {Authorized, getTimeRemaining, nextSaturday} from "../Helpers";
import { Tabs } from 'antd';


let deadline2 = nextSaturday();
deadline2.setHours(21,0,0,);

const { TabPane } = Tabs;

class conditions_jeu extends React.Component {
  constructor() {
    super()
    this.state = {
      authPage:false,
      LectureMode : false,
      editorState_54: EditorState.createEmpty(),
      editorState_55: EditorState.createEmpty(),
      editorState_56: EditorState.createEmpty(),
      editorState_57: EditorState.createEmpty(),
      editorState_58: EditorState.createEmpty(),
      editorState_59: EditorState.createEmpty(),
      editorState_60: EditorState.createEmpty(),
      editorState_61: EditorState.createEmpty(),
      editorState_62: EditorState.createEmpty(),
      editorState_63: EditorState.createEmpty(),
      editorState_64: EditorState.createEmpty(),
      editorState_65: EditorState.createEmpty(),
      editorState_136: EditorState.createEmpty(),
      editorState_137: EditorState.createEmpty(),
      editorState_138: EditorState.createEmpty(),
      editorState_139: EditorState.createEmpty(),
    }

    this.onEditorStateChange_54 = this.onEditorStateChange_54.bind(this);
    this.onEditorStateChange_55 = this.onEditorStateChange_55.bind(this);
    this.onEditorStateChange_56 = this.onEditorStateChange_65.bind(this);
    this.onEditorStateChange_57 = this.onEditorStateChange_57.bind(this);
    this.onEditorStateChange_58 = this.onEditorStateChange_58.bind(this);
    this.onEditorStateChange_59 = this.onEditorStateChange_59.bind(this);
    this.onEditorStateChange_60 = this.onEditorStateChange_60.bind(this);
    this.onEditorStateChange_61 = this.onEditorStateChange_61.bind(this);
    this.onEditorStateChange_62 = this.onEditorStateChange_62.bind(this);
    this.onEditorStateChange_63 = this.onEditorStateChange_63.bind(this);
    this.onEditorStateChange_64 = this.onEditorStateChange_64.bind(this);
    this.onEditorStateChange_65 = this.onEditorStateChange_65.bind(this);
    this.onEditorStateChange_136 = this.onEditorStateChange_136.bind(this);
    this.onEditorStateChange_137 = this.onEditorStateChange_137.bind(this);
    this.onEditorStateChange_138 = this.onEditorStateChange_138.bind(this);
    this.onEditorStateChange_139 = this.onEditorStateChange_139.bind(this);
  }

  onEditorStateChange_54(editorState){
    this.setState({
      editorState_54 : editorState,
    });
  };
  onEditorStateChange_55(editorState){
    this.setState({
      editorState_55 : editorState,
    });
  };
  onEditorStateChange_56(editorState){
    this.setState({
      editorState_56 : editorState,
    });
  };
  onEditorStateChange_57(editorState){
    this.setState({
      editorState_57 : editorState,
    });
  };
  onEditorStateChange_58(editorState){
    this.setState({
      editorState_58 : editorState,
    });
  };
  onEditorStateChange_59(editorState){
    this.setState({
      editorState_59 : editorState,
    });
  };
  onEditorStateChange_60(editorState){
    this.setState({
      editorState_60 : editorState,
    });
  };
  onEditorStateChange_61(editorState){
    this.setState({
      editorState_61 : editorState,
    });
  };

  onEditorStateChange_62(editorState){
    this.setState({
      editorState_62 : editorState,
    });
  };
  onEditorStateChange_63(editorState){
    this.setState({
      editorState_63 : editorState,
    });
  };
  onEditorStateChange_64(editorState){
    this.setState({
      editorState_64 : editorState,
    });
  };

  onEditorStateChange_65(editorState){
    this.setState({
      editorState_65 : editorState,
    });
  };

  onEditorStateChange_136(editorState){
    this.setState({
      editorState_136 : editorState,
    });
  };

  onEditorStateChange_137(editorState){
    this.setState({
      editorState_137 : editorState,
    });
  };

  onEditorStateChange_138(editorState){
    this.setState({
      editorState_138 : editorState,
    });
  };

  onEditorStateChange_139(editorState){
    this.setState({
      editorState_139 : editorState,
    });
  };


  openNotification = () => {
    notification['success']({
      message: 'Succès',
      description:
          'La page a été mis à jour avec succès !',
    });
  };

  openNotificationError = () => {
    notification['error']({
      message: 'Erreur',
      description:
          'Une erreur s\'est produite veuillez réessayer.',
    });
  };

  componentDidMount() {
    Authorized("FAQ_CDJ").then(r=>{
      if (r.data.Role === 2){ // if role is super user see if access to this page is giver
        if (r.data.Access){
          this.setState({authPage:true})
        }
        else{
          window.location.replace("/");
        }
      }else if(r.data.Role === 3) {
        this.setState({LectureMode : true, authPage:true})
      }else
        this.setState({authPage:true})
    }).catch(
        this.setState({authPage:true})
    )


    axios
        .get('api/pages')
        .then(res=>{
          // console.log("Tous les pages : " , res.data.Pages[0].contenu_page);
          let data = res.data.Pages
          data.map(p =>{
            // console.log(p.contenu_page)
            const blocksFromHTML = htmlToDraft(p.contenu_page);
            const temp = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );

            this.setState({
              ["editorState_"+p.id_page]: EditorState.createWithContent(temp),
            });
          })


        })
        .catch(err =>{
          console.log(err.data)
        })
    // this.initializeClock(deadline2);
  }

  openNotificationErrorProfile = (msg) => {
    notification['error']({
      message: 'Erreur',
      description:
      msg,
    });
  };
  updatePage = async (id) =>{
    if(this.state.LectureMode){
      this.openNotificationErrorProfile("vous n'êtes pas autorisé à effectuer cette action")
    }else {
      await axios.post("api/pages", {
        contenu_page: draftToHtml(convertToRaw(this.state['editorState_' + id].getCurrentContent())),
        id_page: id,
      })
          .then((res) => {
            console.log(res)
            this.openNotification()
          })
          .catch(() => {
            this.openNotificationError()
          })
    }
  }


  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };


  render() {

    return (
      <>
        {!this.state.authPage ?
            <div className="spinner-pages spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
            </div> :
            <>
        <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("assets/img/brand/argon-react.png"),
              imgAlt: "..."
            }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
              {...this.props}
              brandText = {this.getBrandText(this.props.location.pathname)}
          />

        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">

                <CardHeader className="border-0">
                  <h2 className="mb-0" style={{textAlignLast: "center"}}>Pages</h2>

                </CardHeader>
                <CardBody>

                  <h3>Question 1 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_54}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_54}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(54)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <div style={{margin: "50px"}}/>
                  <h3>Réponse 1 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_60}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_60}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(60)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <hr/>

                  <h3>Question 2 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_55}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_55}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(55)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <div style={{margin: "50px"}}/>
                  <h3>Réponse 2 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_61}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_61}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(61)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <hr/>

                  <h3>Question 3 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_56}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_56}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(56)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <div style={{margin: "50px"}}/>
                  <h3>Réponse 3 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_62}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_62}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(62)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <hr/>

                  <h3>Question 4 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_57}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_57}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(57)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <div style={{margin: "50px"}}/>
                  <h3>Réponse 4 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_63}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_63}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(63)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <hr/>

                  <h3>Question 5 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_58}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_58}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(58)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <div style={{margin: "50px"}}/>
                  <h3>Réponse 5 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_64}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_64}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(64)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <hr/>

                  <h3>Question 6 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_59}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_59}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(59)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <div style={{margin: "50px"}}/>
                  <h3>Réponse 6 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_65}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_65}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(65)} style={{marginTop : "30px"}}>Sauvegarder</Button>

                  <hr/>
                  <h3>Question 7 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_136}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_136}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(136)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <div style={{margin: "50px"}}/>
                  <h3>Réponse 7 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_137}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_137}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(137)} style={{marginTop : "30px"}}>Sauvegarder</Button>

                  <hr/>
                  <h3>Question 8 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_138}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_138}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(138)} style={{marginTop : "30px"}}>Sauvegarder</Button>
                  <div style={{margin: "50px"}}/>
                  <h3>Réponse 8 :</h3>
                  <div className="mt-3">
                    <Editor
                        editorState={this.state.editorState_139}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange_139}
                    />
                  </div>
                  <Button onClick={() => this.updatePage(139)} style={{marginTop : "30px"}}>Sauvegarder</Button>

                </CardBody>
                <CardFooter className="py-4">
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>

          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
              </>
        }
      </>
    );
  }
}

export default conditions_jeu;
