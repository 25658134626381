import React from "react";
// reactstrap components
import {
    Badge,
    Button,
    Card, CardBody,
    CardFooter,
    CardHeader,
    Container, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row, Spinner,
    Table
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, notification} from "antd";
import "./GlobalesCss.css";
import {TiArrowUnsorted} from "react-icons/ti";
import {AiOutlineEye} from "react-icons/ai"
import {FiEdit2} from "react-icons/fi"
import Tooltip from "react-simple-tooltip"
import {RiDeleteBin2Line} from "react-icons/ri";
import {Link} from "react-router-dom";
import Col from "reactstrap/lib/Col";
import {convertDate, convertDateTime, IsLoggedIn} from '../Helpers/index'
import ReactExport from "react-export-excel";
import {Authorized, FormatDate} from "../Helpers";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Search_In_Table() {
  var input, filter, table, found, tr, td, i, j, txtValue;
  input = document.getElementById("input-cord-table");
  filter = input.value.toUpperCase();
  table = document.getElementById("cordonnee-table");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td");
    for (j = 0; j < td.length; j++) {
      if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
        found = true;
      }
    }
    if (found) {
      tr[i].style.display = "";
      found = false;
    } else {
      if (i>0) { //this skips the headers
        tr[i].style.display = "none";
      }
    }
  }

}

function sortTable(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("cordonnee-table");
  switching = true;
  //Set the sorting direction to ascending:
  dir = "asc";
  /*Make a loop that will continue until
  no switching has been done:*/
  while (switching) {
    //start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /*Loop through all table rows (except the
    first, which contains table headers):*/
    for (i = 1; i < (rows.length - 1); i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /*Get the two elements you want to compare,
      one from current row and one from the next:*/
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /*check if the two rows should switch place,
      based on the direction, asc or desc:*/
      console.log(n , y.innerHTML)
      if (n===4){
            x= convertDateTime(x.innerHTML)
            y = convertDateTime(y.innerHTML)
            if (dir == "asc") {
                if (x > y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir == "desc") {
                if (x < y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        else {
          if (dir === "asc") {
              if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                  //if so, mark as a switch and break the loop:
                  shouldSwitch= true;
                  break;
              }
          } else if (dir === "desc") {
              if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                  //if so, mark as a switch and break the loop:
                  shouldSwitch = true;
                  break;
              }
          }
        }

    }
    if (shouldSwitch) {
      /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      //Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /*If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again.*/
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}


function DownloadPdfFile(FileName, byte, FileType) {
    let blob = new Blob([new Uint8Array(byte)], {type: FileType});
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = FileName;
    link.click();
}

class Admins extends React.Component {
  constructor() {
    super()
    this.state = {
      joueurs:[],
        notificationModal: false,
        loading : true,
        idDelete:""
    }

  }

    openCustomNotification_Success = (msg) => {
        notification['success']({
            message: 'Succès',
            description:
            msg,
        });
    };
    openNotificationError = () => {
        notification['error']({
            message: 'Erreur',
            description:
                'Une erreur s\'est produite veuillez réessayer.',
        });
    };


    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

  componentDidMount() {
      if(IsLoggedIn()[0] && IsLoggedIn()[1].Role === 1){
          console.log("auth")
      }
      else
          window.location.replace("/");
      axios.get('api/admins')
        .then(res=>{
          if (res.data.Players){
            this.setState({
              joueurs: Object.values(res.data.Players),
                loading : false
            });
          }
        })
        .catch(err =>{
            this.setState({
                loading : false
            });
          console.log(err.data)
        })

  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

    async removeAdmin(id) {
        this.toggleModal("notificationModal")
        await axios.delete("api/admins/"+id)
            .then((res) =>{
                // console.log(res.data)
                console.log('ok');
                let filteredArray = this.state.joueurs.filter(item => item.id !== id)
                this.setState({joueurs: filteredArray});
                this.openCustomNotification_Success("L'administarteur a été supprimé avec succès")
            })
            .catch(err =>{
                console.log("erreur : " + err)
                this.openNotificationError()
            })

    }

    DownloadExcel = () =>{
        console.log("download")
        return(
            <ExcelFile>
                <ExcelSheet data={this.state.joueurs} name="Joueurs">
                    <ExcelColumn label="id" value="id"/>
                    <ExcelColumn label="Prénom" value="first_name"/>
                    <ExcelColumn label="Nom" value="last_name"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
            </ExcelFile>
        )
}

  render() {
    return (
      <>
        <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("assets/img/brand/argon-react.png"),
              imgAlt: "..."
            }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
              {...this.props}
              brandText = {this.getBrandText(this.props.location.pathname)}
          />

        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h2 className="mb-0" style={{textAlign: "center"}}>Administrateurs</h2>
                </CardHeader>
                  <Row>
                      <Col md={2}>
                          <Input type={"text"} onKeyUp={Search_In_Table} placeholder={"Chercher"} id="input-cord-table"/>
                      </Col>
                      <Col>
                          <Link to={'/add-admin'}>
                              <button className="btn"><i className="fa fa-plus"/> Ajouter</button>
                          </Link>

                      </Col>
                      <Col>
                          <ExcelFile element={
                              <Button className="export-btn btn-primary">
                                  Exporter</Button>
                          } filename={"Liste joueurs"}>
                              {this.state.joueurs===[] ? <ExcelSheet data={this.state.joueurs} name="Joueurs">
                                  <ExcelColumn label="E-mail" value="email"/>
                              </ExcelSheet>
                                  :
                                  <ExcelSheet data={this.state.joueurs} name="Joueurs">
                                      <ExcelColumn label="id" value="id"/>
                                      <ExcelColumn label="Pseudonyme" value="Pseudonyme"/>
                                      <ExcelColumn label="Prénom" value="first_name"/>
                                      <ExcelColumn label="Nom" value="last_name"/>
                                      <ExcelColumn label="Nom marital" value="nom_marital"/>
                                      <ExcelColumn label="Sexe" value="gender"/>
                                      <ExcelColumn label="E-mail" value="email"/>
                                      <ExcelColumn label="Date de naissance" value="Date_Naissance"/>
                                      <ExcelColumn label="Ville de naissance" value="Ville_Naissance"/>
                                      <ExcelColumn label="Ville de residence" value="Ville_Residence"/>
                                      <ExcelColumn label="Pays de residence" value="Pays_Residence"/>
                                      <ExcelColumn label="Numéro de téléphone" value="Num_telephone"/>
                                      <ExcelColumn label="Statut"
                                                   value={(col) => col.validation_piece ==="true" ? "Validée" :
                                                       "Non validée"}/>
                                      <ExcelColumn label="Date de création du compte"
                                                   value={(col) => FormatDate(col.created)}/>
                                  </ExcelSheet>
                              }
                          </ExcelFile>
                      </Col>
                  </Row>
                  {this.state.loading ?
                  <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                  </div> :
                <Table className="align-items-center table-flush" responsive id={"cordonnee-table"}>
                  <thead className="thead-light">
                    <tr>
                      <th className="th-sort" scope="col" onClick={() => sortTable(0)}>Prénom <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(1)}>Nom <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(2)}>E-mail <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(3)}>Nom d'utilisateur <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(4)}>date de création <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(5)}>Rôle <TiArrowUnsorted/></th>
                      <th className="th-sort" scope="col">Action</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.joueurs.map((row) => (
                      <tr key={row.id}>
                          <td>{row.first_name}</td>
                          <td>{row.last_name}</td>
                          <td>{row.email}</td>
                          <td>{row.Pseudonyme}</td>
                          <td>{FormatDate(row.created)}</td>
                          <td>
                              {row.Role ===1 ? <label>Admin</label> : <>{row.Role === 2 ?
                                  <label>Super User</label> : <label>User</label>}</>
                              }
                          </td>
                        <td>
                            <Tooltip content={"Modifier"} radius={7} padding={8}>
                                <Link to={{
                                    pathname: "/editAdmin/"+row.id
                                }}><FiEdit2 size="20px" style={{color : "#fddb3a" , marginRight: "10px"}} /></Link>
                            </Tooltip>
                            <Tooltip content={"Supprimer"} radius={7} padding={8}>
                                <a onClick={() => {this.toggleModal("notificationModal")
                                    this.setState({idDelete : row.id})}
                                } style={{cursor : "pointer"}}>
                                    <RiDeleteBin2Line size="20px" style={{color : "red"}}/>
                                </a>
                            </Tooltip>
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </Table>
                      }
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>

            <Modal
                className="modal-dialog-centered modal-danger"
                contentClassName="bg-gradient-danger"
                isOpen={this.state.notificationModal}
                toggle={() => this.toggleModal("notificationModal")}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        Votre attention est requise
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("notificationModal")}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        <i className="ni ni-bell-55 ni-3x" />
                        <h4 className="heading mt-4">VOUS DEVRIEZ LIRE CECI!</h4>
                        <p>
                            Vos êtes sur le point de supprimer ce compte, Êtes-vous certain de vouloir faire ça ?
                        </p>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button className="btn-white" color="default" type="button" disabled={this.state.loading}
                            onClick={() => this.removeAdmin(this.state.idDelete)}>
                        {this.state.loading ? <div className="spinner-border" role="status">
                        </div>: <>Ok, compris</>}

                    </Button>
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("notificationModal")}
                    >
                        Fermer
                    </Button>
                </div>
            </Modal>


            <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admins;
