import React from "react";
// reactstrap components
import {
  Button,
  Card, CardBody,
  CardFooter,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import 'react-best-tabs/dist/index.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw,convertFromHTML,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './GlobalesCss.css'
import 'draft-js/dist/Draft.css';

// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, notification} from "antd";
import "./GlobalesCss.css";

import {Authorized, getTimeRemaining, nextSaturday} from "../Helpers";
import { Tabs } from 'antd';


let deadline2 = nextSaturday();
deadline2.setHours(21,0,0,);

const { TabPane } = Tabs;

class Pages_2 extends React.Component {
  constructor() {
    super()
    this.state = {
      joueurs:[],
      notif_visible : false,
      Modal_Detail: true,
      notificationModal: false,
      authPage:false,
      LectureMode : false,
      editorState_7: EditorState.createEmpty(),
      editorState_4: EditorState.createEmpty(),
      editorState_8: EditorState.createEmpty(),
      editorState_9: EditorState.createEmpty(),
      editorState_10: EditorState.createEmpty(),
      editorState_11: EditorState.createEmpty(),
      editorState_12: EditorState.createEmpty(),
      editorState_13: EditorState.createEmpty(),
      editorState_14: EditorState.createEmpty(),
      editorState_15: EditorState.createEmpty(),
      editorState_16: EditorState.createEmpty(),
      editorState_34: EditorState.createEmpty(),
      editorState_135: EditorState.createEmpty(),
    }

    this.onEditorStateChange_7 = this.onEditorStateChange_7.bind(this);
    this.onEditorStateChange_4 = this.onEditorStateChange_4.bind(this);
    this.onEditorStateChange_8 = this.onEditorStateChange_8.bind(this);
    this.onEditorStateChange_9 = this.onEditorStateChange_9.bind(this);
    this.onEditorStateChange_10 = this.onEditorStateChange_10.bind(this);
    this.onEditorStateChange_11 = this.onEditorStateChange_11.bind(this);
    this.onEditorStateChange_12 = this.onEditorStateChange_12.bind(this);
    this.onEditorStateChange_13 = this.onEditorStateChange_13.bind(this);
    this.onEditorStateChange_14 = this.onEditorStateChange_14.bind(this);
    this.onEditorStateChange_15 = this.onEditorStateChange_15.bind(this);
    this.onEditorStateChange_16 = this.onEditorStateChange_16.bind(this);
    this.onEditorStateChange_34 = this.onEditorStateChange_34.bind(this);
    this.onEditorStateChange_135 = this.onEditorStateChange_135.bind(this);
  }

  onEditorStateChange_7(editorState){
    this.setState({
      editorState_7 : editorState,
    });
  };
  onEditorStateChange_4(editorState){
    this.setState({
      editorState_4 : editorState,
    });
  };
  onEditorStateChange_8(editorState){
    this.setState({
      editorState_8 : editorState,
    });
  };
  onEditorStateChange_9(editorState){
    this.setState({
      editorState_9 : editorState,
    });
  };
  onEditorStateChange_10(editorState){
    this.setState({
      editorState_10 : editorState,
    });
  };
  onEditorStateChange_11(editorState){
    this.setState({
      editorState_11 : editorState,
    });
  };
  onEditorStateChange_12(editorState){
    this.setState({
      editorState_12 : editorState,
    });
  };
  onEditorStateChange_13(editorState){
    this.setState({
      editorState_13 : editorState,
    });
  };

  onEditorStateChange_14(editorState){
    this.setState({
      editorState_14 : editorState,
    });
  };
  onEditorStateChange_15(editorState){
    this.setState({
      editorState_15 : editorState,
    });
  };
  onEditorStateChange_16(editorState){
    this.setState({
      editorState_16 : editorState,
    });
  };

  onEditorStateChange_34(editorState){
    this.setState({
      editorState_34 : editorState,
    });
  };

  onEditorStateChange_135(editorState){
    this.setState({
      editorState_135 : editorState,
    });
  };


  openNotification = () => {
    notification['success']({
      message: 'Succès',
      description:
          'La page est mis à jour avec succès !',
    });
  };

  openNotificationError = () => {
    notification['error']({
      message: 'Erreur',
      description:
          'Une erreur s\'est produite veuillez réessayer.',
    });
  };

  toggle = () => this.setState({Modal_Detail : !this.state.Modal_Detail});
  componentDidMount() {
    Authorized("PA2").then(r=>{
      if (r.data.Role === 2){ // if role is super user see if access to this page is giver
        if (r.data.Access){
          this.setState({authPage:true})
        }
        else{
          window.location.replace("/");
        }
      }else if(r.data.Role === 3) {
        this.setState({LectureMode : true, authPage:true})
      }else
        this.setState({authPage:true})
    }).catch(
        this.setState({authPage:true})
    )
    axios
        .get('api/pages')
        .then(res=>{
          // console.log("Tous les pages : " , res.data.Pages[0].contenu_page);
          let data = res.data.Pages
          data.map(p =>{
            // console.log(p.contenu_page)
            const blocksFromHTML = htmlToDraft(p.contenu_page);
            const temp = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );

            this.setState({
              ["editorState_"+p.id_page]: EditorState.createWithContent(temp),
            });
          })
        })
        .catch(err =>{
          console.log(err.data)
        })
    // this.initializeClock(deadline2);
  }

  onDismiss = () => this.setState({notif_visible : false})
  openNotificationErrorProfile = (msg) => {
    notification['error']({
      message: 'Erreur',
      description:
      msg,
    });
  };
  updatePage = async (id) =>{
    if(this.state.LectureMode){
      this.openNotificationErrorProfile("vous n'êtes pas autorisé à effectuer cette action")
    }else {
      await axios.post("api/pages", {
        contenu_page: draftToHtml(convertToRaw(this.state['editorState_' + id].getCurrentContent())),
        id_page: id,
      })
          .then((res) => {
            console.log(res)
            this.openNotification()
          })
          .catch(() => {
            this.openNotificationError()
          })
    }
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };


  render() {

    return (
      <>
        {!this.state.authPage ?
            <div className="spinner-pages spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
            </div> :
            <>
              <Sidebar
                  {...this.props}
                  routes={routes}
                  logo={{
                    innerLink: "/admin/index",
                    imgSrc: require("assets/img/brand/argon-react.png"),
                    imgAlt: "..."
                  }}
              />
              <div className="main-content" ref="mainContent">
                <AdminNavbar
                    {...this.props}
                    brandText={this.getBrandText(this.props.location.pathname)}
                />

                <Header/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                  {/* Table */}
                  <Row>
                    <div className="col">
                      <Card className="shadow">

                        <CardHeader className="border-0">
                          <h2 className="mb-0" style={{textAlignLast: "center"}}>Pages</h2>

                        </CardHeader>
                        <CardBody>

                          <Tabs defaultActiveKey="1" className="mt-5" activityClassName="bg-success">
                            {/* Les risques pour le joueur */}
                            <TabPane key={"1"} tab="Les risques pour le joueur" className="mr-3">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_7}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_7}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(7)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* ALGORITHME VS. MACHINES A BOULES */}
                            <TabPane key={"2"} tab="ALGORITHME VS. MACHINES A BOULES" className="mr-3">
                              <h3>Texte en haut : </h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_15}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_15}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(15)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>
                              <h3 className="mt-5 mb-3">Texte à gauche: </h3>
                              <div className="mt-4">

                                <Editor
                                    editorState={this.state.editorState_8}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_8}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(8)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>
                              <h3 className="mt-5 mb-3">Texte à droite: </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_14}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_14}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(14)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>
                              <h3 className="mt-5 mb-3">Texte en bas: </h3>
                              <div className="mt-4">
                                <Editor
                                    editorState={this.state.editorState_16}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_16}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(16)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* Accessibilité */}
                            <TabPane key={"3"} tab="Accessibilité" className="mr-3">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_13}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_13}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(13)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* CGU */}
                            <TabPane key={"4"} tab="CGU" className="mr-3">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_9}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_9}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(9)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* CGV */}
                            <TabPane key={"6"} tab="CGV" className="mr-3">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_10}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_10}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(10)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* Politique de confidentialité */}
                            <TabPane tab="Politique de confidentialité" className="mr-3" key="7">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_4}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_4}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(4)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>

                            {/* Mentions légales */}
                            <TabPane key={"5"} tab="Mentions légales" className="mr-3">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_11}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_11}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(11)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>


                            {/* ********* Les gains ************** */}
                            <TabPane key={"8"} tab="Les gains" className="mr-3">
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_135}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_135}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(135)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                            </TabPane>


                          </Tabs>
                        </CardBody>
                        <CardFooter className="py-4">
                        </CardFooter>
                      </Card>
                    </div>
                  </Row>
                </Container>

                <Container fluid>
                  <AdminFooter/>
                </Container>
              </div>
            </>
        }
      </>
    );
  }
}

export default Pages_2;
