import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import logo_com from "../../assets/img/comonpot.png"
import axios from "axios";
import routes from "../../routes";

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    routes:routes,
    loading : true,

  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  componentDidMount(): void {
    axios.get('/api/access')
        .then((r)=>{
          console.log(r)
          if (r.data.Role === 2)
          {
            const valuesToRemove = r.data.Access
            let filteredItems = this.state.routes.filter(item => item.label !== "A")
            this.setState({routes: filteredItems})
            valuesToRemove.map(v=>{
              let filteredItems = this.state.routes.filter(item => item.label !== v.AccessLabel)
              this.setState({routes: filteredItems})
            })
            this.setState({loading : false})
          }
          else {
            this.setState({loading : false})

          }
        })
        .catch(()=>            this.setState({loading : false})
        )
  }

  // creates the links that appear in the left menu / Sidebar
/*
  createLinks =  routes => {
    return routes.map((prop, key) => {
            return (
                <NavItem key={key}>
                  <NavLink
                      to={prop.path}
                      tag={NavLinkRRD}
                      onClick={this.closeCollapse}
                      activeClassName="active"
                  >
                    <i className={prop.icon} />
                    {prop.name}
                  </NavLink>
                </NavItem>
            );
        })

  };
*/
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: "/",
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}

            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt="com on pot logo"
                className="navbar-brand-img"
                src={logo_com}
              />
            </NavbarBrand>

          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav-link-icon">
                <i className="ni ni-bell-55" />
              </DropdownToggle>

            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("../../assets/img/theme/Groupe 36002.png")}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bienvenue!</h6>
                </DropdownItem>
                <DropdownItem to="/ad-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Mon profil</span>
                </DropdownItem>


                <DropdownItem onClick={e=>{e.preventDefault();
                  localStorage.removeItem('usertoken');
                  console.log("deconnexion ...")
                  window.location.reload();                }}>
                  <i className="ni ni-user-run" />
                  <span>Déconnexion</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                  <Col className="collapse-brand" xs="6">
                      <Link to={"/"}>
                        <img alt={"com on pot logo"} src={logo_com} />
                      </Link>

                  </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            {/* Navigation */}
            {this.state.loading ?
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div> :
                <Nav navbar>
                  {this.state.routes.map((prop, key) => {
                    return (
                        <NavItem key={key}>
                          <NavLink
                              to={prop.path}
                              tag={NavLinkRRD}
                              onClick={this.closeCollapse}
                              activeClassName="active"
                          >
                            <i className={prop.icon}/>
                            {prop.name}
                          </NavLink>
                        </NavItem>
                    );
                  })}
                </Nav>
            }
            {/* Divider */}
            <hr className="my-3" />
            {/* Heading */}


          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;
