import React from "react";
// reactstrap components
import {
  Button,
  Card, CardBody,
  CardFooter,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import 'react-best-tabs/dist/index.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw,convertFromHTML,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './GlobalesCss.css'
import 'draft-js/dist/Draft.css';

// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, notification} from "antd";
import "./GlobalesCss.css";

import {Authorized, getTimeRemaining, nextSaturday} from "../Helpers";
import { Tabs } from 'antd';


let deadline2 = nextSaturday();
deadline2.setHours(21,0,0,);

const { TabPane } = Tabs;

class le_tirage extends React.Component {
  constructor() {
    super()
    this.state = {
      authPage:false,
      LectureMode : false,
      loading : true,
      editorState_105: EditorState.createEmpty(),
      editorState_106: EditorState.createEmpty(),
      editorState_107: EditorState.createEmpty(),
      editorState_108: EditorState.createEmpty(),
      editorState_109: EditorState.createEmpty(),
      editorState_110: EditorState.createEmpty(),
      editorState_129: EditorState.createEmpty(),
      editorState_130: EditorState.createEmpty(),
      editorState_131: EditorState.createEmpty(),
      editorState_132: EditorState.createEmpty(),
      editorState_133: EditorState.createEmpty(),
      editorState_134: EditorState.createEmpty(),
    }

    this.onEditorStateChange_105 = this.onEditorStateChange_105.bind(this);
    this.onEditorStateChange_106 = this.onEditorStateChange_106.bind(this);
    this.onEditorStateChange_107 = this.onEditorStateChange_107.bind(this);
    this.onEditorStateChange_108 = this.onEditorStateChange_108.bind(this);
    this.onEditorStateChange_109 = this.onEditorStateChange_109.bind(this);
    this.onEditorStateChange_110 = this.onEditorStateChange_110.bind(this);
    this.onEditorStateChange_129 = this.onEditorStateChange_129.bind(this);
    this.onEditorStateChange_130 = this.onEditorStateChange_130.bind(this);
    this.onEditorStateChange_131 = this.onEditorStateChange_131.bind(this);
    this.onEditorStateChange_132 = this.onEditorStateChange_132.bind(this);
    this.onEditorStateChange_133 = this.onEditorStateChange_133.bind(this);
    this.onEditorStateChange_134 = this.onEditorStateChange_134.bind(this);

  }

  onEditorStateChange_105(editorState){
    this.setState({
      editorState_105 : editorState,
    });
  };
  onEditorStateChange_106(editorState){
    this.setState({
      editorState_106 : editorState,
    });
  };
  onEditorStateChange_107(editorState){
    this.setState({
      editorState_107 : editorState,
    });
  };
  onEditorStateChange_108(editorState){
    this.setState({
      editorState_108: editorState,
    });
  };
  onEditorStateChange_109(editorState){
    this.setState({
      editorState_109 : editorState,
    });
  };
  onEditorStateChange_110(editorState){
    this.setState({
      editorState_110 : editorState,
    });
  };
  onEditorStateChange_129(editorState){
    this.setState({
      editorState_129 : editorState,
    });
  };
  onEditorStateChange_130(editorState){
    this.setState({
      editorState_130 : editorState,
    });
  };
  onEditorStateChange_131(editorState){
    this.setState({
      editorState_131 : editorState,
    });
  };
  onEditorStateChange_132(editorState){
    this.setState({
      editorState_132 : editorState,
    });
  };
  onEditorStateChange_133(editorState){
    this.setState({
      editorState_133 : editorState,
    });
  };
  onEditorStateChange_134(editorState){
    this.setState({
      editorState_134 : editorState,
    });
  };


  openNotification = () => {
    notification['success']({
      message: 'Succès',
      description:
          'La page a été mis à jour avec succès !',
    });
  };

  openNotificationError = () => {
    notification['error']({
      message: 'Erreur',
      description:
          'Une erreur s\'est produite veuillez réessayer.',
    });
  };
  openNotificationErrorProfile = (msg) => {
    notification['error']({
      message: 'Erreur',
      description:
      msg,
    });
  };

  componentDidMount() {
    Authorized("FAQ_T").then(r=>{
      if (r.data.Role === 2){ // if role is super user see if access to this page is giver
        if (r.data.Access){
          this.setState({authPage:true})
        }
        else{
          window.location.replace("/");
        }
      }else if(r.data.Role === 3) {
        this.setState({LectureMode : true, authPage:true})
      }else
        this.setState({authPage:true})
    }).catch(
        this.setState({authPage:true})
    )

    axios
        .get('api/pages')
        .then(res=>{
          // console.log("Tous les pages : " , res.data.Pages[0].contenu_page);
          let data = res.data.Pages
          data.map(p =>{
            // console.log(p.contenu_page)
            const blocksFromHTML = htmlToDraft(p.contenu_page);
            const temp = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );

            this.setState({
              ["editorState_"+p.id_page]: EditorState.createWithContent(temp),
            });
          })
          this.setState({loading : false})


        })
        .catch(err =>{
          console.log(err.data)
          this.setState({loading : false})
        })
    // this.initializeClock(deadline2);
  }


  updatePage = async (id) =>{
    if(this.state.LectureMode){
      this.openNotificationErrorProfile("vous n'êtes pas autorisé à effectuer cette action")
    }else {
      await axios.post("api/pages", {
        contenu_page: draftToHtml(convertToRaw(this.state['editorState_' + id].getCurrentContent())),
        id_page: id,
      })
          .then((res) => {
            console.log(res)
            this.openNotification()
          })
          .catch(() => {
            this.openNotificationError()
          })
    }
  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };


  render() {

    return (
      <>
        {!this.state.authPage ?
            <div className="spinner-pages spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
            </div> :
            <>
              <Sidebar
                  {...this.props}
                  routes={routes}
                  logo={{
                    innerLink: "/admin/index",
                    imgSrc: require("assets/img/brand/argon-react.png"),
                    imgAlt: "..."
                  }}
              />
              <div className="main-content" ref="mainContent">
                <AdminNavbar
                    {...this.props}
                    brandText={this.getBrandText(this.props.location.pathname)}
                />

                <Header/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                  {/* Table */}
                  <Row>
                    <div className="col">
                      <Card className="shadow">

                        <CardHeader className="border-0">
                          <h2 className="mb-0" style={{textAlignLast: "center"}}>Pages</h2>

                        </CardHeader>
                        {this.state.loading ?
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Loading...</span>
                            </div> :
                            <CardBody>

                              <h3>Question 1 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_105}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_105}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(105)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <div style={{margin: "50px"}}/>
                              <h3>Réponse 1 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_108}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_108}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(108)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3>Question 2 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_106}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_106}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(106)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <div style={{margin: "50px"}}/>
                              <h3>Réponse 2 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_109}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_109}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(109)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <hr/>

                              <h3>Question 3 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_107}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_107}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(107)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <div style={{margin: "50px"}}/>
                              <h3>Réponse 3 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_110}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_110}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(110)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>
                              <div style={{margin: "50px"}}/>
                              <h3>Question 4 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_129}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_129}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(129)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <div style={{margin: "50px"}}/>
                              <h3>Réponse 4 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_130}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_130}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(130)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <div style={{margin: "50px"}}/>
                              <h3>Question 5 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_131}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_131}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(131)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <div style={{margin: "50px"}}/>
                              <h3>Réponse 5 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_132}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_132}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(132)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <div style={{margin: "50px"}}/>
                              <h3>Question 6 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_133}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_133}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(133)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                              <div style={{margin: "50px"}}/>
                              <h3>Réponse 6 :</h3>
                              <div className="mt-3">
                                <Editor
                                    editorState={this.state.editorState_134}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange_134}
                                />
                              </div>
                              <Button onClick={() => this.updatePage(134)}
                                      style={{marginTop: "30px"}}>Sauvegarder</Button>

                            </CardBody>
                        }
                      </Card>
                    </div>
                  </Row>
                </Container>

                <Container fluid>
                  <AdminFooter/>
                </Container>
              </div>
            </>
        }
      </>
    );
  }
}

export default le_tirage;
