/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class DetailsJoueursHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 d-flex align-items-center"
          style={{
            minHeight: "500px",
            backgroundImage:
              "url(" + require("../../assets/img/comonpot.png") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col>
                <h1 className="display-2 text-white">Details du Joueur :</h1>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default DetailsJoueursHeader;
