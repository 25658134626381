import React from "react";
// reactstrap components
import {
  Button,
  Card, CardBody,
  CardFooter,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import 'react-best-tabs/dist/index.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw,convertFromHTML,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './GlobalesCss.css'
import 'draft-js/dist/Draft.css';

// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, notification} from "antd";
import "./GlobalesCss.css";

import {Authorized, getTimeRemaining, nextSaturday} from "../Helpers";
import { Tabs } from 'antd';


let deadline2 = nextSaturday();
deadline2.setHours(21,0,0,);

const { TabPane } = Tabs;

class les_gains extends React.Component {
  constructor() {
    super()
    this.state = {
      authPage:false,
      LectureMode : false,
      editorState_111: EditorState.createEmpty(),
      editorState_112: EditorState.createEmpty(),
      editorState_113: EditorState.createEmpty(),
      editorState_114: EditorState.createEmpty(),
      editorState_115: EditorState.createEmpty(),
      editorState_116: EditorState.createEmpty(),

    }

    this.onEditorStateChange_111 = this.onEditorStateChange_111.bind(this);
    this.onEditorStateChange_112 = this.onEditorStateChange_112.bind(this);
    this.onEditorStateChange_113 = this.onEditorStateChange_113.bind(this);
    this.onEditorStateChange_114 = this.onEditorStateChange_114.bind(this);
    this.onEditorStateChange_115 = this.onEditorStateChange_115.bind(this);
    this.onEditorStateChange_116 = this.onEditorStateChange_116.bind(this);

  }

  onEditorStateChange_111(editorState){
    this.setState({
      editorState_111 : editorState,
    });
  };
  onEditorStateChange_112(editorState){
    this.setState({
      editorState_112 : editorState,
    });
  };
  onEditorStateChange_113(editorState){
    this.setState({
      editorState_113 : editorState,
    });
  };
  onEditorStateChange_114(editorState){
    this.setState({
      editorState_114: editorState,
    });
  };
  onEditorStateChange_115(editorState){
    this.setState({
      editorState_115 : editorState,
    });
  };
  onEditorStateChange_116(editorState){
    this.setState({
      editorState_116 : editorState,
    });
  };


  openNotification = () => {
    notification['success']({
      message: 'Succès',
      description:
          'La page a été mis à jour avec succès !',
    });
  };

  openNotificationError = () => {
    notification['error']({
      message: 'Erreur',
      description:
          'Une erreur s\'est produite veuillez réessayer.',
    });
  };

  openNotificationErrorProfile = (msg) => {
    notification['error']({
      message: 'Erreur',
      description:
      msg,
    });
  };

  componentDidMount() {
    Authorized("FAQ_G").then(r=>{
      if (r.data.Role === 2){ // if role is super user see if access to this page is giver
        if (r.data.Access){
          this.setState({authPage:true})
        }
        else{
          window.location.replace("/");
        }
      }else if(r.data.Role === 3) {
        this.setState({LectureMode : true, authPage:true})
      }else
        this.setState({authPage:true})
    }).catch(
        this.setState({authPage:true})
    )


    axios
        .get('api/pages')
        .then(res=>{
          // console.log("Tous les pages : " , res.data.Pages[0].contenu_page);
          let data = res.data.Pages
          data.map(p =>{
            // console.log(p.contenu_page)
            const blocksFromHTML = htmlToDraft(p.contenu_page);
            const temp = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );

            this.setState({
              ["editorState_"+p.id_page]: EditorState.createWithContent(temp),
            });
          })


        })
        .catch(err =>{
          console.log(err.data)
        })
    // this.initializeClock(deadline2);
  }


  updatePage = async (id) =>{
    if(this.state.LectureMode){
      this.openNotificationErrorProfile("vous n'êtes pas autorisé à effectuer cette action")
    }else {
      await axios.post("api/pages", {
        contenu_page: draftToHtml(convertToRaw(this.state['editorState_' + id].getCurrentContent())),
        id_page: id,
      })
          .then((res) => {
            console.log(res)
            this.openNotification()
          })
          .catch(() => {
            this.openNotificationError()
          })
    }
  }


  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  render() {

    return (
      <>
        {!this.state.authPage ?
            <div className="spinner-pages spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
            </div> :
            <>
              <Sidebar
                  {...this.props}
                  routes={routes}
                  logo={{
                    innerLink: "/admin/index",
                    imgSrc: require("assets/img/brand/argon-react.png"),
                    imgAlt: "..."
                  }}
              />
              <div className="main-content" ref="mainContent">
                <AdminNavbar
                    {...this.props}
                    brandText={this.getBrandText(this.props.location.pathname)}
                />

                <Header/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                  {/* Table */}
                  <Row>
                    <div className="col">
                      <Card className="shadow">

                        <CardHeader className="border-0">
                          <h2 className="mb-0" style={{textAlignLast: "center"}}>Pages</h2>

                        </CardHeader>
                        <CardBody>

                          <h3>Question 1 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_111}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_111}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(111)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 1 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_114}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_114}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(114)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 2 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_112}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_112}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(112)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 2 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_115}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_115}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(115)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 3 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_113}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_113}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(113)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 3 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_116}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_116}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(116)} style={{marginTop: "30px"}}>Sauvegarder</Button>

                        </CardBody>
                      </Card>
                    </div>
                  </Row>
                </Container>

                <Container fluid>
                  <AdminFooter/>
                </Container>
              </div>
            </>
        }
      </>
    );
  }
}

export default les_gains;
