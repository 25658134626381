import jwt_decode from "jwt-decode";
import jwt from 'jsonwebtoken'
import axios from "axios";

// import store from '../redux/store';

// import {useDispatch, useSelector} from "react-redux";
// import {disableVote, enableVote} from "../redux/Actions";

/**
 * @return {*[]}
 */
export function IsLoggedIn(){
    try {
        const token = localStorage.usertoken;
         let decodedToken=jwt.decode(token, {complete: true});
        if(jwt_decode(token).exp < Date.now() / 1000 && !jwt.verify(token, process.env.jwtPrivateKey)){
            return [false]
        }else{
            //console.log("logged")
            return [true , decodedToken.payload]
        }
    } catch (err) {
        return false;
    }
}

/**
 * @return {string}
 */
export function FormatDate(_date) {
    let date = new Date(_date);
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let mins = date.getMinutes();
    let MyDateString = ('0' + day).slice(-2) + '/'
        + ('0' + (month)).slice(-2) + '/' + year + " " +
        ('0' + (hours)).slice(-2) + ":" + ('0' + (mins)).slice(-2);
    return( MyDateString);
}
/**
 * @return {string}
 */
export function FormatDateWithoutHour(_date) {
    let date = new Date(_date);
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    let MyDateString = ('0' + day).slice(-2) + '/'
        + ('0' + (month)).slice(-2) + '/' + year;
    return( MyDateString);
}

export async function Authorized(AccessLabel) {
    return axios.get('/api/access/'+AccessLabel)
        .then(r=>{
            return(r)
        })
}

export function getStatutColor(statut){
    switch (statut) {
        case statut === false : return ("bg-error");
        case true : return ("bg-success")
    }
}

export function getStatutWord(statut){
    switch (statut) {
        case false : return ("Non validé");
        case true : return ("Validé")
    }
}


export const montant_participation = 3;

export function getYear_Week(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    let weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}


export const calculateTimeLeft = () => {
    let difference = +new Date(nextSaturday()) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }
    console.log(" difference : " + difference);
    console.log("time left : " + timeLeft)
    return difference;
}
/**
 * for getting next saturday
 * @param dayIndex 0 for monday 1 2 ... 0 for sunday
 * @returns {Date}
 */
export const nextSaturday = (dayIndex = 6) => {
    let today = new Date();
    today.setDate(today.getDate() + (dayIndex - 1 - today.getDay() + 7) % 7 + 1);
    return today;
}

export function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
        total,
        days,
        hours,
        minutes,
        seconds
    };
}

export function get_choix_tirage(Montant_cagnotte) {

    console.log("montant : "+Montant_cagnotte)
    if (Montant_cagnotte < 10000.99)
        return [2, 3, 4]
    else if (Montant_cagnotte < 50000.99)
        return [2, 4, 5]
    else if (Montant_cagnotte < 100000.99)
        return [3, 4, 6]
    else if (Montant_cagnotte < 500000.99)
        return [3, 5, 7]
    else if (Montant_cagnotte < 1000000.99)
        return [3, 6, 8]
    else if (Montant_cagnotte < 5000000.99)
        return [4, 7, 9]
    else if (Montant_cagnotte < 10000000.99)
        return [4, 8, 10]
    else
        return [5, 9, 13]
}


export function convertDate(d) {
    let p = d.split("/");
    return +(p[2]+p[1]+p[0]);
}

export function convertDateTime(d) {
    let p = d.split("/");
    let res = p[2].split(" ")
    console.log(res[0]+p[1]+p[0]+res[1])
    return res[0]+p[1]+p[0]+res[1];
}
