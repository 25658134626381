import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col, Alert
} from "reactstrap";
import axios from "axios";

// core components
import UserHeader from "../components/Headers/UserHeader.js";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import {IsLoggedIn} from "../Helpers";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
const UserData = IsLoggedIn()[1];


class tiragesAdmin extends React.Component {

  constructor() {
    super()
    this.state = {
      dateDebut: '',
      dateFin: '',
      HeureDebut: '',
      HeureFin: '', notif_visible : false
    }
    this.onchange = this.onchange.bind(this)
  }

  componentDidMount() {
    fetch('api/dateTirage')
        .then(res => {
          console.log(res);
          return res.json()
        })
        .then(user => {
          console.log(user);
          this.setState({
            dateDebut: user.dateDebut,
            dateFin: user.dateFin,
            HeureDebut: user.HeureDebut,
            HeureFin: user.HeureFin,
          })
        });

  }
  onDismiss = () => this.setState({notif_visible : false})
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  onchange(e){
    e.preventDefault()
    const { name, value } = e.target;
    this.setState({[name]: value})

  }

  updateDates = async (e) =>{
      await axios.post("api/dateTirage",{
        dateDebut:  this.state.dateDebut,
        dateFin: this.state.dateFin,
        HeureDebut: this.state.HeureDebut,
        HeureFin: this.state.HeureFin,
      })
          .then((res) =>{
              this.setState({notif_visible : true})
          })
    }

  render() {
    return (
      <>
        <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("../assets/img/brand/argon-react.png"),
              imgAlt: "..."
            }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
              {...this.props}
              brandText = {this.getBrandText(this.props.location.pathname)}
          />
        <UserHeader pseudonyme = {UserData.Pseudonyme}/>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" >
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Gestion des tirages </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Alert color="success" isOpen={this.state.notif_visible} toggle={this.onDismiss}>
                    Les dates ont été mis à jour avec succès !
                  </Alert>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Dates de débloquage des votes
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Date de début :
                            </label>
                            <RadioGroup aria-label="gender" name="dateDebut"
                                        value={this.state.dateDebut} onChange={this.onchange}>
                              <FormControlLabel value={"1"} control={<Radio />} label="Lundi" />
                              <FormControlLabel value={"2"} control={<Radio />} label="Mardi" />
                              <FormControlLabel value={"3"} control={<Radio />} label="Mercredi" />
                              <FormControlLabel value={"4"} control={<Radio />} label="Jeudi" />
                              <FormControlLabel value={"5"} control={<Radio />} label="Vendredi" />
                              <FormControlLabel value={"6"} control={<Radio />} label="Samedi" />
                              <FormControlLabel value={"0"} control={<Radio />} label="Dimanche" />
                            </RadioGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-email">Heure de début :</label>
                            <Input type="select" name="HeureDebut" value={this.state.HeureDebut}
                                   onChange={this.onchange} required={true}>
                              <option value={"0"}>00 h</option>
                              <option value={"1"}>1 h</option>
                              <option value={"2"}>2 h</option>
                              <option value={"3"}>3 h</option>
                              <option value={"4"}>4 h</option>
                              <option value={"5"}>5 h</option>
                              <option value={"6"}>6 h</option>
                              <option value={"7"}>7 h</option>
                              <option value={"8"}>8 h</option>
                              <option value={"9"}>9 h</option>
                              <option value={"10"}>10 h</option>
                              <option value={"11"}>11 h</option>
                              <option value={"12"}>12 h</option>
                              <option value={"13"}>13 h</option>
                              <option value={"14"}>14 h</option>
                              <option value={"15"}>15 h</option>
                              <option value={"16"}>16 h</option>
                              <option value={"17"}>17 h</option>
                              <option value={"18"}>18 h</option>
                              <option value={"19"}>19 h</option>
                              <option value={"20"}>20 h</option>
                              <option value={"21"}>21 h</option>
                              <option value={"22"}>22 h</option>
                              <option value={"23"}>23 h</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-username">Date de fin</label>
                            <RadioGroup aria-label="gender" name="dateFin" value={this.state.dateFin}
                                        onChange={this.onchange}>
                              <FormControlLabel value={"1"} control={<Radio />} label="Lundi" />
                              <FormControlLabel value={"2"} control={<Radio />} label="Mardi" />
                              <FormControlLabel value={"3"} control={<Radio />} label="Mercredi" />
                              <FormControlLabel value={"4"} control={<Radio />} label="Jeudi" />
                              <FormControlLabel value={"5"} control={<Radio />} label="Vendredi" />
                              <FormControlLabel value={"6"} control={<Radio />} label="Samedi" />
                              <FormControlLabel value={"0"} control={<Radio />} label="Dimanche" />
                            </RadioGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-email">
                              Heure de fin
                            </label>
                            <Input type="select" name="HeureFin" value={this.state.HeureFin}
                                   onChange={this.onchange} required={true}>
                              <option value={"0"}>00 h</option>
                              <option value={"1"}>1 h</option>
                              <option value={"2"}>2 h</option>
                              <option value={"3"}>3 h</option>
                              <option value={"4"}>4 h</option>
                              <option value={"5"}>5 h</option>
                              <option value={"6"}>6 h</option>
                              <option value={"7"}>7 h</option>
                              <option value={"8"}>8 h</option>
                              <option value={"9"}>9 h</option>
                              <option value={"10"}>10 h</option>
                              <option value={"11"}>11 h</option>
                              <option value={"12"}>12 h</option>
                              <option value={"13"}>13 h</option>
                              <option value={"14"}>14 h</option>
                              <option value={"15"}>15 h</option>
                              <option value={"16"}>16 h</option>
                              <option value={"17"}>17 h</option>
                              <option value={"18"}>18 h</option>
                              <option value={"19"}>19 h</option>
                              <option value={"20"}>20 h</option>
                              <option value={"21"}>21 h</option>
                              <option value={"22"}>22 h</option>
                              <option value={"23"}>23 h</option>
                            </Input>

                          </FormGroup>
                        </Col>
                      </Row>
                      {/*<Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Prénom
                            </label>
                            <Input
                              className="form-control-alternative"
                              Value={this.state.first_name}
                              id="input-first-name"
                              placeholder="Prénom"
                              type="text"
                              onChange={this.onchange}
                              disabled={true}
                              required={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Nom de famille
                            </label>
                            <Input
                              className="form-control-alternative"
                              Value={this.state.last_name}
                              id="input-last-name"
                              placeholder="Nom de famille"
                              type="text"
                              onChange={this.onchange}
                              disabled={true}
                              required={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Numéro de téléphone
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="Num_telephone"
                              Value={this.state.Num_telephone}
                              id="input-phone"
                              placeholder="Numéro de téléphone"
                              type="text"
                              onChange={this.onchange}
                              required={true}
                            />
                          </FormGroup>
                        </Col>

                      </Row>*/}
                      <Row>
                        <Col>
                        <Button
                            color="info"
                            onClick={this.updateDates.bind(this)}
                        >
                          Sauvegarder les modifications
                        </Button>
                        </Col>
                      </Row>

                    </div>

                    <hr className="my-4" />

                    {/* Mot de passe */}
                      <h6 className="heading-small text-muted mb-4">
                        Changez votre mot de passe
                      </h6>
                      {/*<div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                              >
                                Nouveau mot de passe
                              </label>
                              <Input
                                  className="form-control-alternative"
                                  name="password"
                                  Value={this.state.password}
                                  id="input-password"
                                  type="password"
                                  onChange={this.onchange}
                                  required={true}
                              />
                              <span className= "input-error">
                                {this.state.hasError_password_confirm ?  "Entrez le même nouveau mot de passe deux fois " : "" }</span>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                              >
                                Ré-entrez le nouveau mot de passe
                              </label>
                              <Input
                                  className="form-control-alternative"
                                  name="confirm_password"
                                  Value={this.state.confirm_password}
                                  id="input-confirm-password"
                                  type="password"
                                  onChange={this.onchange}
                                  required={true}
                              />
                              <span className="input-error">
                                {this.state.hasError_password_confirm ?  "Entrez le même nouveau mot de passe deux fois " : "" }</span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Button
                              color="info"
                              onClick={this.updatePassword.bind(this)}
                          >
                            Changer le mot de passe
                          </Button>
                        </Row>
                      </div>*/}
                    {/*<hr className="my-4" />*/}
                    {/* Description */}
                    {/*<h6 className="heading-small text-muted mb-4">About me</h6>*/}
                    {/*<div className="pl-lg-4">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          className="form-control-alternative"
                          placeholder="A few words about you ..."
                          rows="4"
                          defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                          Open Source."
                          type="textarea"
                        />
                      </FormGroup>
                    </div>*/}

                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default tiragesAdmin;
