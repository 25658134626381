import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col, Alert
} from "reactstrap";
import axios from "axios";

// core components
import UserHeader from "../components/Headers/UserHeader.js";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import AdminFooter from "../components/Footers/AdminFooter";
import {IsLoggedIn} from "../Helpers";
import {notification, Radio, Select, Space, Switch} from "antd";
import AdminNavbar from "../components/Navbars/AdminNavbar";

const { Option } = Select;

class Add_VB_MC extends React.Component {

  constructor() {
    super()
    this.state = {
      user:[],
      pseudo:[],
      loading: true,
      objet:"",
      montant:"",
      moyen:"ABO-VB",
      hasError_objet:false,
      hasError_montant:false,
      hasError_Pseudonyme:false,
      Pseudonyme: "",

    }
    this.onchange = this.onchange.bind(this)
    this.onChange_Pseudo = this.onChange_Pseudo .bind(this)
    this.onChange_moyen = this.onChange_moyen .bind(this)

    this.addAdmin = this.addAdmin.bind(this)
    this.validateData = this.validateData.bind(this)

  }

  componentDidMount() {
    if(IsLoggedIn()[0] && IsLoggedIn()[1].Role === 1){
      console.log("auth")
    }
    else
      window.location.replace("/");

    axios.get('api/vb-mc/all')
        .then(res=>{
          console.log("Tous les pseudos: " , res.data);
          this.setState({
            pseudo: res.data,
            loading: false
          });
        })
        .catch(err =>{
          console.log(err.data)
          this.setState({
            loading: false
          });
        })

  }


  openNotification = () => {
    notification['success']({
      message: 'Succès',
      description:
          "L'utilisateur a été ajouté avec succès",
    });
  };

  openNotificationError = () => {
    notification['error']({
      message: 'Erreur',
      description:
          'Une erreur s\'est produite veuillez réessayer.',
    });
  };

  openNotificationErrorProfile = (msg) => {
    notification['error']({
      message: 'Erreur',
      description:
          msg,
    });
  };

  onchange(e){
    e.preventDefault()
    const { name, value } = e.target;
    this.setState({[name]: value})

  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  async validateData(){
    let NoER = true

    if(this.state.objet.length<1){
      NoER = false
      this.setState({hasError_objet : true})
    }
    else this.setState({hasError_objet : false})

    if(this.state.Pseudonyme.length<1){
      NoER = false
      this.setState({hasError_Pseudonyme : true})
    }else
      this.setState({hasError_Pseudonyme : false})

    if(this.state.montant.length<1){
      NoER = false
      this.setState({hasError_montant : true})
    }else
      this.setState({hasError_montant : false})

    return NoER
  }

  addAdmin = async () =>{

    if(await this.validateData()){
        axios.post("api/vb-mc/",{
          Pseudonyme: this.state.Pseudonyme,
          montant: this.state.montant,
          objet: this.state.objet,
          moyen: this.state.moyen,
        })
            .then((res) =>{
              // console.log(res.data)
              if (res.data === "ok"){
                console.log('ok');
                this.openNotification()
              }
              else{
                this.openNotificationError()
              }
            })
            .catch(r=>{
              this.openNotificationError()
            })

    }

    }

  onChange_Pseudo(value) {
   this.setState({Pseudonyme : value})
  }
  onChange_moyen(value) {
   this.setState({moyen : value})
  }


  render() {
    return (
      <>
        <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("../assets/img/brand/argon-react.png"),
              imgAlt: "..."
            }}
        />
        <div className="main-content" ref="mainContent">
          {/*<AdminNavbar
              {...this.props}
              brandText = {this.getBrandText(this.props.location.pathname)}
          />*/}
          <AdminNavbar
              {...this.props}
              brandText = {this.getBrandText(this.props.location.pathname)}
          />
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" >
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Ajouter un paiement</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nom d'utilisateur
                            </label>
                            <Select
                                showSearch
                                style={{width: "100%"}}
                                className="form-control-alternative form-control"
                                placeholder="sélectionner"
                                optionFilterProp="children"
                                onChange={this.onChange_Pseudo}
                                loading={this.state.loading}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                              {this.state.pseudo.map(r => (
                                  <Option value={r.Pseudonyme}>{r.Pseudonyme}</Option>
                              ))}

                            </Select>
                            {this.state.hasError_Pseudonyme &&
                            <span className="help-block text-red">Choisissez un nom d'utilisateur.</span>}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Objet / motif
                            </label>
                            <Input
                              className={"form-control-alternative"}
                              name="objet"
                              Value={this.state.objet}
                              placeholder="Objet / motif"
                              type="text"
                              onChange={this.onchange}
                              required={true}
                            />
                            {this.state.hasError_objet &&
                            <span className="help-block text-red">Entrez un Objet / motif.</span>}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Crédits
                            </label>
                            <Input
                              className="form-control-alternative"
                              Value={this.state.montant}
                              placeholder="Nombre de tirages"
                              name="montant"
                              type="number"
                              onChange={this.onchange}
                              required={true}
                            />
                            {this.state.hasError_montant &&
                            <span className="help-block text-red">Saisissez le nombre de crédit.</span>}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Moyen de paiement
                            </label>
                            <Select
                              defaultValue={this.state.moyen}
                              onChange={this.onChange_moyen}
                              style={{width: "100%"}}
                              className="form-control-alternative form-control"
                            >
                              <Option value={"ABO-VB"}>Virement bancaire</Option>
                              <Option value={"ABO-MC"}>Mandat cash</Option>
                            </Select>
                          </FormGroup>
                        </Col>

                      </Row>

                    </div>

                    <Row className="pl-lg-4">
                      <Button
                          color="info"
                          onClick={this.addAdmin.bind(this)}
                      >
                        Enregistrer
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}



export default Add_VB_MC;
