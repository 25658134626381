import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col, Alert, Modal
} from "reactstrap";
import axios from "axios";

// core components
import UserHeader from "../components/Headers/UserHeader.js";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import {IsLoggedIn} from "../Helpers";
const UserData = IsLoggedIn()[1];


class Profile extends React.Component {

  constructor() {
    super()
    this.state = {
      user:[],
      first_name: UserData.first_name,
      last_name: UserData.last_name,
      email: '',
      password: '',
      confirm_password: '',
      hasError_password:'',
      Pseudonyme: UserData.Pseudonyme,
      Num_telephone :'',
      notif_visible : false,
      notif_password_change_visible : false,
      Role:'',
      notificationModal: false,
      oldPassword : '',
      oldPassword1 : '',
      oldPassword1Error : ''
    }
    this.onchange = this.onchange.bind(this)
  }

  componentDidMount() {
    fetch('api/users/login')
        .then(res => {
          console.log(res);
          return res.json()
        })
        .then(user => {
          console.log(user);
          this.setState({
            user :user,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            Pseudonyme: user.Pseudonyme,
            Num_telephone: user.Num_telephone,
            Role:user.Role
          })
          if(user.Role === 1){
            this.setState({Role : 'Admin'})
          }else if(user.Role ===2){
            this.setState({Role : 'Super User'})
          }else {
            this.setState({Role : 'User'})
          }
        });

  }
  onDismiss = () => this.setState({notif_visible : false})
  Dismiss_pass_aler = () => this.setState({notif_password_change_visible : false})
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  onchange(e){
    e.preventDefault()
    const { name, value } = e.target;
    this.setState({[name]: value})

  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  updateProfile = (e) =>{
    console.log("inside update function ")
    if(this.state.oldPassword !== ''){
      this.setState({OldPasswordError : ""})
      axios.post("api/users/login/checkPassword",{
        password : this.state.oldPassword,
        id : UserData.id
      }).then((res)=>{
        //password correct
        if(res.status === 200){
           axios.put("api/users/login/" + UserData.id,{
        first_name:  this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        Num_telephone: this.state.Num_telephone,
      })
          .then((res) =>{
            // console.log(res.data)
            if (res.data === "ok"){
              console.log('ok');
              this.toggleModal("notificationModal")
              this.setState({notif_visible : true,oldPassword:''})
              document.getElementById('maj').scrollIntoView()

            }
          })

        }
        //password incorrect
        else {
          console.log('inco')
          this.setState({OldPasswordError : "Mot de passe incorrect"})
        }
      })
          .catch(()=>{
            console.log('inco')
            this.setState({OldPasswordError : "Mot de passe incorrect"})
          })
    }else {
      this.setState({OldPasswordError : "Veuillez renseigner ce champ"})
    }


    }


  updatePassword = (e) =>{
    console.log("inside update function for password")
    if(this.state.oldPassword1 !== ''){
      this.setState({
        oldPassword1Error : '',hasError_password:''
      })
      this.setState({OldPasswordError : ""})
      axios.post("api/users/login/checkPassword",{
        password : this.state.oldPassword1,
        id : UserData.id
      }).then((res)=>{
        if(res.status === 200){
          if(this.state.password !== '')
          {
            axios.post("api/users/login/updatePassword" + UserData.id,{
              password: this.state.password,
            })
                .then((res) =>{
                  // console.log(res.data)
                  if (res.data === "ok"){
                    console.log('ok');
                    this.setState({notif_password_change_visible : true})
                    document.getElementById('mdp').scrollIntoView()
                  }
                })
          }else {
            this.setState({hasError_password : "Mot de passe incorrect"})
          }
        }
        else {
          this.setState({oldPassword1Error : "Mot de passe incorrect"})
        }
      })
          .catch(()=>{
            this.setState({oldPassword1Error : "Mot de passe incorrect"})
          })

    }
    else {
      this.setState({
        oldPassword1Error : 'Veuillez entrer un mot de passe'
      })
    }

  }


  render() {
    return (
      <>
        <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("../assets/img/brand/argon-react.png"),
              imgAlt: "..."
            }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
              {...this.props}
              brandText = {this.getBrandText(this.props.location.pathname)}
          />
        <UserHeader pseudonyme = {UserData.Pseudonyme}/>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" >
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Mon compte</h3>
                    </Col>
                    <Col className="text-right" xs="4">

                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Alert color="success" id={'maj'} isOpen={this.state.notif_visible} toggle={this.onDismiss}>
                    Votre profil a été mis à jour avec succès !
                  </Alert>
                  <Alert color="success" id={'mdp'} isOpen={this.state.notif_password_change_visible} toggle={this.Dismiss_pass_aler}>
                    Votre mot de passe a été mis à jour avec succès !
                  </Alert>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Vos informations
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nom d'utilisateur
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="Pseudonyme"
                              Value={this.state.Pseudonyme}
                              id="input-username"
                              placeholder="Nom d'utilisateur"
                              type="text"
                              disabled ={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Adresse e-mail
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="email"
                              Value={this.state.email}
                              id="input-email"
                              placeholder="Adresse e-mail"
                              type="email"
                              onChange={this.onchange}
                              required={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Prénom
                            </label>
                            <Input
                              className="form-control-alternative"
                              Value={this.state.first_name}
                              id="input-first-name"
                              placeholder="Prénom"
                              type="text"
                              disabled={true}
                              required={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Nom de famille
                            </label>
                            <Input
                              className="form-control-alternative"
                              Value={this.state.last_name}
                              id="input-last-name"
                              placeholder="Nom de famille"
                              type="text"
                              disabled={true}
                              required={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                            >
                              Rôle
                            </label>
                            <Input
                                className="form-control-alternative"
                                Value={this.state.Role}
                                type="text"
                                disabled={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Numéro de téléphone
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="Num_telephone"
                              Value={this.state.Num_telephone}
                              id="input-phone"
                              placeholder="Numéro de téléphone"
                              type="text"
                              onChange={this.onchange}
                              required={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Button
                            color="info"
                            onClick={()=>this.toggleModal("notificationModal")
                            }
                        >
                          Sauvegarder les modifications
                        </Button>
                      </Row>

                    </div>

                    <hr className="my-4" />

                    {/* Mot de passe */}
                      <h6 className="heading-small text-muted mb-4">
                        Changez votre mot de passe
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                              >
                                Entrez l'ancien mot de passe
                              </label>
                              <Input
                                  className="form-control-alternative"
                                  name="oldPassword1"
                                  Value={this.state.oldPassword1}
                                  id="input-password"
                                  type="password"
                                  onChange={this.onchange}
                                  required={true}
                              />
                              <span className= "input-error">
                                {this.state.oldPassword1Error}</span>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                              >
                                Entrez le nouveau mot de passe
                              </label>
                              <Input
                                  className="form-control-alternative"
                                  name="password"
                                  Value={this.state.password}
                                  id="input-password"
                                  type="password"
                                  onChange={this.onchange}
                                  required={true}
                              />
                              <span className="input-error">
                                {this.state.hasError_password}</span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Button
                              color="info"
                              onClick={this.updatePassword.bind(this)}
                          >
                            Changer le mot de passe
                          </Button>
                        </Row>
                      </div>
                    {/*<hr className="my-4" />*/}
                    {/* Description */}
                    {/*<h6 className="heading-small text-muted mb-4">About me</h6>*/}
                    {/*<div className="pl-lg-4">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          className="form-control-alternative"
                          placeholder="A few words about you ..."
                          rows="4"
                          defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                          Open Source."
                          type="textarea"
                        />
                      </FormGroup>
                    </div>*/}

                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
          <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={this.state.notificationModal}
              toggle={() => this.toggleModal("notificationModal")}
          >
            <div className="modal-header">
              <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("notificationModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <h4 className="heading mt-4">VEUILLEZ CONFIRMER VOTRE MOT DE PASSE !</h4>
                <Input
                    className="form-control-alternative"
                    name="oldPassword"
                    Value={this.state.oldPassword}
                    id="input-password"
                    type="password"
                    onChange={this.onchange}
                />
                <span style={{marginTop:"10px"}}>
                  {this.state.OldPasswordError}
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <Button className="btn-white" color="default" type="button" disabled={this.state.loading}
                      onClick={() => this.updateProfile()}>
                {this.state.loading ? <div className="spinner-border" role="status">
                </div>: <>OK</>}

              </Button>
              <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("notificationModal")}
              >
                Annuler
              </Button>
            </div>
          </Modal>

          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Profile;
