import React from "react";
// reactstrap components
import {
    Card,
    CardFooter,
    CardHeader, Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, Select} from "antd";
import "./GlobalesCss.css";
import {TiArrowUnsorted} from "react-icons/ti";

import {ModalManager} from 'react-dynamic-modal';
import Infos_Modal from "../components/Joueurs_Detail_Modal";
import {Authorized, FormatDate, getYear_Week} from "../Helpers";

const { Option } = Select;
let year_week = getYear_Week(new Date());
const NumeroTirage = year_week[0]+"-"+year_week[1];

function Search_In_Table() {
  var input, filter, table, found, tr, td, i, j, txtValue;
  input = document.getElementById("input-cord-table");
  filter = input.value.toUpperCase();
  table = document.getElementById("cordonnee-table");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td");
    for (j = 0; j < td.length; j++) {
      if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
        found = true;
      }
    }
    if (found) {
      tr[i].style.display = "";
      found = false;
    } else {
      if (i>0) { //this skips the headers
        tr[i].style.display = "none";
      }
    }
  }

}

function sortTable(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("cordonnee-table");
  switching = true;
  //Set the sorting direction to ascending:
  dir = "asc";
  /*Make a loop that will continue until
  no switching has been done:*/
  while (switching) {
    //start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /*Loop through all table rows (except the
    first, which contains table headers):*/
    for (i = 1; i < (rows.length - 1); i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /*Get the two elements you want to compare,
      one from current row and one from the next:*/
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /*check if the two rows should switch place,
      based on the direction, asc or desc:*/
      if (dir == "asc") {
        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
          //if so, mark as a switch and break the loop:
          shouldSwitch= true;
          break;
        }
      } else if (dir == "desc") {
        if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
          //if so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      //Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /*If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again.*/
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}



class Paiements extends React.Component {
  constructor() {
    super()
    this.state = {
      paiements:[],
      idWeek:[],
      Modal_Detail: true,
        authPage:false,
        LectureMode : false,
    }
    this.openModal = this.openModal.bind(this);
    this.onChange_idWeek = this.onChange_idWeek.bind(this)
  }


  openModal(JoueurDetails){
    ModalManager.open(<Infos_Modal Joueur={JoueurDetails} onRequestClose={() => true}/>);
  }

  toggle = () => this.setState({Modal_Detail : !this.state.Modal_Detail});
  componentDidMount() {
      Authorized("Num").then(r=>{
          if (r.data.Role === 2){ // if role is super user see if access to this page is giver
              if (r.data.Access){
                  this.setState({authPage:true})
              }
              else{
                  window.location.replace("/");
              }
          }else if(r.data.Role === 3) {
              this.setState({LectureMode : true, authPage:true})
          }else
              this.setState({authPage:true})
      }).catch(
          this.setState({authPage:true})
      )

      axios.get('api/paiements/all/')
        .then(res=>{
          console.log("Tous les paiements : " , res.data);
            this.setState({
              paiements: Object.values(res.data),
            });
        })
        .catch(err =>{
          console.log(err.data)
        });

      axios.get('api/paiements/all-ids/')
        .then(res=>{
          console.log("Tous les ids: " , res.data);
            this.setState({
              idWeek: res.data,
            });
        })
        .catch(err =>{
          console.log(err.data)
        })
  }

  onChange_idWeek(value) {
      axios.get('api/paiements/all/'+value)
          .then(res=>{
              console.log("Tous les votes : " , res.data);
              this.setState({
                  paiements: [],
              });
              this.setState({
                  paiements: Object.values(res.data),
              });
          })
          .catch(err =>{
              console.log(err.data)
          });
  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };


  render() {
    return (
      <>
          {!this.state.authPage ?
              <div className="spinner-pages spinner-border text-primary " role="status">
                  <span className="sr-only">Loading...</span>
              </div> :
              <>
                  <Sidebar
                      {...this.props}
                      routes={routes}
                      logo={{
                          innerLink: "/admin/index",
                          imgSrc: require("assets/img/brand/argon-react.png"),
                          imgAlt: "..."
                      }}
                  />
                  <div className="main-content" ref="mainContent">
                      <AdminNavbar
                          {...this.props}
                          brandText={this.getBrandText(this.props.location.pathname)}
                      />

                      <Header/>
                      {/* Page content */}
                      <Container className="mt--7" fluid>
                          {/* Table */}
                          <Row>
                              <div className="col">
                                  <Card className="shadow">

                                      <CardHeader className="border-0">
                                          <h2 className="mb-0" style={{textAlign: "center"}}>
                                              Paiements par carte</h2>
                                      </CardHeader>
                                      <Row>
                                          <Col md={7} className="ml-5">
                                              <span>Rechercher : </span>
                                              <Input type={"text"} onKeyUp={Search_In_Table} placeholder={"Rechercher"}
                                                     id="input-cord-table"/>
                                          </Col>
                                          <Col>
                                              <span>Numéro de tirage : </span>
                                              <Select
                                                  showSearch
                                                  style={{width: 200, marginLeft: 20}}
                                                  placeholder="sélectionner"
                                                  optionFilterProp="children"
                                                  onChange={this.onChange_idWeek}
                                                  filterOption={(input, option) =>
                                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                  }
                                                  filterSort={(optionA, optionB) =>
                                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                  }
                                              >
                                                  {this.state.idWeek.map(r => (
                                                      <Option value={r.NumeroWeek}>{r.NumeroWeek}</Option>
                                                  ))}

                                              </Select>
                                          </Col>
                                      </Row>

                                      <Table className="align-items-center table-dark table-flush" responsive
                                             id={"cordonnee-table"}>
                                          <thead className="thead-dark">
                                          <tr>
                                              <th className="th-sort" scope="col" onClick={() => sortTable(0)}>
                                                  Pseudonyme
                                                  <TiArrowUnsorted/></th>
                                              <th className="th-sort" scope="col" onClick={() => sortTable(1)}>Numéro de
                                                  semaine
                                                  <TiArrowUnsorted/></th>
                                              <th className="th-sort" scope="col" onClick={() => sortTable(2)}>Date de
                                                  paiement
                                                  <TiArrowUnsorted/></th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.paiements.map((row) => (
                                              <tr key={row.Pseudonyme}>
                                                  <td>{row.Pseudonyme}</td>
                                                  <td>{row.NumeroTirage}</td>
                                                  <td>{FormatDate(row.PaymentDate)}</td>
                                              </tr>
                                          ))}

                                          </tbody>
                                      </Table>
                                      <CardFooter className="py-4">
                                          <nav aria-label="...">
                                              <Pagination
                                                  className="pagination justify-content-end mb-0"
                                                  listClassName="justify-content-end mb-0"
                                              >
                                                  <PaginationItem className="disabled">
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                          tabIndex="-1"
                                                      >
                                                          <i className="fas fa-angle-left"/>
                                                          <span className="sr-only">Previous</span>
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem className="active">
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          1
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem>
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          2 <span className="sr-only">(current)</span>
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem>
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          3
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem>
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          <i className="fas fa-angle-right"/>
                                                          <span className="sr-only">Next</span>
                                                      </PaginationLink>
                                                  </PaginationItem>
                                              </Pagination>
                                          </nav>
                                      </CardFooter>
                                  </Card>
                              </div>
                          </Row>


                      </Container>

                      <Container fluid>
                          <AdminFooter/>
                      </Container>
                  </div>
              </>
          }
      </>
    );
  }
}

export default Paiements;
