import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import Utilisateurs from "./views/Utilisateurs";
import Admin_Profil from "./views/Profile";
import { PrivateRoute } from "./Services/authService";
import DetailsJoueurs from "./views/DetailsJoueurs";
import TiragesAdmin from "./views/tiragesAdmin";
import Do_Draw from "./views/Do_Draw";
import Pages from "./views/Pages";
import ArchivedJoueurs from "./views/ArchivedJoueurs";
import Votes from "./views/Votes";
import NumerosTirages from "./views/NumerosTirage";
import Parrainages from "./views/Parrainages";
import Paiements from "./views/Paiements";
import Pages_2 from "./views/Pages_2";
import Gagnants from "./views/Gagnants";
import Credits from "./views/Credits";
import conditions_jeu from "./views/Pages_Conditions_de_jeu ";
import le_jeu from "./views/Le_jeu";
import securite from "./views/Pages_securite";
import le_tirage from "./views/Pages_le_tirage";
import les_gains from "./views/Pages_les_gains";
import Admins from "./views/Admins";
import AddAdmin from "./views/AddAdmin";
import EditAdmin from "./views/EditAdmin";
import VB_MC from "./views/VB_MC";
import Add_VB_MC from "./views/Add_VB_MC";
import Admins_logs from "./views/Admins_logs";
import NewsLetter from "./views/NewsLetter";

import "antd/dist/antd.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

String.prototype.toDate = function (format) {
  var normalized = this.replace(/[^a-zA-Z0-9]/g, "-");
  var normalizedFormat = format.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-");
  var formatItems = normalizedFormat.split("-");
  var dateItems = normalized.split("-");

  var monthIndex = formatItems.indexOf("mm");
  var dayIndex = formatItems.indexOf("dd");
  var yearIndex = formatItems.indexOf("yyyy");
  var hourIndex = formatItems.indexOf("hh");
  var minutesIndex = formatItems.indexOf("ii");

  var today = new Date();

  var year = yearIndex > -1 ? dateItems[yearIndex] : today.getFullYear();
  var month =
    monthIndex > -1 ? dateItems[monthIndex] - 1 : today.getMonth() - 1;
  var day = dayIndex > -1 ? dateItems[dayIndex] : today.getDate();

  var hour = hourIndex > -1 ? dateItems[hourIndex] : today.getHours();
  var minute = minutesIndex > -1 ? dateItems[minutesIndex] : today.getMinutes();

  return new Date(year, month, day, hour, minute);
};

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <PrivateRoute exact path="/" component={AdminLayout} />

      <PrivateRoute exact path="/index" component={AdminLayout} />

      {/*-------------- Profile -------------------*/}

      <PrivateRoute exact path="/ad-profile" component={Admin_Profil} />
      <PrivateRoute exact path="/admins" component={Admins} />
      <PrivateRoute exact path="/add-admin" component={AddAdmin} />
      <PrivateRoute exact path="/editAdmin/:idAdmin" component={EditAdmin} />
      <PrivateRoute exact path="/admin-logs" component={Admins_logs} />

      {/*  -----------------  Joueurs  -------------*/}
      <PrivateRoute exact path="/user" component={Utilisateurs} />
      <PrivateRoute exact path="/newsletter" component={NewsLetter} />
      <PrivateRoute exact path="/user-archived" component={ArchivedJoueurs} />
      <PrivateRoute exact path="/parrainages" component={Parrainages} />

      {/* ********* Votes ************/}
      <PrivateRoute exact path="/votes" component={Votes} />

      {/* ********* Paiements ************/}
      <PrivateRoute exact path="/paiements" component={Paiements} />
      <PrivateRoute exact path="/credits" component={Credits} />
      <PrivateRoute exact path="/vb-mc" component={VB_MC} />
      <PrivateRoute exact path="/add-vb-mc" component={Add_VB_MC} />

      {/* ********* Numéros de tirages ************/}
      <PrivateRoute exact path="/tirage-number" component={NumerosTirages} />

      <PrivateRoute exact path="/dates-tirage" component={TiragesAdmin} />
      <PrivateRoute exact path="/do-draw" component={Do_Draw} />
      <PrivateRoute exact path="/winners" component={Gagnants} />
      <PrivateRoute exact path="/pages" component={Pages} />
      <PrivateRoute exact path="/pages-2" component={Pages_2} />
      <PrivateRoute
        exact
        path="/faq-conditions-jeu"
        component={conditions_jeu}
      />
      <PrivateRoute exact path="/faq-le-jeu" component={le_jeu} />
      <PrivateRoute exact path="/faq-securite" component={securite} />
      <PrivateRoute exact path="/faq-le-tirage" component={le_tirage} />
      <PrivateRoute exact path="/faq-le-gain" component={les_gains} />

      <PrivateRoute exact path="/user/:JoueurId" component={DetailsJoueurs} />
      {/*<Route path="/admin" render={props => <AdminLayout {...props} />} />*/}
      <Route path="/auth" component={AuthLayout} />
      {/*<Redirect from="/" to="/auth" />*/}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
