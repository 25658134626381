import React from "react";
import {Effect, Modal, ModalManager} from "react-dynamic-modal";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {FormatDate, getStatutColor, getStatutWord} from "../Helpers";
import {Badge, Button} from "reactstrap";

class Infos_Modal extends React.Component{

    render(){
        const { Joueur,onRequestClose } = this.props;
        return (
            <Modal
                onRequestClose={onRequestClose}
                effect={Effect.Fall} style={{content: { margin:'10% auto', padding: "2.5rem"}}}>
                <Row style={{marginBottom : "25px"}}>
                    <h2>Informations du joueur :</h2>
                </Row>
                <Row style={{marginBottom : "15px"}}>
                    <Col md={3}><b>Identifiant  : </b></Col>
                    <Col md={2}>{Joueur.id}</Col>
                </Row>

                <Row style={{marginBottom : "15px"}}>
                    <Col md={3}><b>Nom  : </b></Col>
                    <Col md={2}>{Joueur.first_name}</Col>
                    <Col md={2}/>
                    <Col md={3}><b>Prénom  : </b></Col>
                    <Col md={2}>{Joueur.last_name}</Col>
                </Row>

                <Row style={{marginBottom : "15px"}}>
                    <Col md={3}><b>Nom marital : </b></Col>
                    <Col md={2}>{Joueur.nom_marital}</Col>
                    <Col md={2}/>
                    <Col md={3}><b>Genre : </b></Col>
                    <Col md={2}>{Joueur.gender}</Col>
                </Row>


                <Row style={{marginBottom : "15px"}}>
                    <Col md={3}><b>E-mail  : </b></Col>
                    <Col md={2}>{Joueur.email}</Col>
                </Row>

                <Row style={{marginBottom : "15px"}}>
                    <Col md={3}><b>Date de naissance  : </b></Col>
                    <Col md={2}>{Joueur.Date_Naissance}</Col>
                    <Col md={2}/>
                    <Col md={3}><b>Ville de naissance  : </b></Col>
                    <Col md={2}>{Joueur.Ville_Residence}</Col>
                </Row>

                <Row style={{marginBottom : "15px"}}>
                    <Col md={3}><b>Pays de résidence : </b></Col>
                    <Col md={2}>{Joueur.Pays_Residence}</Col>
                    <Col md={2}/>
                    <Col md={3}><b>Ville de résidence : </b></Col>
                    <Col md={2}>{Joueur.Ville_Residence}</Col>
                </Row>

                <Row style={{marginBottom : "15px"}}>
                    <Col md={3}><b>Pseudonyme : </b></Col>
                    <Col md={2}>{Joueur.Pseudonyme}</Col>
                    <Col md={2}/>
                    <Col md={3}><b>Numéro de téléphone : </b></Col>
                    <Col md={2}>{Joueur.Num_telephone}</Col>
                </Row>

                <Row style={{marginBottom : "15px"}}>
                    <Col md={3}><b>Date de création du compte: </b></Col>
                    <Col md={2}>{FormatDate(Joueur.created)}</Col>
                    <Col md={2}/>
                    <Col md={3}><b>Statut du compte : </b></Col>
                    <Col md={2}>
                        <Badge color="" className="badge-dot mr-4">
                            {Joueur.validation_piece ==="true" ? <><i className={"bg-success"} />
                                Compte validé</> :
                                <><i className={"bg-warning"} />
                                    Compte non validé</>
                            }
                    </Badge>
                    </Col>
                </Row>

                <Button color="secondary" type="button" onClick={ModalManager.close}
                        style={{float : "right", marginTop : "20px"}}>
                    Fermer
                </Button>

            </Modal>
        );
    }
}

export default Infos_Modal;
