import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";
import {login} from "../../Services/authService";
import { message } from 'antd';


const Login = () =>{

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);

  //------------------------- LOGIN ------------------------------------------
  let onSubmit = () => {
    const user = {
      email: email,
      password: password
    };

    login(user).then(res => {
      console.log("response from login : "+res);


      if (res[0] === "success") {
        setErrorMsg(false)
        window.location.href="/"
      }
      else {
        setErrorMsg(true)
      }
    })
  }

  return( <>
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent pb-5">
          <div className="text-muted text-center mt-2 mb-3">
            <h2>Authentification</h2>
          </div>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">

          <Form role="form">
            {errorMsg && <div className="alert alert-danger">
              Email ou mot de passe invalide.</div>}
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Utilisateur" type="text" onChange={e => setEmail(e.target.value)}/>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Mot de passe" type="password" onChange={e => setPassword(e.target.value)}/>
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button className="my-4" color="primary" type="button" onClick={()=> onSubmit()}>
                Se connecter
              </Button>


            </div>
          </Form>
        </CardBody>
      </Card>

    </Col>
  </>)
}


export default Login;
