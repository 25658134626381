import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  CustomInput,
  Container,
  Row,
  Col, Alert
} from "reactstrap";
import axios from "axios";
import UserHeader from "../components/Headers/UserHeader.js";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import {Authorized, IsLoggedIn} from "../Helpers";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import DetailsJoueursHeader from "../components/Headers/DetailsJoueursHeader";
import Radio from '@material-ui/core/Radio';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {notification} from "antd";



const UserData = IsLoggedIn()[1];


class DetailsJoueurs extends React.Component {

  constructor() {
    super()
    this.state = {
      id : '',
      first_name: '',
      last_name: '',
      nom_marital: '',
      email: '',
      password: '',
      confirm_password: '',
      hasError_password_confirm:false,
      Pseudonyme: '',
      Date_Naissance : '',
      Num_telephone :'',
      notif_visible : false,
      notif_password_change_visible : false,
      validation : '',
      disable_save_button : false,
      authPage:false,
      LectureMode : false
    }
    this.onchange = this.onchange.bind(this)
    this.Validation = this.Validation.bind(this)

  }


  openNotification = () => {
    notification['success']({
      message: 'Succès',
      description:
          'Les informations sont mis à jour avec succès !',
    });
  };

  openNotificationError = () => {
    notification['error']({
      message: 'Erreur',
      description:
          'Une erreur s\'est produite veuillez réessayer.',
    });
  };

  async componentDidMount() {
    Authorized("J").then(r=>{
      if (r.data.Role === 2){ // if role is super user see if access to this page is giver
        if (r.data.Access){
          this.setState({authPage:true})
        }
        else{
          window.location.replace("/");
        }
      }else if(r.data.Role === 3) {
        window.location.replace("/");
      }else
        this.setState({authPage:true})
    })

    let Id_Joueur = this.props.match.params.JoueurId
    this.setState({id : Id_Joueur})
    await axios
        .get('/api/players/single/'+Id_Joueur)
        .then(res => {
          console.log("joueur " +res.data.first_name);
          this.setState({
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            nom_marital: res.data.nom_marital,
            email: res.data.email,
            Pseudonyme: res.data.Pseudonyme,
            Date_Naissance : res.data.Date_Naissance,
            Ville_Naissance : res.data.Ville_Naissance,
            Num_telephone : res.data.Num_telephone,
            validation : res.data.validation_piece.toString(),
          })
        });
  }


  async updateProfile(e){
    this.setState({save_button : true})
    console.log("inside update function ")
    await axios.put("/api/players/" + this.state.id,{
      first_name:  this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      Pseudonyme: this.state.Pseudonyme,
      nom_marital: this.state.nom_marital,
      Num_telephone: this.state.Num_telephone,
      Date_Naissance : this.state.Date_Naissance,
      Ville_Naissance : this.state.Ville_Naissance,
      validation_piece : this.state.validation
    })
        .then((res) =>{
          this.setState({save_button : false})
          console.log('ok');
          this.openNotification()
        })
        .catch(err =>{
          console.log("erreur : " + err)
          this.setState({save_button : false})
          this.openNotificationError()
        })
  }


  onDismiss = () => this.setState({notif_visible : false})
  Dismiss_pass_aler = () => this.setState({notif_password_change_visible : false})
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  onchange(e){
    e.preventDefault()
    const { name, value } = e.target;
    this.setState({[name]: value})

  }




  updatePassword = (e) =>{
    console.log("inside update function for password")
    if(this.state.password === this.state.confirm_password){

      this.setState({
        hasError_password_confirm : false
      })

      axios.post("api/users/login/updatePassword" + UserData.id,{
        password: this.state.password,
      })
          .then((res) =>{
            // console.log(res.data)
            if (res.data === "ok"){
              console.log('ok');
              this.setState({notif_password_change_visible : true})
            }
          })
    }
    else {
      this.setState({
        hasError_password_confirm : true
      })
    }

  }

  Validation(e){
    console.log(e.target.value)
    this.setState({validation : e.target.value})
  }

  render() {

    return (
      <>
        {!this.state.authPage ?
            <div className="spinner-pages spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
            </div> :
            <>
              <Sidebar
                  {...this.props}
                  routes={routes}
                  logo={{
                    innerLink: "/admin/index",
                    imgSrc: require("../assets/img/brand/argon-react.png"),
                    imgAlt: "..."
                  }}
              />
              <div className="main-content" ref="mainContent">
                <AdminNavbar
                    {...this.props}
                    brandText={this.getBrandText(this.props.location.pathname)}
                />
                <DetailsJoueursHeader/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                  <Row>
                    <Col className="order-xl-1">
                      <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                          <Row className="align-items-center">
                            <Col xs="8">
                              <h3 className="mb-0">Id joueur : {this.state.id}</h3>
                            </Col>

                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Alert color="success" isOpen={this.state.notif_visible} toggle={this.onDismiss}>
                            Les informations ont été mis à jour avec succès !
                          </Alert>
                          <Alert color="success" isOpen={this.state.notif_password_change_visible}
                                 toggle={this.Dismiss_pass_aler}>
                            Votre mot de passe a été mis à jour avec succès !
                          </Alert>
                          <Form>
                            <h6 className="heading-small text-muted mb-4">
                              Informations
                            </h6>
                            <div className="pl-lg-4">
                              <Row>
                                <Col lg="3">
                                  <label className="form-control-label">Validation de la pièce d'identité :</label>
                                </Col>
                                <Col>
                                  <RadioGroup aria-label="gender" name="gender1"
                                              value={this.state.validation} onChange={this.Validation}>
                                    <FormControlLabel value="true" control={<Radio/>} label="Validée"/>
                                    <FormControlLabel value="false" control={<Radio/>} label="Non validée"/>
                                  </RadioGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                    >
                                      Prénom
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="first_name"
                                        value={this.state.first_name}
                                        id="input-first-name"
                                        placeholder="Prénom"
                                        type="text"
                                        onChange={this.onchange}
                                        required={true}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-last-name"
                                    >
                                      Nom de famille
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="last_name"
                                        Value={this.state.last_name}
                                        id="input-last-name"
                                        placeholder="Nom de famille"
                                        type="text"
                                        onChange={this.onchange}
                                        required={true}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >
                                      Nom marital
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="nom_marital"
                                        Value={this.state.nom_marital}
                                        id="input-username"
                                        placeholder="Nom marital"
                                        type="text"
                                        onChange={this.onchange}
                                        required={true}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                    >
                                      Adresse e-mail
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="email"
                                        Value={this.state.email}
                                        id="input-email"
                                        placeholder="Adresse e-mail"
                                        type="email"
                                        onChange={this.onchange}
                                        required={true}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >
                                      Date de naissance
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="Date_Naissance"
                                        Value={this.state.Date_Naissance}
                                        id="input-username"
                                        placeholder="Date de naissance"
                                        type="date"
                                        onChange={this.onchange}
                                        required={true}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                    >
                                      Ville de naissance
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="Ville_Naissance"
                                        Value={this.state.Ville_Naissance}
                                        id="input-email"
                                        placeholder="Ville de naissance"
                                        type="text"
                                        onChange={this.onchange}
                                        required={true}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                    >
                                      Numéro de téléphone
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="Num_telephone"
                                        Value={this.state.Num_telephone}
                                        id="input-phone"
                                        placeholder="Numéro de téléphone"
                                        type="text"
                                        onChange={this.onchange}
                                        required={true}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Button
                                    color="info"
                                    onClick={this.updateProfile.bind(this)}
                                    disabled={this.state.save_button}
                                >
                                  {this.state.save_button ? 'Sauvegarde en cours' : 'Sauvegarder les modifications'}
                                </Button>
                              </Row>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>

                <Container fluid>
                  <AdminFooter/>
                </Container>
              </div>
            </>
        }
      </>
    );
  }
}

export default DetailsJoueurs;
