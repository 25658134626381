import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col, Alert
} from "reactstrap";
import axios from "axios";

// core components
import UserHeader from "../components/Headers/UserHeader.js";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import AdminFooter from "../components/Footers/AdminFooter";
import {IsLoggedIn} from "../Helpers";
import {notification, Radio, Space, Switch} from "antd";
import AdminNavbar from "../components/Navbars/AdminNavbar";


class EditAdmin extends React.Component {

  constructor() {
    super()
    this.state = {
      user:[],
      loading: true,
      first_name: "",
      last_name: "",
      email: "",
      password: '',
      confirm_password: '',
      Role: 1,
      hasError_password_confirm:false,
      hasError_email:false,
      hasError_first_name:false,
      hasError_last_name:false,
      hasError_Num_telephone:false,
      hasError_Pseudonyme:false,
      hasError_password:false,
      Pseudonyme: "",
      Num_telephone :"",
      disable_save_button : false,


      accesJ : false,
      accesJA : false,
      accesPAR : false,
      accesV : false,
      accesNum : false,
      accesPC : false,
      accesVB_MC: false,
      accesC : false,
      accesLT : false,
      accesG : false,
      accesPA : false,
      accesPA2: false,
      accesFAQ_CDJ : false,
      accesFAQ_J : false,
      accesFAQ_S : false,
      accesFAQ_T : false,
      accesFAQ_G : false,
    }
    this.onchange = this.onchange.bind(this)
    this.addAdmin = this.addAdmin.bind(this)
    this.validateData = this.validateData.bind(this)
    this.onChangeAccesJ = this.onChangeAccesJ.bind(this)
    this.onChangeAccesJA = this.onChangeAccesJA.bind(this)
    this.onChangeParr = this.onChangeParr.bind(this)
    this.onChangeAccesVote = this.onChangeAccesVote.bind(this)
    this.onChangeAccesNumT = this.onChangeAccesNumT.bind(this)
    this.onChangeaccesPPcarte = this.onChangeaccesPPcarte.bind(this)
    this.onChangeaccesVB_MC = this.onChangeaccesVB_MC.bind(this)
    this.onChangeaccesCredit = this.onChangeaccesCredit.bind(this)

    this.onChangeaccesLT = this.onChangeaccesLT.bind(this)
    this.onChangeaccesGagnants = this.onChangeaccesGagnants.bind(this)
    this.onChangeaccesPages = this.onChangeaccesPages.bind(this)
    this.onChangeaccesPages2 = this.onChangeaccesPages2.bind(this)
    this.onChangeaccesFAQ_CDJ = this.onChangeaccesFAQ_CDJ.bind(this)
    this.onChangeaccesFAQ_Jeu = this.onChangeaccesFAQ_Jeu.bind(this)
    this.onChangeaccesFAQ_Tirage = this.onChangeaccesFAQ_Tirage.bind(this)
    this.onChangeaccesFAQ_Gain = this.onChangeaccesFAQ_Gain.bind(this)
    this.onChangeaccesFAQ_Secu = this.onChangeaccesFAQ_Secu.bind(this)
  }

  componentDidMount() {
    let Id_Admin = this.props.match.params.idAdmin
    if(IsLoggedIn()[0] && IsLoggedIn()[1].Role === 1){
      console.log("auth")
    }
    else
      window.location.replace("/");
    axios.get('/api/admins/'+ Id_Admin)
        .then(res => {
          console.log(res.data);
          this.setState({
            user : res.data.admin,
            first_name: res.data.admin.first_name,
            last_name: res.data.admin.last_name,
            email: res.data.admin.email,
            Pseudonyme: res.data.admin.Pseudonyme,
            Num_telephone: res.data.admin.Num_telephone,
            Role : res.data.admin.Role,

          })

          res.data.access.map((async item => {
            console.log(item.AccessLabel)
            await this.setState({['acces'+item.AccessLabel]: item.AccessStat})
          }))
          this.setState({loading : false})

        })
        .catch(()=>{
          this.setState({loading : false});
        })


  }

  openNotification = () => {
    notification['success']({
      message: 'Succès',
      description:
          "Les informations sont mis à jour avec succès !",
    });
  };

  openNotificationError = () => {
    notification['error']({
      message: 'Erreur',
      description:
          'Une erreur s\'est produite veuillez réessayer.',
    });
  };

  openNotificationErrorProfile = (msg) => {
    notification['error']({
      message: 'Erreur',
      description:
          msg,
    });
  };

  onchange(e){
    e.preventDefault()
    const { name, value } = e.target;
    this.setState({[name]: value})

  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  /* ******* notifications on change ****** */
  onChangeAccesJ(v){
    this.setState({accesJ : v})
    console.log(v)
  }

  onChangeAccesJA(v){
    this.setState({accesJA : v})
    console.log(v)

  }
  onChangeParr(v){
    this.setState({accesPAR : v})
    console.log(v)
  }

  onChangeAccesVote(v){
    this.setState({accesV : v})
    console.log(v)
  }
  onChangeAccesNumT(v){
    this.setState({accesNum: v})
    console.log(v)
  }
  onChangeaccesPPcarte(v){
    this.setState({accesPC : v})
    console.log(v)
  }
  onChangeaccesVB_MC(v){
    this.setState({accesVB_MC : v})
    console.log(v)
  }
  onChangeaccesCredit(v){
    this.setState({accesC: v})
    console.log(v)
  }
  onChangeaccesLT(v){
    this.setState({accesLT: v})
    console.log(v)
  }
  onChangeaccesGagnants(v){
    this.setState({accesG: v})
    console.log(v)
  }
  onChangeaccesPages(v){
    this.setState({accesPA: v})
    console.log(v)
  }

  onChangeaccesPages2(v){
    this.setState({accesPA2: v})
    console.log(v)
  }
  onChangeaccesFAQ_CDJ(v){
    this.setState({accesFAQ_CDJ: v})
    console.log(v)
  }
  onChangeaccesFAQ_Jeu(v){
    this.setState({accesFAQ_J: v})
    console.log(v)
  }
  onChangeaccesFAQ_Secu(v){
    this.setState({accesFAQ_S: v})
    console.log(v)
  }
  onChangeaccesFAQ_Tirage(v){
    this.setState({accesFAQ_T: v})
    console.log(v)
  }
  onChangeaccesFAQ_Gain(v){
    this.setState({accesFAQ_G: v})
    console.log(v)
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isPhoneValid(p) {
    var phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return phoneRe.test(p);
  }
  async validateData(){
    let NoER = true
    if(this.state.first_name.length<1){
      NoER = false
      this.setState({hasError_first_name : true})
    }
    else this.setState({hasError_first_name : false})


    if(this.state.last_name.length<1){
      NoER = false
      this.setState({hasError_last_name : true})
    }
    else this.setState({hasError_last_name : false})


    if(this.state.Pseudonyme.length<1){
      NoER = false
      this.setState({hasError_Pseudonyme : true})
    }else
      this.setState({hasError_Pseudonyme : false})

    if(await this.isPhoneValid(this.state.Num_telephone))
      this.setState({hasError_Num_telephone : false})
    else
    {
      NoER = false
      this.setState({hasError_Num_telephone : true})
    }

    if(await this.validateEmail(this.state.email))
    this.setState({hasError_email : false})
    else{
      NoER = false
      this.setState({hasError_email : true})
    }
    return NoER
  }

  addAdmin = async () =>{

    if(await this.validateData()){
      this.setState({disable_save_button : true})
      if(this.state.Role === 2){
        let AccesConfig = {
          accesJ : this.state.accesJ,
          accesJA : this.state.accesJA,
          accesPAR : this.state.accesPAR,
          accesV : this.state.accesV,
          accesNum : this.state.accesNum,
          accesPC : this.state.accesPC,
          accesVB_MC: this.state.accesVB_MC,
          accesC : this.state.accesC,
          accesLT : this.state.accesLT,
          accesG : this.state.accesG,
          accesPA : this.state.accesPA,
          accesPA2: this.state.accesPA2,
          accesFAQ_CDJ : this.state.accesFAQ_CDJ,
          accesFAQ_J : this.state.accesFAQ_J,
          accesFAQ_S : this.state.accesFAQ_S,
          accesFAQ_T : this.state.accesFAQ_T,
          accesFAQ_G : this.state.accesFAQ_G,
        }
        axios.put("/api/admins/",{
          id : this.state.user.id,
          first_name:  this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          Pseudonyme: this.state.Pseudonyme,
          Role: this.state.Role,
          Num_telephone: this.state.Num_telephone,
          AccesConfig : AccesConfig
        })
            .then((res) =>{
              this.setState({disable_save_button : false})

              // console.log(res.data)
              if (res.data === "ok"){
                console.log('ok');
                this.openNotification()
              }
              else{
                this.openNotificationError()
              }
            })
            .catch(r=>{
              this.setState({disable_save_button : false})
              this.openNotificationError()
            })
      }
      else{
        axios.put("/api/admins/",{
          id : this.state.user.id,
          first_name:  this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          Pseudonyme: this.state.Pseudonyme,
          Role: this.state.Role,
          Num_telephone: this.state.Num_telephone,
        })
            .then((res) =>{
              this.setState({disable_save_button : false})

              // console.log(res.data)
              if (res.data === "ok"){
                console.log('ok');
                this.openNotification()
              }
              else{
                this.openNotificationError()
              }
            })
            .catch(r=>{
              this.setState({disable_save_button : false})

              this.openNotificationError()
            })
      }
    }
    }

  openCustomNotification_Success = (msg) => {
    notification['success']({
      message: 'Succès',
      description:
      msg,
    });
  };

  updatePassword = (e) =>{
    if(this.state.password.length<8){
      this.setState({hasError_password : true})
    }
    else{
      this.setState({hasError_password : false})
      if(this.state.password === this.state.confirm_password){

        this.setState({
          hasError_password_confirm: false
        })

        axios.post("/api/admins/updatePassword/" + this.state.user.id,{
          password: this.state.password,
        })
            .then(() =>{
              this.openCustomNotification_Success("Le mot de passe a été modifié avec succès")
            })
            .catch(()=>this.openNotificationError)
      }
      else {
        this.setState({
          hasError_password_confirm : true
        })
      }
    }
  }


  render() {
    return (
      <>
        <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("../assets/img/brand/argon-react.png"),
              imgAlt: "..."
            }}
        />
        <div className="main-content" ref="mainContent">
          {/*<AdminNavbar
              {...this.props}
              brandText = {this.getBrandText(this.props.location.pathname)}
          />*/}
          <AdminNavbar
              {...this.props}
              brandText = {this.getBrandText(this.props.location.pathname)}
          />
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" >
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Modifier un compte</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.loading ?
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div> :
                      <Form>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                  Nom d'utilisateur
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    name="Pseudonyme"
                                    Value={this.state.Pseudonyme}
                                    id="input-username"
                                    placeholder="Nom d'utilisateur"
                                    type="text"
                                    required={true}
                                    disabled={true}
                                />
                                {this.state.hasError_Pseudonyme &&
                                <span className="help-block text-red">Saisissez un nom d'utilisateur.</span>}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-email"
                                >
                                  Adresse e-mail
                                </label>
                                <Input
                                    className={"form-control-alternative"}
                                    name="email"
                                    Value={this.state.email}
                                    id="input-email"
                                    placeholder="Adresse e-mail"
                                    type="email"
                                    onChange={this.onchange}
                                    required={true}
                                />
                                {this.state.hasError_email &&
                                <span className="help-block text-red">Entrez une adresse e-mail valide.</span>}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                >
                                  Prénom
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    Value={this.state.first_name}
                                    id="input-first-name"
                                    placeholder="Prénom"
                                    name="first_name"
                                    type="text"
                                    onChange={this.onchange}
                                    required={true}
                                />
                                {this.state.hasError_first_name &&
                                <span className="help-block text-red">Saisissez votre prénom.</span>}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-last-name">
                                  Nom de famille
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    Value={this.state.last_name}
                                    id="input-last-name"
                                    placeholder="Nom de famille"
                                    type="text"
                                    name="last_name"
                                    onChange={this.onchange}
                                />
                                {this.state.hasError_last_name &&
                                <span className="help-block text-red">Saisissez votre nom.</span>}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                >
                                  Numéro de téléphone
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    name="Num_telephone"
                                    Value={this.state.Num_telephone}
                                    id="input-phone"
                                    placeholder="Numéro de téléphone"
                                    type="text"
                                    onChange={this.onchange}
                                    required={true}
                                />
                                {this.state.hasError_Num_telephone &&
                                <span className="help-block text-red">Numéro de téléphone invalide.</span>}
                              </FormGroup>
                            </Col>

                          </Row>

                        </div>



                        <div className="pl-lg-4 pb-lg-5">


                        </div>
                        <hr className="my-4"/>
                        <Row className="pl-lg-4 pb-lg-5">
                          <Col>
                            <h6 className="heading-small text-muted mb-4">
                              Choisissez un rôle
                            </h6>
                            <Radio.Group onChange={this.onchange} value={this.state.Role} name={"Role"}>
                              <Space direction="vertical">
                                <Radio value={1}><label className="form-control-label">Administrateur</label></Radio>
                                <Radio value={2}><label className="form-control-label">Super user</label></Radio>
                                <Radio value={3}><label className="form-control-label">User</label></Radio>
                              </Space>
                            </Radio.Group>
                          </Col>
                        </Row>
                        {this.state.Role === 2 &&
                        <>
                          <hr className="my-4"/>
                          <Row className="pl-lg-4">
                            <h6 className="heading-small text-muted mb-4">
                              Configurer les accés pour super User :
                            </h6>
                          </Row>
                          <Row className="pl-lg-4 pb-lg-5">
                            <Col lg="3">
                              <label className="form-control-label mr-3">Joueurs</label>
                              <Switch onChange={this.onChangeAccesJ} checked={this.state.accesJ}
                              />
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">Joueurs achivés</label>
                              <Switch onChange={this.onChangeAccesJA} checked={this.state.accesJA}
                              />
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">Parrainages</label>
                              <Switch onChange={this.onChangeParr} checked={this.state.accesPAR}
                              />
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">Joueurs Votes</label>
                              <Switch onChange={this.onChangeAccesVote}
                                      checked={this.state.accesV}
                              />
                            </Col>
                          </Row>
                          <Row className="pl-lg-4 pb-lg-5">
                            <Col lg="3">
                              <label className="form-control-label mr-3">Numéros tirages</label>
                              <Switch onChange={this.onChangeAccesNumT}
                                      checked={this.state.accesNum}
                              />
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">Paiements</label>
                              <Switch onChange={this.onChangeaccesPPcarte}
                                      checked={this.state.accesPC}
                              />
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">Crédits</label>
                              <Switch onChange={this.onChangeaccesCredit}
                                      checked={this.state.accesC}/>
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">Lancer le tirage</label>
                              <Switch onChange={this.onChangeaccesLT}
                                      checked={this.state.accesLT}
                              />
                            </Col>
                          </Row>
                          <Row className="pl-lg-4 pb-lg-5">
                            <Col lg="3">
                              <label className="form-control-label mr-3">Gagnants</label>
                              <Switch onChange={this.onChangeaccesGagnants}
                                      checked={this.state.accesG}/>
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">Pages</label>
                              <Switch onChange={this.onChangeaccesPages}
                                      checked={this.state.accesPA}
                              />
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">Pages (2)</label>
                              <Switch onChange={this.onChangeaccesPages2}
                                      checked={this.state.accesPA2}/>
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">FAQ's > Conditions de jeu</label>
                              <Switch onChange={this.onChangeaccesFAQ_CDJ}
                                      checked={this.state.accesFAQ_CDJ}
                              />
                            </Col>
                          </Row>
                          <Row className="pl-lg-4 pb-lg-5">
                            <Col lg="3">
                              <label className="form-control-label mr-3">FAQ's > Le jeu</label>
                              <Switch onChange={this.onChangeaccesFAQ_Jeu}
                                      checked={this.state.accesFAQ_J}/>
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">FAQ's > Sécurité</label>
                              <Switch onChange={this.onChangeaccesFAQ_Secu}
                                      checked={this.state.accesFAQ_S}
                              />
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">FAQ's > Le tirage</label>
                              <Switch onChange={this.onChangeaccesFAQ_Tirage}
                                      checked={this.state.accesFAQ_T}/>
                            </Col>
                            <Col lg="3">
                              <label className="form-control-label mr-3">FAQ's > Les gains</label>
                              <Switch onChange={this.onChangeaccesFAQ_Gain}
                                      checked={this.state.accesFAQ_G}/>
                            </Col>
                          </Row>
                          {/*<Row className="pl-lg-4 pb-lg-5">
                            <Col lg="3">
                              <label className="form-control-label mr-3">Paiements par VB ou MC</label>
                              <Switch onChange={this.onChangeaccesVB_MC}
                                      checked={this.state.accesVB_MC}/>
                            </Col>
                          </Row>*/}
                        </>
                        }

                        <Row className="pl-lg-4 pb-lg-5">
                          <Button
                              color="info"
                              onClick={this.addAdmin.bind(this)}
                              disabled={this.state.disable_save_button}
                          >
                            {this.state.disable_save_button? 'Sauvegarde en cours' : 'Sauvegarder les modifications' }
                          </Button>
                        </Row>

                        {/* Mot de passe */}
                        <hr className="my-4 "/>
                        <Row className="pl-lg-4 ">
                          <Col lg="6">
                            <FormGroup>
                              <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                              >
                                Mot de passe
                              </label>
                              <Input
                                  className="form-control-alternative"
                                  name="password"
                                  Value={this.state.password}
                                  id="input-password"
                                  type="password"
                                  onChange={this.onchange}
                                  required={true}
                              />
                              {this.state.hasError_password &&
                              <span className="help-block text-red">Utilisez 8 caractères ou plus pour votre
                                mot de passe.</span>}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                              >
                                Ré-entrez le Mot de passe
                              </label>
                              <Input
                                  className="form-control-alternative"
                                  name="confirm_password"
                                  Value={this.state.confirm_password}
                                  id="input-confirm-password"
                                  type="password"
                                  onChange={this.onchange}
                                  required={true}
                              />
                              <span className="input-error">
                                {this.state.hasError_password_confirm &&
                                "Ces mots de passe ne correspondent pas. Veuillez réessayer."}</span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="pl-lg-4 pb-lg-5">
                        <Button
                            color="info" onClick={this.updatePassword.bind(this)}>
                          Changer le mot de passe
                        </Button>
                        </Row>
                      </Form>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}



export default EditAdmin;
