import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader, Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, Select} from "antd";
import "./GlobalesCss.css";
import {TiArrowUnsorted} from "react-icons/ti";

import {ModalManager} from 'react-dynamic-modal';
import Infos_Modal from "../components/Joueurs_Detail_Modal";
import {Authorized, convertDate, convertDateTime, FormatDate, getYear_Week} from "../Helpers";
import {Link} from "react-router-dom";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Option } = Select;
let year_week = getYear_Week(new Date());
const NumeroTirage = year_week[0]+"-"+year_week[1];

function Search_In_Table() {
  var input, filter, table, found, tr, td, i, j, txtValue;
  input = document.getElementById("input-cord-table");
  filter = input.value.toUpperCase();
  table = document.getElementById("cordonnee-table");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td");
    for (j = 0; j < td.length; j++) {
      if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
        found = true;
      }
    }
    if (found) {
      tr[i].style.display = "";
      found = false;
    } else {
      if (i>0) { //this skips the headers
        tr[i].style.display = "none";
      }
    }
  }

}

function sortTable(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("cordonnee-table");
  switching = true;
  //Set the sorting direction to ascending:
  dir = "asc";
  /*Make a loop that will continue until
  no switching has been done:*/
  while (switching) {
    //start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /*Loop through all table rows (except the
    first, which contains table headers):*/
    for (i = 1; i < (rows.length - 1); i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /*Get the two elements you want to compare,
      one from current row and one from the next:*/
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /*check if the two rows should switch place,
      based on the direction, asc or desc:*/
       if (n===3 || n===5){
            x= convertDateTime(x.innerHTML)
            y = convertDateTime(y.innerHTML)
            if (dir == "asc") {
                if (x > y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir == "desc") {
                if (x < y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        else {
            if (dir === "asc") {
                if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir === "desc") {
                if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
    }
    if (shouldSwitch) {
      /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      //Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /*If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again.*/
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}


class Votes extends React.Component {
  constructor() {
    super()
    this.state = {
      votes:[],
      idWeek:[],
      Modal_Detail: true,
        loading : true,

    }
    this.openModal = this.openModal.bind(this);
    this.onChange_idWeek = this.onChange_idWeek.bind(this)
  }


  openModal(JoueurDetails){
    ModalManager.open(<Infos_Modal Joueur={JoueurDetails} onRequestClose={() => true}/>);
  }

  toggle = () => this.setState({Modal_Detail : !this.state.Modal_Detail});
  componentDidMount() {
      Authorized("V").then(r=>{
          if (r.data.Role === 2){ // if role is super user see if access to this page is giver
              if (r.data.Access){
                  this.setState({authPage:true})
              }
              else{
                  window.location.replace("/");
              }
          }else if(r.data.Role === 3) {
              this.setState({LectureMode : true, authPage:true})
          }else
              this.setState({authPage:true})
      }).catch(
          this.setState({authPage:true})
      )
      axios.get('api/votes/all/')
        .then(res=>{
          console.log("Tous les votes : " , res.data);
            this.setState({
              votes: Object.values(res.data),loading : false
            });
        })
        .catch(err =>{
            this.setState({loading : false})
          console.log(err.data)
        });

      axios.get('api/numero/all-ids/')
        .then(res=>{
          console.log("Tous les ids: " , res.data);
            this.setState({
              idWeek: res.data,
            });
        })
        .catch(err =>{
          console.log(err.data)
        })
  }

  onChange_idWeek(value) {
      axios.get('api/votes/all/'+value)
          .then(res=>{
              console.log("Tous les votes : " , res.data);
              this.setState({
                  votes: [],
              });
              this.setState({
                  votes: Object.values(res.data),
              });
          })
          .catch(err =>{
              console.log(err.data)
          });
  }
    getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
            if (
                this.props.location.pathname.indexOf(
                    routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };


  render() {
    return (
      <>
          {!this.state.authPage ?
              <div className="spinner-pages spinner-border text-primary " role="status">
                  <span className="sr-only">Loading...</span>
              </div> :
              <>
                  <Sidebar
                      {...this.props}
                      routes={routes}
                      logo={{
                          innerLink: "/admin/index",
                          imgSrc: require("assets/img/brand/argon-react.png"),
                          imgAlt: "..."
                      }}
                  />
                  <div className="main-content" ref="mainContent">
                      <AdminNavbar
                          {...this.props}
                          brandText={this.getBrandText(this.props.location.pathname)}
                      />

                      <Header/>
                      {/* Page content */}
                      <Container className="mt--7" fluid>
                          {/* Table */}
                          <Row>
                              <div className="col">
                                  <Card className="shadow">

                                      <CardHeader className="border-0">
                                          <h2 className="mb-0" style={{textAlign: "center"}}>Votes</h2>
                                      </CardHeader>
                                      <Row>
                                          <Col md={4} className="ml-5">
                                              <span>Rechercher : </span>
                                              <Input type={"text"} onKeyUp={Search_In_Table} placeholder={"Rechercher"}
                                                     id="input-cord-table"/>
                                          </Col>
                                          <Col style={{marginTop : '5px'}}>
                                              <span>Numéro de tirage : </span>
                                              <Select
                                                  showSearch
                                                  style={{marginLeft: 20}}
                                                  placeholder="sélectionner"
                                                  optionFilterProp="children"
                                                  onChange={this.onChange_idWeek}
                                                  filterOption={(input, option) =>{}
                                                  }
                                                  filterSort={(optionA, optionB) =>{
                                                  }
                                                  }
                                              >
                                                  {this.state.idWeek.map(r => (
                                                      <Option value={r.NumeroWeek}>{r.NumeroWeek}</Option>
                                                  ))}

                                              </Select>
                                          </Col>
                                          {!this.state.LectureMode &&
                                          <>
                                              <Col>
                                                  <ExcelFile element={
                                                      <Button className="export-btn btn-primary">
                                                          Exporter</Button>
                                                  } filename={"Votes"}>
                                                      {this.state.votes===[] ? <ExcelSheet data={this.state.votes}
                                                                                             name="Votes">
                                                              <ExcelColumn label="" value=""/>
                                                          </ExcelSheet>
                                                          :
                                                          <ExcelSheet data={this.state.votes} name="Votes">
                                                              <ExcelColumn label="Pseudonyme" value="Pseudonyme"/>
                                                              <ExcelColumn label="Numéro de semaine" value="NumeroWeek"/>
                                                              <ExcelColumn label="Ticket généré" value="Number"/>
                                                              <ExcelColumn label="Date de création du ticket"
                                                                           value={(col) => FormatDate(col.created)}/>
                                                              <ExcelColumn label="Choix de vote" value={
                                                              (row)=>(row.vote !== null ? (row.Choix_vote+" gagnants"):
                                                              " ")}/>
                                                              <ExcelColumn label="Date de vote" value={
                                                                  (row)=>(row.vote !== null ? FormatDate(row.Date_Vote):
                                                                      " ")}/>
                                                          </ExcelSheet>
                                                      }
                                                  </ExcelFile>
                                              </Col>
                                          </>
                                          }
                                      </Row>
                                      {this.state.loading ?
                                          <div className="spinner-border text-primary" role="status">
                                              <span className="sr-only">Loading...</span>
                                          </div> :
                                          <Table className="align-items-center table-dark table-flush" responsive
                                                 id={"cordonnee-table"}>
                                              <thead className="thead-dark">
                                              <tr>
                                                  <th className="th-sort" scope="col" onClick={() => sortTable(0)}>
                                                      Pseudonyme<TiArrowUnsorted/></th>
                                                  <th className="th-sort" scope="col"
                                                      onClick={() => sortTable(1)}>Numéro de semaine
                                                      <TiArrowUnsorted/></th>
                                                  <th className="th-sort" scope="col"
                                                      onClick={() => sortTable(2)}>Ticket généré
                                                      <TiArrowUnsorted/></th>
                                                  <th className="th-sort" scope="col" onClick={() => sortTable(3)}>Date
                                                      de
                                                      création du ticket
                                                      <TiArrowUnsorted/></th>
                                                  <th className="th-sort" scope="col" onClick={() => sortTable(4)}>Choix
                                                      de vote
                                                      <TiArrowUnsorted/></th>
                                                  <th className="th-sort" scope="col" onClick={() => sortTable(5)}>Date
                                                      de
                                                      vote
                                                      <TiArrowUnsorted/></th>


                                              </tr>
                                              </thead>
                                              <tbody>
                                              {this.state.votes.map((row) => (
                                                  <tr key={row.Pseudonyme}>
                                                      <td>{row.Pseudonyme}</td>
                                                      <td>{row.NumeroWeek}</td>
                                                      <td>{row.Number}</td>
                                                      <td>{FormatDate(row.created)}</td>
                                                      {row.vote !== null ?
                                                          <>
                                                              <td>{row.Choix_vote} gagnants</td>
                                                              <td>{FormatDate(row.Date_Vote)}</td>
                                                          </>
                                                          :
                                                          <>
                                                              <td>----</td>
                                                              <td>----</td>
                                                          </>
                                                      }
                                                  </tr>
                                              ))}
                                              </tbody>
                                          </Table>
                                      }
                                      <CardFooter className="py-4">
                                          <nav aria-label="...">
                                              <Pagination
                                                  className="pagination justify-content-end mb-0"
                                                  listClassName="justify-content-end mb-0"
                                              >
                                                  <PaginationItem className="disabled">
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                          tabIndex="-1"
                                                      >
                                                          <i className="fas fa-angle-left"/>
                                                          <span className="sr-only">Previous</span>
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem className="active">
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          1
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem>
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          2 <span className="sr-only">(current)</span>
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem>
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          3
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem>
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          <i className="fas fa-angle-right"/>
                                                          <span className="sr-only">Next</span>
                                                      </PaginationLink>
                                                  </PaginationItem>
                                              </Pagination>
                                          </nav>
                                      </CardFooter>
                                  </Card>
                              </div>
                          </Row>
                          {/* Dark table */}
                          {/*<Row className="mt-5">
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-white mb-0">Moyens de paiement</h3>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Project</th>
                      <th scope="col">Budget</th>
                      <th scope="col">Status</th>
                      <th scope="col">Users</th>
                      <th scope="col">Completion</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/bootstrap.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Argon Design System
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$2,500 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          pending
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip731399078"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip731399078"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip491083084"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip491083084"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip528540780"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip528540780"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip237898869"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip237898869"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">60%</span>
                          <div>
                            <Progress
                              max="100"
                              value="60"
                              barClassName="bg-warning"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/angular.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Angular Now UI Kit PRO
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$1,800 USD</td>
                      <td>
                        <Badge color="" className="badge-dot">
                          <i className="bg-success" />
                          completed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip188614932"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip188614932"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip66535734"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip66535734"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip427561578"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip427561578"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip904098289"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip904098289"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">100%</span>
                          <div>
                            <Progress
                              max="100"
                              value="100"
                              barClassName="bg-success"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/sketch.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Black Dashboard
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$3,150 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-danger" />
                          delayed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip707904950"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip707904950"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip353988222"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip353988222"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip467171202"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip467171202"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip362118155"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip362118155"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">72%</span>
                          <div>
                            <Progress
                              max="100"
                              value="72"
                              barClassName="bg-danger"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/react.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              React Material Dashboard
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$4,400 USD</td>
                      <td>
                        <Badge color="" className="badge-dot">
                          <i className="bg-info" />
                          on schedule
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip226319315"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip226319315"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip711961370"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip711961370"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip216246707"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip216246707"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip638048561"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip638048561"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">90%</span>
                          <div>
                            <Progress
                              max="100"
                              value="90"
                              barClassName="bg-info"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/vue.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Vue Paper UI Kit PRO
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$2,200 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          completed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip781594051"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip781594051"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip840372212"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip840372212"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip497647175"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip497647175"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip951447946"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip951447946"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">100%</span>
                          <div>
                            <Progress
                              max="100"
                              value="100"
                              barClassName="bg-danger"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>*/}


                      </Container>

                      <Container fluid>
                          <AdminFooter/>
                      </Container>
                  </div>
              </>
          }
      </>
    );
  }
}

export default Votes;
