import React from "react";
// reactstrap components
import {
  Button,
  Card, CardBody,
  CardFooter,
  CardHeader,
  Container,
  Row,
} from "reactstrap";
import 'react-best-tabs/dist/index.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw,convertFromHTML,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './GlobalesCss.css'
import 'draft-js/dist/Draft.css';

// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input, notification} from "antd";
import "./GlobalesCss.css";

import {Authorized, getTimeRemaining, nextSaturday} from "../Helpers";
import { Tabs } from 'antd';


let deadline2 = nextSaturday();
deadline2.setHours(21,0,0,);

const { TabPane } = Tabs;

class le_jeu extends React.Component {
  constructor() {
    super()
    this.state = {
      authPage:false,
      LectureMode : false,
      editorState_66: EditorState.createEmpty(),
      editorState_77: EditorState.createEmpty(),
      editorState_78: EditorState.createEmpty(),
      editorState_79: EditorState.createEmpty(),
      editorState_80: EditorState.createEmpty(),
      editorState_81: EditorState.createEmpty(),
      editorState_82: EditorState.createEmpty(),
      editorState_83: EditorState.createEmpty(),
      editorState_84: EditorState.createEmpty(),
      editorState_86: EditorState.createEmpty(),
      editorState_87: EditorState.createEmpty(),
      editorState_90: EditorState.createEmpty(),
      editorState_91: EditorState.createEmpty(),
      editorState_92: EditorState.createEmpty(),
      editorState_93: EditorState.createEmpty(),
      editorState_94: EditorState.createEmpty(),
      editorState_95: EditorState.createEmpty(),
      editorState_96: EditorState.createEmpty(),
      editorState_97: EditorState.createEmpty(),
      editorState_98: EditorState.createEmpty(),
      editorState_99: EditorState.createEmpty(),
      editorState_100: EditorState.createEmpty(),
      editorState_101: EditorState.createEmpty(),
      editorState_102: EditorState.createEmpty(),
      editorState_103: EditorState.createEmpty(),
      editorState_104: EditorState.createEmpty(),
      editorState_146: EditorState.createEmpty(),
      editorState_147: EditorState.createEmpty(),
    }

    this.onEditorStateChange_66 = this.onEditorStateChange_66.bind(this);
    this.onEditorStateChange_77 = this.onEditorStateChange_77.bind(this);
    this.onEditorStateChange_78 = this.onEditorStateChange_78.bind(this);
    this.onEditorStateChange_79 = this.onEditorStateChange_79.bind(this);
    this.onEditorStateChange_80 = this.onEditorStateChange_80.bind(this);
    this.onEditorStateChange_81 = this.onEditorStateChange_81.bind(this);
    this.onEditorStateChange_82 = this.onEditorStateChange_82.bind(this);
    this.onEditorStateChange_83 = this.onEditorStateChange_83.bind(this);
    this.onEditorStateChange_84 = this.onEditorStateChange_84.bind(this);
    this.onEditorStateChange_86 = this.onEditorStateChange_86.bind(this);
    this.onEditorStateChange_87 = this.onEditorStateChange_87.bind(this);
    this.onEditorStateChange_90 = this.onEditorStateChange_90.bind(this);
    this.onEditorStateChange_91 = this.onEditorStateChange_91.bind(this);
    this.onEditorStateChange_92 = this.onEditorStateChange_92.bind(this);
    this.onEditorStateChange_93 = this.onEditorStateChange_93.bind(this);
    this.onEditorStateChange_94 = this.onEditorStateChange_94.bind(this);
    this.onEditorStateChange_95 = this.onEditorStateChange_95.bind(this);
    this.onEditorStateChange_96 = this.onEditorStateChange_96.bind(this);
    this.onEditorStateChange_97 = this.onEditorStateChange_97.bind(this);
    this.onEditorStateChange_98 = this.onEditorStateChange_98.bind(this);
    this.onEditorStateChange_99 = this.onEditorStateChange_99.bind(this);
    this.onEditorStateChange_100 = this.onEditorStateChange_100.bind(this);
    this.onEditorStateChange_101 = this.onEditorStateChange_101.bind(this);
    this.onEditorStateChange_102 = this.onEditorStateChange_102.bind(this);
    this.onEditorStateChange_103 = this.onEditorStateChange_103.bind(this);
    this.onEditorStateChange_104 = this.onEditorStateChange_104.bind(this);
    this.onEditorStateChange_146 = this.onEditorStateChange_146.bind(this);
    this.onEditorStateChange_147 = this.onEditorStateChange_147.bind(this);
  }

  onEditorStateChange_66(editorState){
    this.setState({
      editorState_66 : editorState,
    });
  };
  onEditorStateChange_77(editorState){
    this.setState({
      editorState_77 : editorState,
    });
  };
  onEditorStateChange_78(editorState){
    this.setState({
      editorState_78 : editorState,
    });
  };
  onEditorStateChange_79(editorState){
    this.setState({
      editorState_79 : editorState,
    });
  };
  onEditorStateChange_80(editorState){
    this.setState({
      editorState_80 : editorState,
    });
  };
  onEditorStateChange_81(editorState){
    this.setState({
      editorState_81 : editorState,
    });
  };
  onEditorStateChange_82(editorState){
    this.setState({
      editorState_82 : editorState,
    });
  };
  onEditorStateChange_83(editorState){
    this.setState({
      editorState_83 : editorState,
    });
  };

  onEditorStateChange_84(editorState){
    this.setState({
      editorState_84 : editorState,
    });
  };
  onEditorStateChange_86(editorState){
    this.setState({
      editorState_86 : editorState,
    });
  };
  onEditorStateChange_87(editorState){
    this.setState({
      editorState_87 : editorState,
    });
  };

  onEditorStateChange_90(editorState){
    this.setState({
      editorState_90 : editorState,
    });
  };

  onEditorStateChange_91(editorState){
    this.setState({
      editorState_91: editorState,
    });
  };

  onEditorStateChange_92(editorState){
    this.setState({
      editorState_92 : editorState,
    });
  };

  onEditorStateChange_93(editorState){
    this.setState({
      editorState_93 : editorState,
    });
  };

  onEditorStateChange_94(editorState){
    this.setState({
      editorState_94 : editorState,
    });
  };

  onEditorStateChange_95(editorState){
    this.setState({
      editorState_95 : editorState,
    });
  };

  onEditorStateChange_96(editorState){
    this.setState({
      editorState_96 : editorState,
    });
  };

  onEditorStateChange_97(editorState){
    this.setState({
      editorState_97 : editorState,
    });
  };

  onEditorStateChange_98(editorState){
    this.setState({
      editorState_98: editorState,
    });
  };

  onEditorStateChange_99(editorState){
    this.setState({
      editorState_99 : editorState,
    });
  };

  onEditorStateChange_100(editorState){
    this.setState({
      editorState_100 : editorState,
    });
  };

  onEditorStateChange_101(editorState){
    this.setState({
      editorState_101 : editorState,
    });
  };

  onEditorStateChange_102(editorState){
    this.setState({
      editorState_102 : editorState,
    });
  };

  onEditorStateChange_103(editorState){
    this.setState({
      editorState_103 : editorState,
    });
  };

  onEditorStateChange_104(editorState){
    this.setState({
      editorState_104 : editorState,
    });
  };

  onEditorStateChange_146(editorState){
    this.setState({
      editorState_146 : editorState,
    });
  };

  onEditorStateChange_147(editorState){
    this.setState({
      editorState_147 : editorState,
    });
  };


  openNotification = () => {
    notification['success']({
      message: 'Succès',
      description:
          'La page a été mis à jour avec succès !',
    });
  };

  openNotificationError = () => {
    notification['error']({
      message: 'Erreur',
      description:
          'Une erreur s\'est produite veuillez réessayer.',
    });
  };

  openNotificationErrorProfile = (msg) => {
    notification['error']({
      message: 'Erreur',
      description:
      msg,
    });
  };

  componentDidMount() {
    Authorized("FAQ_J").then(r=>{
      if (r.data.Role === 2){ // if role is super user see if access to this page is giver
        if (r.data.Access){
          this.setState({authPage:true})
        }
        else{
          window.location.replace("/");
        }
      }else if(r.data.Role === 3) {
        this.setState({LectureMode : true, authPage:true})
      }else
        this.setState({authPage:true})
    }).catch(
        this.setState({authPage:true})
    )
    axios
        .get('api/pages')
        .then(res=>{
          // console.log("Tous les pages : " , res.data.Pages[0].contenu_page);
          let data = res.data.Pages
          data.map(p =>{
            // console.log(p.contenu_page)
            const blocksFromHTML = htmlToDraft(p.contenu_page);
            const temp = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );

            this.setState({
              ["editorState_"+p.id_page]: EditorState.createWithContent(temp),
            });
          })


        })
        .catch(err =>{
          console.log(err.data)
        })
    // this.initializeClock(deadline2);
  }


  updatePage = async (id) =>{
    if(this.state.LectureMode){
      this.openNotificationErrorProfile("vous n'êtes pas autorisé à effectuer cette action")
    }else {
      await axios.post("api/pages",{
        contenu_page:  draftToHtml(convertToRaw(this.state['editorState_'+id].getCurrentContent())),
        id_page: id,
      })
          .then((res) =>{
            console.log(res)
            this.openNotification()
          })
          .catch(()=>{
            this.openNotificationError()
          })
    }

  }


  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };


  render() {

    return (
      <>
        {!this.state.authPage ?
            <div className="spinner-pages spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
            </div> :
            <>
              <Sidebar
                  {...this.props}
                  routes={routes}
                  logo={{
                    innerLink: "/admin/index",
                    imgSrc: require("assets/img/brand/argon-react.png"),
                    imgAlt: "..."
                  }}
              />
              <div className="main-content" ref="mainContent">
                <AdminNavbar
                    {...this.props}
                    brandText={this.getBrandText(this.props.location.pathname)}
                />

                <Header/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                  {/* Table */}
                  <Row>
                    <div className="col">
                      <Card className="shadow">

                        <CardHeader className="border-0">
                          <h2 className="mb-0" style={{textAlignLast: "center"}}>Pages</h2>

                        </CardHeader>
                        <CardBody>

                          <h3>Question 1 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_66}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_66}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(66)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 1 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_92}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_92}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(92)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 2 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_77}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_77}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(77)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 2 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_93}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_93}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(93)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 3 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_78}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_78}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(78)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 3 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_94}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_94}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(94)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 4 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_79}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_79}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(79)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 4 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_95}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_95}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(95)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 5 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_80}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_80}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(80)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 5 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_96}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_96}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(96)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 6 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_81}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_81}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(81)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 6 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_97}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_97}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(97)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 7 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_82}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_82}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(82)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 7 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_98}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_98}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(98)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 8 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_83}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_83}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(83)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 8 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_99}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_99}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(99)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 9 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_84}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_84}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(84)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 9 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_100}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_100}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(100)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 10 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_86}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_86}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(86)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 10 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_101}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_101}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(101)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 11 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_87}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_87}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(87)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 11 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_102}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_102}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(102)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <hr/>

                          <h3>Question 12 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_90}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_90}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(90)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 12 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_103}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_103}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(103)} style={{marginTop: "30px"}}>Sauvegarder</Button>

                          <hr/>
                          <h3>Question 13 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_91}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_91}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(91)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 13 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_104}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_104}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(104)} style={{marginTop: "30px"}}>Sauvegarder</Button>

                          <hr/>
                          <h3>Question 14 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_146}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_146}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(146)} style={{marginTop: "30px"}}>Sauvegarder</Button>
                          <div style={{margin: "50px"}}/>
                          <h3>Réponse 14 :</h3>
                          <div className="mt-3">
                            <Editor
                                editorState={this.state.editorState_147}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange_147}
                            />
                          </div>
                          <Button onClick={() => this.updatePage(104)} style={{marginTop: "30px"}}>Sauvegarder</Button>

                        </CardBody>
                        <CardFooter className="py-4">
                        </CardFooter>
                      </Card>
                    </div>
                  </Row>
                </Container>

                <Container fluid>
                  <AdminFooter/>
                </Container>
              </div>
            </>
        }
      </>
    );
  }
}

export default le_jeu;
