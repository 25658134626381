import React from "react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Container, Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import { Input, notification, Radio, Space } from "antd";
import "./GlobalesCss.css";
import { TiArrowUnsorted } from "react-icons/ti";
import { AiOutlineEye } from "react-icons/ai"
import { FiEdit2 } from "react-icons/fi"
import Tooltip from "react-simple-tooltip"
import { RiDeleteBin2Line } from "react-icons/ri";
import { ModalManager } from 'react-dynamic-modal';
import Infos_Modal from "../components/Joueurs_Detail_Modal";
import { Link } from "react-router-dom";
import Col from "reactstrap/lib/Col";

import ReactExport from "react-export-excel";
import { Authorized, convertDate, convertDateTime, FormatDate } from "../Helpers";
import Checkbox from "antd/lib/checkbox/Checkbox";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Search_In_Table() {
  var input, filter, table, found, tr, td, i, j, txtValue;
  input = document.getElementById("input-cord-table");
  filter = input.value.toUpperCase();
  table = document.getElementById("cordonnee-table");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td");
    for (j = 0; j < td.length; j++) {
      if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
        found = true;
      }
    }
    if (found) {
      tr[i].style.display = "";
      found = false;
    } else {
      if (i > 0) { //this skips the headers
        tr[i].style.display = "none";
      }
    }
  }

}


function sortTable(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("cordonnee-table");
  switching = true;
  //Set the sorting direction to ascending:
  dir = "asc";
  /*Make a loop that will continue until
  no switching has been done:*/
  while (switching) {
    //start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /*Loop through all table rows (except the
    first, which contains table headers):*/
    for (i = 1; i < (rows.length - 1); i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /*Get the two elements you want to compare,
      one from current row and one from the next:*/
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /*check if the two rows should switch place,
      based on the direction, asc or desc:*/
      if (n === 5) {
        x = convertDate(x.innerHTML)
        y = convertDate(y.innerHTML)
        if (dir == "asc") {
          if (x > y) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x < y) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      else if (n === 4) {
        x = convertDateTime(x.innerHTML)
        y = convertDateTime(y.innerHTML)
        if (dir == "asc") {
          if (x > y) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x < y) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      else {
        if (dir === "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      console.log(x)

    }
    if (shouldSwitch) {
      /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      //Each time a switch is done, increase this count by 1:
      switchcount++;
    } else {
      /*If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again.*/
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}


function DownloadPdfFile(id) {
  axios.get('api/players/piece/' + id)
    .then(res => {
      if (res.data.Pieces) {
        if (res.data.Pieces.pieceRecto) {
          let blob = new Blob([new Uint8Array(res.data.Pieces.pieceRecto.data)], { type: res.data.Pieces.FileTypeRecto });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "Recto_" + id;
          link.click();
        }
        if (res.data.Pieces.pieceVerso) {
          let blob = new Blob([new Uint8Array(res.data.Pieces.pieceRecto.data)], { type: res.data.Pieces.FileTypeVerso });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "Verso_" + id;
          link.click();
        }
      }
    })
    .catch(err => {
      this.setState({
        loading: false
      });
      console.log(err.data)
    })

}

class Utilisateurs extends React.Component {
  constructor() {
    super()
    this.state = {
      joueurs: [],
      Modal_Detail: true,
      loading: true,
      notificationModal: false,
      idDelete: "",
      MotifArchive: "Non respect des règles",
      authPage: false,
      LectureMode: false,
      No_PI: [],
    }
    this.openModal = this.openModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeMotif = this.onChangeMotif.bind(this);

  }
  openNotification = () => {
    notification['success']({
      message: 'Succès',
      description:
        "Le compte a été archivé avec succès !",
    });
  };

  openNotificationError = () => {
    notification['error']({
      message: 'Erreur',
      description:
        'Une erreur s\'est produite veuillez réessayer.',
    });
  };

  openNotificationErrorProfile = (msg) => {
    notification['error']({
      message: 'Erreur',
      description:
        msg,
    });
  };

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  openModal(JoueurDetails) {
    ModalManager.open(<Infos_Modal Joueur={JoueurDetails} onRequestClose={() => true} />);
  }

  toggle = () => this.setState({ Modal_Detail: !this.state.Modal_Detail });
  componentDidMount() {
    Authorized("J").then(r => {
      if (r.data.Role === 2) { // if role is super user see if access to this page is giver
        if (r.data.Access) {
          this.setState({ authPage: true })
        }
        else {
          window.location.replace("/");
        }
      } else if (r.data.Role === 3) {
        this.setState({ LectureMode: true, authPage: true })
      } else
        this.setState({ authPage: true })
    })
      .catch(
        this.setState({ authPage: true })
      )
    axios.get('api/players')
      .then(res => {
        if (res.data.Players) {

          this.setState({
            joueurs: Object.values(res.data.Players),
            loading: false
          });

        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        console.log(err.data)
      })
  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  onChangeMotif (e){
    console.log('radio checked', e.target.value);
    this.setState({MotifArchive : e.target.value})
  };

  onChange(e) {
    console.log(`checked = ${e.target.checked}`);

    let today = new Date()
    console.log(today)
    if (e.target.checked) {
      let res = this.state.joueurs.filter(n => {
        let d = new Date(n.created)
        d.setMonth(d.getMonth() + 1);
        console.log()
        return (n.FileTypeRecto === null && n.FileTypeVerso === null) && today.getTime() > d.getTime()
      })
      this.setState({ No_PI: res })
      console.log(res)
    }
    else
      this.setState({ No_PI: [] })
  };

  async removePlayer(id) {
    this.toggleModal("notificationModal")

    await axios.post("api/players/archive",{
      idJoueur : id, motif : this.state.MotifArchive
    })
      .then((res) => {
        // console.log(res.data)
        console.log('ok');
        let filteredArray = this.state.joueurs.filter(item => item.id !== id)
        this.setState({ joueurs: filteredArray });
        this.setState({ save_button: false })
        // this.openNotification()
      })
      .catch(err => {
        console.log("erreur : " + err)
        this.setState({ save_button: false })
        // this.openNotificationError()
      })

  }

  render() {
    return (
      <>
        {!this.state.authPage ?
          <div className="spinner-pages spinner-border text-primary " role="status">
            <span className="sr-only">Loading...</span>
          </div> :
          <>
            <Sidebar
              {...this.props}
              routes={routes}
              logo={{
                innerLink: "/admin/index",
                imgSrc: require("assets/img/brand/argon-react.png"),
                imgAlt: "..."
              }}
            />
            <div className="main-content" ref="mainContent">
              <AdminNavbar
                {...this.props}
                brandText={this.getBrandText(this.props.location.pathname)}
              />

              <Header />
              {/* Page content */}
              <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                  <div className="col">
                    <Card className="shadow">
                      <CardHeader className="border-0">
                        <h2 className="mb-0" style={{ textAlign: "center" }}>Joueurs</h2>
                      </CardHeader>
                      <Row>
                        <Col md={3}>
                          <Input type={"text"} onKeyUp={Search_In_Table} placeholder={"Chercher"} id="input-cord-table" />
                        </Col>
                        <Col md={5} style={{ marginTop: "10px" }}>
                          <Checkbox onChange={this.onChange}>
                            Joueurs n'ayant pas téléverser de PI aprés 1 mois d'inscription</Checkbox>
                        </Col>
                        <Col md={4} style={{ marginTop: "10px" }}>
                          {!this.state.LectureMode &&
                            <ExcelFile element={
                              <Button className="export-btn btn-primary">
                                Exporter</Button>
                            } filename={"Liste joueurs"}>
                              {this.state.joueurs === [] ? <ExcelSheet data={this.state.joueurs} name="Joueurs">
                                <ExcelColumn label="E-mail" value="email" />
                              </ExcelSheet>
                                :
                                <ExcelSheet data={this.state.joueurs} name="Joueurs">
                                  <ExcelColumn label="id" value="id" />
                                  <ExcelColumn label="Pseudonyme" value="Pseudonyme" />
                                  <ExcelColumn label="Prénom" value="first_name" />
                                  <ExcelColumn label="Nom" value="last_name" />
                                  <ExcelColumn label="Nom marital" value="nom_marital" />
                                  <ExcelColumn label="Sexe" value="gender" />
                                  <ExcelColumn label="E-mail" value="email" />
                                  <ExcelColumn label="Date de naissance" value="Date_Naissance" />
                                  <ExcelColumn label="Ville de naissance" value="Ville_Naissance" />
                                  <ExcelColumn label="Ville de residence" value="Ville_Residence" />
                                  <ExcelColumn label="Pays de residence" value="Pays_Residence" />
                                  <ExcelColumn label="Numéro de téléphone" value="Num_telephone" />
                                  <ExcelColumn label="Statut"
                                    value={(col) => col.validation_piece === "true" ? "Validée" :
                                      "Non validée"} />
                                  <ExcelColumn label="Date de création du compte"
                                    value={(col) => FormatDate(col.created)} />
                                </ExcelSheet>
                              }
                            </ExcelFile>}
                        </Col>
                      </Row>
                      {this.state.loading ?
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div> :
                        <Table className="align-items-center table-flush" responsive id={"cordonnee-table"}>
                          <thead className="thead-light">
                            <tr>
                              <th className="th-sort" scope="col" onClick={() => sortTable(0)}>Pseudonyme<TiArrowUnsorted /></th>
                              <th className="th-sort" scope="col" onClick={() => sortTable(1)}>Prénom<TiArrowUnsorted /></th>
                              <th className="th-sort" scope="col" onClick={() => sortTable(2)}>Nom<TiArrowUnsorted /></th>
                              <th className="th-sort" scope="col" onClick={() => sortTable(3)}>E-mail<TiArrowUnsorted /></th>
                              {/*<th className="th-sort" scope="col" onClick={() => sortTable(4)}>nom_marital</th>*/}
                              <th className="th-sort" scope="col" onClick={() => sortTable(4)}>Date de création</th>
                              <th className="th-sort" scope="col" onClick={() => sortTable(5)}>date de naissance<TiArrowUnsorted /></th>
                              {/*<th className="th-sort" scope="col" onClick={() => sortTable(7)}>ville de naissance</th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(8)}>pays de résidence</th>
                      <th className="th-sort" scope="col" onClick={() => sortTable(9)}>ville de résidence</th>*/}
                              {!this.state.LectureMode &&
                                <th className="th-sort" scope="col" onClick={() => sortTable(6)}>Pièce d'identité
                                  <TiArrowUnsorted /></th>
                              }
                              <th className="th-sort" scope="col" onClick={() => sortTable(7)}>Etat de piece</th>
                              {/*<th className="th-sort" scope="col" onClick={() => sortTable(12)}>date de création</th>*/}
                              {/*<th className="th-sort" scope="col" onClick={() => sortTable(6)}>Statut du compte <TiArrowUnsorted/></th>*/}
                              <th className="th-sort" scope="col">Action</th>
                              <th scope="col" />
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.No_PI.length > 0 ?
                              this.state.No_PI.map(row => (
                                <tr key={row.id}>
                                  <td>{row.Pseudonyme}</td>
                                  <td>{row.first_name}</td>
                                  <td>{row.last_name}</td>
                                  <td>{row.email}</td>
                                  <td>{FormatDate(row.created)}</td>
                                  <td>{row.Date_Naissance}</td>
                                  {!this.state.LectureMode &&
                                    <td>{(!!row.FileTypeRecto || !!row.FileTypeVerso) ? <>
                                      <Button color="primary"
                                        onClick={() => {
                                          DownloadPdfFile(row.id)
                                        }}>
                                        Télécharger
                                      </Button>
                                    </> : <b>Aucune Pièce</b>}</td>
                                  }


                                  <td>
                                    <Badge color="" className="badge-dot mr-4">
                                      {row.validation_piece === "true" ? <><i className="bg-success" /><label>Validée</label></> :
                                        <><i className="bg-warning" /><label>Non validée</label></>
                                      }
                                    </Badge>
                                  </td>
                                  <td>
                                    <Tooltip content={"Afficher"} radius={7} padding={8}>
                                      <a onClick={() => this.openModal(row)} style={{ cursor: "pointer" }}>
                                        <AiOutlineEye size="20px"
                                          style={{ color: "blue", marginRight: "10px" }} />
                                      </a>
                                    </Tooltip>
                                    {!this.state.LectureMode &&
                                      <>
                                        <Tooltip content={"Modifier"} radius={7} padding={8}>
                                          <Link to={{
                                            pathname: "/user/" + row.id
                                          }}><FiEdit2 size="20px" style={{ color: "#fddb3a", marginRight: "10px" }} /></Link>
                                        </Tooltip>
                                        <Tooltip content={"Supprimer"} radius={7} padding={8}>
                                          <a onClick={() => {
                                            this.toggleModal("notificationModal")
                                            this.setState({ idDelete: row.id })
                                          }} style={{ cursor: "pointer" }}>
                                            <RiDeleteBin2Line size="20px" style={{ color: "red" }} />
                                          </a>

                                        </Tooltip>
                                      </>
                                    }
                                  </td>
                                </tr>
                              )) :
                              this.state.joueurs.map((row) => (
                                <tr key={row.id}>
                                  <td>{row.Pseudonyme}</td>
                                  <td>{row.first_name}</td>
                                  <td>{row.last_name}</td>
                                  <td>{row.email}</td>
                                  <td>{FormatDate(row.created)}</td>
                                  <td>{row.Date_Naissance}</td>
                                  {!this.state.LectureMode &&
                                    <td>{(!!row.FileTypeRecto || !!row.FileTypeVerso) ? <>
                                      <Button color="primary"
                                        onClick={() => {
                                          DownloadPdfFile(row.id)
                                        }}>
                                        Télécharger
                                      </Button>
                                    </> : <b>Aucune Pièce</b>}</td>
                                  }

                                  {/* {!!row.FileTypeVerso ? <>
                            <Button color="secondary" onClick={() => {
                                  DownloadPdfFile("verso_" + row.id,
                                      row.pieceVerso.data,
                                      row.FileTypeVerso)
                              }}>
                                  Verso
                              </Button>
                          </> : <b>Aucune Pièce</b>}
                          </td>
                          } */}

                                  {/*<td>{row.Num_telephone}</td>*/}
                                  {/*<td>{FormatDate(row.created)}</td>*/}
                                  <td>
                                    <Badge color="" className="badge-dot mr-4">
                                      {row.validation_piece === "true" ? <><i className="bg-success" /><label>Validée</label></> :
                                        <><i className="bg-warning" /><label>Non validée</label></>
                                      }
                                    </Badge>
                                  </td>
                                  <td>
                                    <Tooltip content={"Afficher"} radius={7} padding={8}>
                                      <a onClick={() => this.openModal(row)} style={{ cursor: "pointer" }}>
                                        <AiOutlineEye size="20px"
                                          style={{ color: "blue", marginRight: "10px" }} />
                                      </a>
                                    </Tooltip>
                                    {!this.state.LectureMode &&
                                      <>
                                        <Tooltip content={"Modifier"} radius={7} padding={8}>
                                          <Link to={{
                                            pathname: "/user/" + row.id
                                          }}><FiEdit2 size="20px" style={{ color: "#fddb3a", marginRight: "10px" }} /></Link>
                                        </Tooltip>
                                        <Tooltip content={"Supprimer"} radius={7} padding={8}>
                                          <a onClick={() => {
                                            this.toggleModal("notificationModal")
                                            this.setState({ idDelete: row.id })
                                          }} style={{ cursor: "pointer" }}>
                                            <RiDeleteBin2Line size="20px" style={{ color: "red" }} />
                                          </a>

                                        </Tooltip>
                                      </>
                                    }
                                  </td>
                                </tr>
                              ))}

                          </tbody>
                        </Table>
                      }
                      <CardFooter className="py-4">
                        <nav aria-label="...">
                          <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                          >
                            <PaginationItem className="disabled">
                              <PaginationLink
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                                tabIndex="-1"
                              >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                              </PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="active">
                              <PaginationLink
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                1
                              </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                              <PaginationLink
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                2 <span className="sr-only">(current)</span>
                              </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                              <PaginationLink
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                3
                              </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                              <PaginationLink
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                              </PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </nav>
                      </CardFooter>
                    </Card>
                  </div>
                </Row>

              </Container>

              <Modal
                className="modal-dialog-centered modal-danger"
                contentClassName="bg-gradient-danger"
                isOpen={this.state.notificationModal}
                toggle={() => this.toggleModal("notificationModal")}
              >
                <div className="modal-header">
                  <h6 className="modal-title" id="modal-title-notification">
                    Votre attention est requise
                  </h6>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("notificationModal")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="py-3 text-center">
                    <i className="ni ni-bell-55 ni-3x" />
                    <p>
                      Vous êtes sur le point d'archiver ce compte,
                      veuillez sélectionner le motif de suppression?
                    </p>
                  </div>
                  <div>
                    <Radio.Group onChange={this.onChangeMotif} value={this.state.MotifArchive}>
                    <Space direction="vertical">
                        <Radio value={'Non respect des règles'}><span style={{color : "#FFF"}}>
                          Non respect des règles</span></Radio>
                        <Radio value={"Joeurs n'a pas envoyé sa PI à temps"}><span style={{color : "#FFF"}}>
                          Joeurs n'a pas envoyé sa PI à temps</span></Radio>
                      </Space>
                    </Radio.Group>
                  </div>
                </div>
                <div className="modal-footer">
                  <Button className="btn-white" color="default" type="button" disabled={this.state.loading}
                    onClick={() => this.removePlayer(this.state.idDelete)}>
                    {this.state.loading ? <div className="spinner-border" role="status">
                    </div> : <>Archiver</>}

                  </Button>
                  <Button
                    className="text-white ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("notificationModal")}
                  >
                    Fermer
                  </Button>
                </div>
              </Modal>

              <Container fluid>
                <AdminFooter />
              </Container>
            </div>
          </>
        }
      </>
    );
  }
}

export default Utilisateurs;
