import React from "react";
// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardFooter,
    CardHeader,
    Container,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row, Spinner,
    Table
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input} from "antd";
import "./GlobalesCss.css";
import {TiArrowUnsorted} from "react-icons/ti";
import {AiOutlineEye} from "react-icons/ai"
import {FiEdit2} from "react-icons/fi"
import Tooltip from "react-simple-tooltip"
import {RiDeleteBin2Line} from "react-icons/ri";
import {Modal, ModalManager} from 'react-dynamic-modal';
import Infos_Modal from "../components/Joueurs_Detail_Modal";
import {Link} from "react-router-dom";
import {Authorized, FormatDate, FormatDateWithoutHour} from "../Helpers";
import Col from "reactstrap/lib/Col";
import ReactExport from "react-export-excel";


function Search_In_Table() {
  var input, filter, table, found, tr, td, i, j, txtValue;
  input = document.getElementById("input-cord-table");
  filter = input.value.toUpperCase();
  table = document.getElementById("cordonnee-table");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td");
    for (j = 0; j < td.length; j++) {
      if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
        found = true;
      }
    }
    if (found) {
      tr[i].style.display = "";
      found = false;
    } else {
      if (i>0) { //this skips the headers
        tr[i].style.display = "none";
      }
    }
  }

}
function convertDate(d) {
    var p = d.split("/");
    return +(p[2]+p[1]+p[0]);
}

function sortTable(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("cordonnee-table");
  switching = true;
  //Set the sorting direction to ascending:
  dir = "asc";
  /*Make a loop that will continue until
  no switching has been done:*/
  while (switching) {
    //start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /*Loop through all table rows (except the
    first, which contains table headers):*/
    for (i = 1; i < (rows.length - 1); i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /*Get the two elements you want to compare,
      one from current row and one from the next:*/
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /*check if the two rows should switch place,
      based on the direction, asc or desc:*/
      console.log(x)
      console.log(convertDate(x.innerHTML))
        if(n===4 || n===6){
            x = convertDate(x.innerHTML)
            y = convertDate(y.innerHTML)
            if (dir == "asc") {
                if (x > y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir == "desc") {
                if (x < y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        else {
            if (dir === "asc") {
                if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir === "desc") {
                if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }

    }
    if (shouldSwitch) {
      /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      //Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /*If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again.*/
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}


function DownloadPdfFile(id) {
  axios.get('api/players/piece/' + id)
        .then(res=>{
          if (res.data.Pieces){
            if(res.data.Pieces.pieceRecto){
            let blob = new Blob([new Uint8Array(res.data.Pieces.pieceRecto.data)], {type: res.data.Pieces.FileTypeRecto});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Recto_" + id;
            link.click();
            }
            if(res.data.Pieces.pieceVerso){
              let blob = new Blob([new Uint8Array(res.data.Pieces.pieceRecto.data)], {type: res.data.Pieces.FileTypeVerso});
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "Verso_"+ id;
              link.click();
            }
          }
        })
        .catch(err =>{
            this.setState({
                loading : false
            });
          console.log(err.data)
        })
   
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ArchivedJoueurs extends React.Component {
  constructor() {
    super()
    this.state = {
      joueurs:[],
      Modal_Detail: true,
        authPage:false,
        LectureMode : false
    }
    this.openModal = this.openModal.bind(this);

  }


  openModal(JoueurDetails){
    ModalManager.open(<Infos_Modal Joueur={JoueurDetails} onRequestClose={() => true}/>);
  }

  toggle = () => this.setState({Modal_Detail : !this.state.Modal_Detail});
  componentDidMount() {

      Authorized("JA").then(r=>{
          if (r.data.Role === 2){ // if role is super user see if access to this page is giver
              if (r.data.Access){
                  this.setState({authPage:true})
              }
              else{
                  window.location.replace("/");
              }
          }else if(r.data.Role === 3) {
              this.setState({LectureMode : true, authPage:true})
          }else
              this.setState({authPage:true})
      }).catch(
          this.setState({authPage:true})
      )

    axios.get('api/players/archived')
        .then(res=>{
          console.log("Tous les joueurs : " + res.data.Players);
          if (res.data.Players){
            console.log("Players : " + res.data.Players)
            this.setState({
              joueurs: Object.values(res.data.Players),
            });

            console.log("111111111111111111111111111", this.state.joueurs[0].pieceRecto);

          }
        })
        .catch(err =>{
          console.log(err.data)
        })
  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };


  render() {
    return (
      <>{!this.state.authPage ?
          <div className="spinner-pages spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
          </div> :
          <>
              <Sidebar
                  {...this.props}
                  routes={routes}
                  logo={{
                      innerLink: "/admin/index",
                      imgSrc: require("assets/img/brand/argon-react.png"),
                      imgAlt: "..."
                  }}
              />
              <div className="main-content" ref="mainContent">
                  <AdminNavbar
                      {...this.props}
                      brandText={this.getBrandText(this.props.location.pathname)}
                  />

                  <Header/>
                  {/* Page content */}
                  <Container className="mt--7" fluid>
                      {/* Table */}
                      <Row>
                          <div className="col">
                              <Card className="shadow">

                                  <CardHeader className="border-0">
                                      <h2 className="mb-0" style={{textAlign: "center"}}>Joueurs archivés</h2>
                                  </CardHeader>
                                  <Row>
                                      <Col>
                                          <Input type={"text"} onKeyUp={Search_In_Table} placeholder={"Chercher"}
                                                 id="input-cord-table"/>
                                      </Col>
                                      <Col>
                                          {!this.state.LectureMode &&
                                          <ExcelFile element = {
                                              <Button className="export-btn btn-primary">
                                                  Exporter</Button>
                                          } filename={"Liste des joueurs archivés"}>
                                              {this.state.joueurs === [] ?
                                                  <ExcelSheet data={this.state.joueurs}
                                                              name="Liste des joueurs archivés">
                                                      <ExcelColumn label="vide" value="vide"/>
                                                  </ExcelSheet>
                                                  :
                                                  <ExcelSheet data={this.state.joueurs}
                                                              name="Liste des joueurs archivés">
                                                      <ExcelColumn label="id" value="id"/>
                                                      <ExcelColumn label="Pseudonyme" value="Pseudonyme"/>
                                                      <ExcelColumn label="Prénom" value="first_name"/>
                                                      <ExcelColumn label="Nom" value="last_name"/>
                                                      <ExcelColumn label="Nom marital" value="nom_marital"/>
                                                      <ExcelColumn label="Sexe" value="gender"/>
                                                      <ExcelColumn label="E-mail" value="email"/>
                                                      <ExcelColumn label="Date de naissance" value="Date_Naissance"/>
                                                      <ExcelColumn label="Ville de naissance" value="Ville_Naissance"/>
                                                      <ExcelColumn label="Ville de residence" value="Ville_Residence"/>
                                                      <ExcelColumn label="Pays de residence" value="Pays_Residence"/>
                                                      <ExcelColumn label="Numéro de téléphone" value="Num_telephone"/>
                                                      <ExcelColumn label="Statut"
                                                                   value={(col) => col.validation_piece === "true" ? "Validée" :
                                                                       "Non validée"}/>
                                                      <ExcelColumn label="Date de création du compte"
                                                                   value={(col) => FormatDate(col.created)}/>
                                                  </ExcelSheet>
                                              }
                                          </ExcelFile>}
                                      </Col>
                                  </Row>
                                  <Table className="align-items-center table-dark table-flush" responsive
                                         id={"cordonnee-table"}>
                                      <thead className="thead-dark">
                                      <tr>
                                          <th className="th-sort" scope="col"
                                              onClick={() => sortTable(0)}>Pseudonyme<TiArrowUnsorted/></th>
                                          <th className="th-sort" scope="col"
                                              onClick={() => sortTable(1)}>Prénom <TiArrowUnsorted/></th>
                                          <th className="th-sort" scope="col"
                                              onClick={() => sortTable(2)}>Nom <TiArrowUnsorted/></th>
                                          <th className="th-sort" scope="col"
                                              onClick={() => sortTable(3)}>E-mail <TiArrowUnsorted/></th>
                                          {/*<th className="th-sort" scope="col" onClick={() => sortTable(4)}>nom_marital</th>*/}
                                          <th className="th-sort" scope="col" onClick={() => sortTable(4)}>date de
                                              naissance <TiArrowUnsorted/></th>
                                          <th className="th-sort" scope="col" onClick={() => sortTable(5)}>Pièce
                                              d'identité
                                          </th>
                                          <th className="th-sort" scope="col" onClick={() => sortTable(6)}>LA DATE DE
                                              CLOTURE
                                          </th>
                                          <th className="th-sort" scope="col" onClick={() => sortTable(7)}>Archivé par</th>
                                          <th className="th-sort" scope="col" onClick={() => sortTable(8)}>Motif d'archivage</th>
                                          <th className="th-sort" scope="col">Action</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {this.state.joueurs.map((row) => (
                                          <tr key={row.id}>
                                              <td>{row.Pseudonyme}</td>
                                              <td>{row.first_name}</td>
                                              <td>{row.last_name}</td>
                                              <td>{row.email}</td>
                                              <td>{row.Date_Naissance}</td>
                                              {!this.state.LectureMode &&
                          <td>{(!!row.FileTypeRecto || !!row.FileTypeVerso) ? <>
                              <Button color="primary"
                                      onClick={() => {
                                          DownloadPdfFile(row.id)
                                      }}>
                                  Télécharger
                              </Button>
                          </> : <b>Aucune Pièce</b>}</td>
                          }
                                              <td>{FormatDateWithoutHour(row.ArchivedDate)}</td>
                                              <td>{(row.isArchived && row.isDeleted && !row.motifArchive )?
                          "Joueur" : "Admin"
                          }</td>
                          <td>{row.motifArchive ? row.motifArchive : ""}</td>
                                              <td>
                                                  <Tooltip content={"Afficher"} radius={7} padding={8}>
                                                      <a onClick={() => this.openModal(row)}
                                                         style={{cursor: "pointer"}}>
                                                          <AiOutlineEye size="20px"
                                                                        style={{color: "white", marginRight: "10px"}}/>
                                                      </a>
                                                  </Tooltip>
                                              </td>
                                          </tr>
                                      ))}
                                      </tbody>
                                  </Table>
                                  <CardFooter className="py-4">
                                      <nav aria-label="...">
                                          <Pagination
                                              className="pagination justify-content-end mb-0"
                                              listClassName="justify-content-end mb-0"
                                          >
                                              <PaginationItem className="disabled">
                                                  <PaginationLink
                                                      href="#pablo"
                                                      onClick={e => e.preventDefault()}
                                                      tabIndex="-1"
                                                  >
                                                      <i className="fas fa-angle-left"/>
                                                      <span className="sr-only">Previous</span>
                                                  </PaginationLink>
                                              </PaginationItem>
                                              <PaginationItem className="active">
                                                  <PaginationLink
                                                      href="#pablo"
                                                      onClick={e => e.preventDefault()}
                                                  >
                                                      1
                                                  </PaginationLink>
                                              </PaginationItem>
                                              <PaginationItem>
                                                  <PaginationLink
                                                      href="#pablo"
                                                      onClick={e => e.preventDefault()}
                                                  >
                                                      2 <span className="sr-only">(current)</span>
                                                  </PaginationLink>
                                              </PaginationItem>
                                              <PaginationItem>
                                                  <PaginationLink
                                                      href="#pablo"
                                                      onClick={e => e.preventDefault()}
                                                  >
                                                      3
                                                  </PaginationLink>
                                              </PaginationItem>
                                              <PaginationItem>
                                                  <PaginationLink
                                                      href="#pablo"
                                                      onClick={e => e.preventDefault()}
                                                  >
                                                      <i className="fas fa-angle-right"/>
                                                      <span className="sr-only">Next</span>
                                                  </PaginationLink>
                                              </PaginationItem>
                                          </Pagination>
                                      </nav>
                                  </CardFooter>
                              </Card>
                          </div>
                      </Row>
                      {/* Dark table */}
                      {/*<Row className="mt-5">
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-white mb-0">Moyens de paiement</h3>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Project</th>
                      <th scope="col">Budget</th>
                      <th scope="col">Status</th>
                      <th scope="col">Users</th>
                      <th scope="col">Completion</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/bootstrap.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Argon Design System
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$2,500 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          pending
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip731399078"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip731399078"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip491083084"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip491083084"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip528540780"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip528540780"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip237898869"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip237898869"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">60%</span>
                          <div>
                            <Progress
                              max="100"
                              value="60"
                              barClassName="bg-warning"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/angular.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Angular Now UI Kit PRO
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$1,800 USD</td>
                      <td>
                        <Badge color="" className="badge-dot">
                          <i className="bg-success" />
                          completed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip188614932"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip188614932"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip66535734"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip66535734"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip427561578"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip427561578"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip904098289"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip904098289"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">100%</span>
                          <div>
                            <Progress
                              max="100"
                              value="100"
                              barClassName="bg-success"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/sketch.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Black Dashboard
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$3,150 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-danger" />
                          delayed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip707904950"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip707904950"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip353988222"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip353988222"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip467171202"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip467171202"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip362118155"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip362118155"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">72%</span>
                          <div>
                            <Progress
                              max="100"
                              value="72"
                              barClassName="bg-danger"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/react.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              React Material Dashboard
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$4,400 USD</td>
                      <td>
                        <Badge color="" className="badge-dot">
                          <i className="bg-info" />
                          on schedule
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip226319315"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip226319315"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip711961370"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip711961370"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip216246707"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip216246707"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip638048561"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip638048561"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">90%</span>
                          <div>
                            <Progress
                              max="100"
                              value="90"
                              barClassName="bg-info"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/vue.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Vue Paper UI Kit PRO
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$2,200 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          completed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip781594051"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip781594051"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip840372212"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip840372212"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip497647175"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip497647175"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip951447946"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip951447946"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">100%</span>
                          <div>
                            <Progress
                              max="100"
                              value="100"
                              barClassName="bg-danger"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>*/}


                  </Container>

                  <Container fluid>
                      <AdminFooter/>
                  </Container>
              </div>
          </>
      }
      </>
    );
  }
}

export default ArchivedJoueurs;
