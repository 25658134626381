import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader, Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import routes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import axios from "axios";
import {Input} from "antd";
import "./GlobalesCss.css";
import {TiArrowUnsorted} from "react-icons/ti";
import {GrGroup} from "react-icons/all"
import Tooltip from "react-simple-tooltip"
import {Modal, ModalManager} from 'react-dynamic-modal';
import Parrainage_details from "../components/Parrainage_details";
import {Authorized, convertDateTime, FormatDate} from "../Helpers";
import {Link} from "react-router-dom";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Search_In_Table() {
  var input, filter, table, found, tr, td, i, j, txtValue;
  input = document.getElementById("input-cord-table");
  filter = input.value.toUpperCase();
  table = document.getElementById("cordonnee-table");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td");
    for (j = 0; j < td.length; j++) {
      if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
        found = true;
      }
    }
    if (found) {
      tr[i].style.display = "";
      found = false;
    } else {
      if (i>0) { //this skips the headers
        tr[i].style.display = "none";
      }
    }
  }

}

function sortTable(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("cordonnee-table");
  switching = true;
  //Set the sorting direction to ascending:
  dir = "asc";
  /*Make a loop that will continue until
  no switching has been done:*/
  while (switching) {
    //start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /*Loop through all table rows (except the
    first, which contains table headers):*/
    for (i = 1; i < (rows.length - 1); i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /*Get the two elements you want to compare,
      one from current row and one from the next:*/
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /*check if the two rows should switch place,
      based on the direction, asc or desc:*/
        if (n===3 || n===4){
            x= Number(x.innerHTML)
            y = Number(y.innerHTML)
            if (dir == "asc") {
                if (x > y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir == "desc") {
                if (x < y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        else {
            if (dir === "asc") {
                if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch= true;
                    break;
                }
            } else if (dir === "desc") {
                if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
    }
    if (shouldSwitch) {
      /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      //Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /*If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again.*/
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}


function DownloadPdfFile(FileName, byte, FileType) {
    let blob = new Blob([new Uint8Array(byte)], {type: FileType});
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = FileName;
    link.click();
}

function CalculateNiveau(niveau) {
    let nextNiveau, nextNumber
    switch (niveau) {
        case "Rookie":
            nextNiveau = "Bronze";
            nextNumber = 10;
            break;
        case "Bronze":
            nextNiveau = "Silver";
            nextNumber = 20;
            break;
        case "Silver":
            nextNiveau = "Gold";
            nextNumber = 40;
            break;
        case "Gold":
            nextNiveau = "Platinium";
            nextNumber = 70
            break;
        case "Platinium":
            nextNiveau = "Diamond";
            nextNumber = 110;
            break;
        case "Diamond":
            nextNiveau = "";
            nextNumber = 170;
            break;
        default :
            nextNiveau = "Rookie";
            nextNumber = 10;
            break;
    }
    // let progress = (this.state.number / this.state.nextNumber) * 100
    return [nextNiveau, nextNumber]
}

class Parrainages extends React.Component {
  constructor() {
    super()
    this.state = {
      joueurs:[],
      Modal_Detail: true,
      errorMsg : false,
      successMsg : false,
      loading : true,
      authPage:false,
      LectureMode : false,
    }
    this.openModal = this.openModal.bind(this);
  }

    componentDidMount() {
        Authorized("PAR").then(r=>{
            if (r.data.Role === 2){ // if role is super user see if access to this page is giver
                if (r.data.Access){
                    this.setState({authPage:true})
                }
                else{
                    window.location.replace("/");
                }
            }else if(r.data.Role === 3) {
                this.setState({LectureMode : true, authPage:true})
            }else
                this.setState({authPage:true})
        }).catch(
            this.setState({authPage:true})
        )

        axios.get('api/parrainages/code')
            .then(res=>{
                console.log(res.data);
                /*for (let c of res.data){
                    if (c.niveau){
                        let next = new CalculateNiveau(c.niveau.Niveau)
                        c.nextNiveau = next[0];
                        c.nextNumber = next[1]
                    }else {
                        let next = new CalculateNiveau("Rookie")
                        c.nextNiveau = next[0];
                        c.nextNumber = next[1]
                    }
                }*/
                this.setState({joueurs : res.data,loading : false})
            })
            .catch(err =>{
                console.log(err.data)
                this.setState({successMsg:false , errorMsg : true,loading : false})
            })
    }

  openModal(code){
    ModalManager.open(<Parrainage_details Code={code} onRequestClose={() => true}/>);
  }

  toggle = () => this.setState({Modal_Detail : !this.state.Modal_Detail});

/*
  changeLevel = (user) =>{
      this.setState({loading : true})
      axios.post('api/parrainages/changer-niveau',{userData : user})
          .then(()=>{
              this.setState({errorMsg:false , successMsg : true, loading : false})
          })
  }
*/

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          this.props.location.pathname.indexOf(
              routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };



  render() {
    return (
      <>
          {!this.state.authPage ?
              <div className="spinner-pages spinner-border text-primary " role="status">
                  <span className="sr-only">Loading...</span>
              </div> :
              <>
                  <Sidebar
                      {...this.props}
                      routes={routes}
                      logo={{
                          innerLink: "/admin/index",
                          imgSrc: require("assets/img/brand/argon-react.png"),
                          imgAlt: "..."
                      }}
                  />
                  <div className="main-content" ref="mainContent">
                      <AdminNavbar
                          {...this.props}
                          brandText={this.getBrandText(this.props.location.pathname)}
                      />

                      <Header/>
                      {/* Page content */}
                      <Container className="mt--7" fluid>
                          {/* Table */}
                          <Row>
                              <div className="col">
                                  <Card className="shadow">
                                      <CardHeader className="border-0">
                                          <h2 className="mb-0" style={{textAlign: "center"}}>Parrainages</h2>
                                          {this.state.errorMsg && <div className="alert alert-danger">
                                              une erreur s'est produite veuillez réessayer </div>}
                                          {this.state.successMsg && <div className="alert alert-success">
                                              Modifier avec succès, veuillez actualiser la page pour voir les
                                              modifications </div>}
                                      </CardHeader>
                                      <Row>
                                          <Col>
                                      <Input type={"text"} onKeyUp={Search_In_Table} placeholder={"Chercher"}
                                             id="input-cord-table"/>
                                          </Col>
                                          {!this.state.LectureMode &&
                                          <>
                                              <Col>
                                                  <ExcelFile element={
                                                      <Button className="export-btn btn-primary">
                                                          Exporter</Button>
                                                  } filename={"Parrainages"}>
                                                      {this.state.joueurs===[] ? <ExcelSheet data={this.state.joueurs}
                                                                                             name="Parrainages">
                                                              <ExcelColumn label="" value=""/>
                                                          </ExcelSheet>
                                                          :
                                                          <ExcelSheet data={this.state.joueurs} name="Parrainages">
                                                              <ExcelColumn label="Pseudonyme" value="Pseudonyme"/>
                                                              <ExcelColumn label="Code de parrainage" value="Code"/>
                                                              <ExcelColumn label="Niveau"
                                                                           value={(row) => row.Niveau ?
                                                                               row.Niveau : "Rookie"}/>
                                                              <ExcelColumn label="Nombre de filleuls" value="number"/>
                                                              <ExcelColumn label="Crédits" value={(row) => row.Niveau ?
                                                                  row.Credit : "0"}/>
                                                          </ExcelSheet>
                                                      }
                                                  </ExcelFile>
                                              </Col>
                                          </>
                                          }
                                      </Row>
                                      {this.state.loading ?
                                          <div className="spinner-border text-primary" role="status">
                                              <span className="sr-only">Loading...</span>
                                          </div> :
                                          <Table className="align-items-center table-flush" responsive
                                                 id={"cordonnee-table"}>
                                              <thead className="thead-light">
                                              <tr>
                                                  <th className="th-sort" scope="col" onClick={() => sortTable(0)}>
                                                      Pseudonyme<TiArrowUnsorted/></th>
                                                  <th className="th-sort" scope="col"
                                                      onClick={() => sortTable(1)}>Code de parrainage<TiArrowUnsorted/></th>
                                                  <th className="th-sort" scope="col"
                                                      onClick={() => sortTable(2)}>Niveau<TiArrowUnsorted/></th>
                                                  <th className="th-sort" scope="col"
                                                      onClick={() => sortTable(3)}>Nombre de filleuls<TiArrowUnsorted/></th>
                                                  <th className="th-sort" scope="col"
                                                      onClick={() => sortTable(4)}>Crédits
                                                      obtenus<TiArrowUnsorted/></th>
                                                  {!this.state.LectureMode &&
                                                  <th className="th-sort" scope="col">Action</th>}
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {this.state.joueurs.map((row) => (
                                                  <tr key={row.idUser}>

                                                      <td>{row.Pseudonyme}</td>
                                                      <td>{row.Code}</td>
                                                      {row.Niveau ? <td>{row.Niveau}</td> : <td>{"Rookie"}</td>}
                                                      {/*<td>{row.nextNiveau}</td>*/}
                                                      <td>
                                                          {/*<Progress percent={(row.number / row.nextNumber) * 100
                                                          } showInfo={false}
                                                                    strokeColor={"#0612B7"} trailColor="#BFE3FF"/>
                                                          <div><label>{row.number} / {row.nextNumber}</label></div>*/}
                                                          {row.number}
                                                      </td>

                                                      {row.Niveau ? <td>{row.Credit}</td> : <td>{0}</td>}
                                                      {!this.state.LectureMode &&
                                                      <td>
                                                          <Tooltip content={"Voir filleuls"} radius={7} padding={8}>
                                                              <a onClick={() => this.openModal(row.Code)}
                                                                 style={{cursor : "pointer"}}>
                                                                  <GrGroup size="20px"
                                                                                style={{color : "blue", marginRight: "10px"}}/>
                                                              </a>
                                                          </Tooltip>
                                                      </td>}
                                                  </tr>
                                              ))}
                                              </tbody>
                                          </Table>
                                      }
                                      <CardFooter className="py-4">
                                          <nav aria-label="...">
                                              <Pagination
                                                  className="pagination justify-content-end mb-0"
                                                  listClassName="justify-content-end mb-0"
                                              >
                                                  <PaginationItem className="disabled">
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                          tabIndex="-1"
                                                      >
                                                          <i className="fas fa-angle-left"/>
                                                          <span className="sr-only">Previous</span>
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem className="active">
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          1
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem>
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          2 <span className="sr-only">(current)</span>
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem>
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          3
                                                      </PaginationLink>
                                                  </PaginationItem>
                                                  <PaginationItem>
                                                      <PaginationLink
                                                          href="#pablo"
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          <i className="fas fa-angle-right"/>
                                                          <span className="sr-only">Next</span>
                                                      </PaginationLink>
                                                  </PaginationItem>
                                              </Pagination>
                                          </nav>
                                      </CardFooter>
                                  </Card>
                              </div>
                          </Row>
                      </Container>

                      <Container fluid>
                          <AdminFooter/>
                      </Container>
                  </div>
      </>
  }
</>
    );
  }
}

export default Parrainages;

